import { useQuery } from '@tanstack/react-query';
import Elk, { ElkExtendedEdge, ElkNode } from 'elkjs/lib/elk-api';
import ElkWorkerUrl from 'elkjs/lib/elk-worker?worker&url';
import { match } from 'ts-pattern';
import { GenealogyGraphStructure } from './useGenealogyGraphStructure';

const elk = new Elk({
  workerFactory() {
    return new Worker(ElkWorkerUrl, {
      type: 'module',
    });
  },
  defaultLayoutOptions: {
    'elk.algorithm': 'layered',
    'elk.direction': 'DOWN',
    'elk.spacing.nodeNode': '100',
    'elk.layered.spacing.nodeNodeBetweenLayers': '100',
    'elk.layered.nodePlacement.favorStraightEdges': 'true',
  },
});

const DEFAULT_WIDTH = 330;
const DEFAULT_HEIGHT = 150;

async function getGenealogyGraphLayout(
  structure: GenealogyGraphStructure,
  // TODO(2315): Allow taking in sizes after they have finished loading everything instead of using the defaults
  // TODO(2315): That will make things look better after the first expansion
) {
  const elkMaterialSetNodes: ElkNode[] = structure.materialSetNodes.map(
    (materialSetNode) => ({
      id: materialSetNode.id,
      width: DEFAULT_WIDTH,
      height: DEFAULT_HEIGHT,
    }),
  );

  const elkFeedFlowGroupNodes: ElkNode[] = structure.feedFlowGroupNodes.map(
    (ffgNode) => ({
      id: ffgNode.id,
      width: DEFAULT_WIDTH,
      height: DEFAULT_HEIGHT,
    }),
  );

  const elkInternalSinkNodes: ElkNode[] = structure.internalSinkNodes.map(
    (sinkNode) => ({
      id: sinkNode.id,
      width: DEFAULT_WIDTH,
      height: DEFAULT_HEIGHT,
    }),
  );

  const elkNodes = [
    ...elkMaterialSetNodes,
    ...elkFeedFlowGroupNodes,
    ...elkInternalSinkNodes,
  ];

  const elkEdges: ElkExtendedEdge[] = structure.edges.map((edge) =>
    match(edge)
      .with(
        { kind: 'inter-material-set' },
        ({ id, sourceHash, targetHash }) => ({
          id,
          sources: [sourceHash],
          targets: [targetHash],
        }),
      )
      .with(
        { kind: 'feedstock' },
        ({ id, materialSetHash, feedFlowGroupId }) => ({
          id,
          sources: [materialSetHash],
          targets: [feedFlowGroupId],
        }),
      )
      .with({ kind: 'process-output' }, ({ id, effect }) => ({
        id,
        sources: [effect.feedFlowGroupId],
        targets: [effect.output],
      }))
      .with(
        { kind: 'internal-material-sink' },
        ({ id, materialSetHash, internalMaterialSinkId }) => ({
          id,
          sources: [materialSetHash],
          targets: [internalMaterialSinkId],
        }),
      )
      .exhaustive(),
  );

  const elkRootLayout = await elk.layout({
    id: 'root',
    children: elkNodes,
    edges: elkEdges,
  });

  if (elkRootLayout.children === undefined) {
    throw new Error('expected children');
  }

  const nodePositions = new Map<string, { x: number; y: number }>();
  for (const child of elkRootLayout.children) {
    if (child.x === undefined || child.y === undefined) {
      throw new Error('child missing x or y');
    }
    nodePositions.set(child.id, { x: child.x, y: child.y });
  }

  return nodePositions;
}

export function useGenealogyGraphLayout(
  structure: GenealogyGraphStructure | undefined,
) {
  return useQuery({
    // we can omit exhaustive deps because material sets are immutable when identified by hash
    queryKey: [
      {
        kind: 'genealogy-graph-layout',
        materialSetHashes: structure?.materialSetNodes.map(
          (n) => n.materialSet.hash,
        ),
      },
    ],
    queryFn: async () => {
      if (structure === undefined) {
        throw new Error(
          'query should have been disabled when structure is not present',
        );
      }
      return await getGenealogyGraphLayout(structure);
    },
    enabled: structure !== undefined,
    staleTime: Number.POSITIVE_INFINITY,
  });
}
