import dayjs from 'dayjs';
import { useFacilityContext } from '../Facility/FacilityContext';
import { DrawerForm, DrawerFormDrawerProps } from '../Form/DrawerForm';
import {
  useOutputContainerChange,
  usePatchOutputContainerChange,
} from '../api/outputContainerChange';
import { useSetFormInitialValuesFromQuery } from '../lib/useSetFormInitialValuesFromQuery';
import {
  OutputContainerChangeId,
  OutputContainerChangePatchDTO,
} from '../rest-client';
import {
  OutputContainerChangeFormFields,
  OutputContainerChangeFormValues,
  useOutputContainerChangeForm,
} from './OutputContainerChangeForm';

export type EditOutputContainerChangeDrawerFormProps = {
  outputContainerChangeId: OutputContainerChangeId;
  onSuccess?: (outputContainerChange: OutputContainerChangePatchDTO) => void;
} & DrawerFormDrawerProps;

export function EditOutputContainerChangeDrawerForm(
  props: EditOutputContainerChangeDrawerFormProps,
) {
  const { outputContainerChangeId, onSuccess, ...drawerFormDrawerProps } =
    props;

  const facility = useFacilityContext();
  const {
    data: outputContainerChange,
    isLoadingError,
    error,
  } = useOutputContainerChange(outputContainerChangeId);
  const patchMutation = usePatchOutputContainerChange(outputContainerChangeId);
  const form = useOutputContainerChangeForm({});

  useSetFormInitialValuesFromQuery(
    form,
    outputContainerChange && {
      changeTime: dayjs
        .utc(outputContainerChange.effectiveTimestamp)
        .tz(facility.timeZoneId),
      processId: outputContainerChange.process.id,
      outputPortId: outputContainerChange.outputPort.outputPortId,
      // biome-ignore lint/style/noNonNullAssertion: <explanation>
      newContainerId: outputContainerChange.container?.id ?? null!,
      previousContainerFilled: outputContainerChange.previousContainerFilled,
    },
  );

  if (isLoadingError) {
    throw error;
  }

  const formValuesToDto = ({
    changeTime,
    newContainerId,
    previousContainerFilled,
  }: OutputContainerChangeFormValues) => {
    const outputContainerChangePatch: OutputContainerChangePatchDTO = {
      ...(form.isDirty('changeTime') && {
        effectiveTimestamp: changeTime.utc().toISOString(),
      }),
      ...(form.isDirty('newContainerId') && { containerId: newContainerId }),
      ...(form.isDirty('previousContainerFilled') && {
        previousContainerFilled,
      }),
    };

    return outputContainerChangePatch;
  };

  return (
    <DrawerForm
      entityName='Output Container Change'
      form={form}
      formVariant='edit'
      mutation={patchMutation}
      onSuccess={onSuccess}
      formValuesToDto={formValuesToDto}
      size='lg'
      {...drawerFormDrawerProps}
    >
      <OutputContainerChangeFormFields form={form} />
    </DrawerForm>
  );
}
