import { useMemo } from 'react';
import { useMaterialClassSets } from '../../api/materialClassSet';
import { useFlattenedSamples } from '../../api/sample';
import { CommodityId, MaterialClassSetId } from '../../rest-client';
import { TableLoader } from '../../Table/TableLoading';
import { useCommoditySampleTableStore } from './CommoditySampleTableState';
import { SampleCompositionLineChart } from './SampleCompositionLineChart';
import { SampleTable } from './SampleTable';
import { SampleTableRow } from './SampleTableState';

export type CommoditySampleTableVariant = {
  type: 'commodity';
  setFilteredRows: (rows: SampleTableRow[]) => void;
  materialClassSetId: MaterialClassSetId | null;
  setMaterialClassSetId: (id: MaterialClassSetId | null) => void;
};

export function CommoditySampleTable(props: { commodityId: CommodityId }) {
  const { commodityId } = props;

  const {
    materialClassSetId,
    setMaterialClassSetId,
    filteredRows,
    setFilteredRows,
  } = useCommoditySampleTableStore();

  const {
    data: materialClassSetSamples,
    error,
    isLoadingError,
    isLoading,
    failureCount,
  } = useFlattenedSamples({ commodityId: commodityId });

  const { data: materialClassSets } = useMaterialClassSets();

  useMemo(() => {
    if (materialClassSets) {
      setMaterialClassSetId(materialClassSets[0].id);
    }
  }, [materialClassSets, setMaterialClassSetId]);

  if (isLoadingError) {
    throw error;
  }

  const variant: CommoditySampleTableVariant = {
    type: 'commodity',
    setFilteredRows,
    materialClassSetId,
    setMaterialClassSetId,
  };

  if (materialClassSetId === null || isLoading) {
    return <TableLoader height='450px' failureCount={failureCount} />;
  }

  const flatSamples =
    materialClassSetSamples.materialClassSetToSamplesMap[materialClassSetId];

  return (
    <>
      {flatSamples.completeSamples.length +
        flatSamples.convertedSampleAnalyses.length >
      0 ? (
        <SampleCompositionLineChart
          key={materialClassSetId} // to force a non-stale e-charts configuration on MaterialClassSetId changing
          materialClasses={
            materialClassSetSamples.materialClassSetToSamplesMap[
              materialClassSetId
            ].materialClassSet.materialClasses
          }
          filteredRows={filteredRows}
        />
      ) : null}
      <SampleTable materialClassSetSamples={flatSamples} variant={variant} />
    </>
  );
}
