import createClient, { ClientOptions, Middleware } from 'openapi-fetch';
import { GetMsalAccessToken } from '../Microsoft/msalUtil';
import type { paths } from '../openapi';

type ClientType = ReturnType<typeof createClient<paths>>;

export let client: ClientType;

const msalMiddleware: Middleware = {
  async onRequest({ request }) {
    const accessToken = await GetMsalAccessToken();
    request.headers.set('Authorization', `Bearer ${accessToken}`);
  },
};

export interface ClientState {
  assumeTenantId: number | null;
}

export const clientState: ClientState = {
  assumeTenantId: null,
};

const assumeTenantMiddleware: Middleware = {
  onRequest({ request }) {
    if (clientState.assumeTenantId !== null) {
      request.headers.set(
        'Valis-Assume-Tenant-Id',
        `${clientState.assumeTenantId}`,
      );
    }
  },
};

export function setupClient(options: ClientOptions) {
  client = createClient<paths>(options);
  client.use(msalMiddleware);
  client.use(assumeTenantMiddleware);
}
