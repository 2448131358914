import {
  Button,
  FocusTrap,
  Group,
  Loader,
  NumberInput,
  Progress,
  RingProgress,
  Table,
  Text,
} from '@mantine/core';
import { IconRefresh } from '@tabler/icons-react';
import { useCallback, useEffect, useState } from 'react';
import { match } from 'ts-pattern';
import { usePatchMaterialClassSetComposition } from '../../api/materialClassSetComposition';
import { useRecoveryGoals } from '../../api/recoveryGoal';
import { usePatchRecoveryStrategySimulation } from '../../api/recoveryStrategySimulation';
import { useCategoricalColors } from '../../lib/colors';
import {
  RecoveryGoalExcludedIcon,
  RecoveryGoalIncludedIcon,
} from '../../RecoveryGoal/RecoveryGoalIcons';
import { MaterialClassLegendItem } from '../MaterialClassLegendItem';
import { useRecoveryStrategySimulationCtx } from '../RecoveryStrategySimulationContext';

export function SimulationPerformanceParametersInput() {
  const { simulation } = useRecoveryStrategySimulationCtx();
  const colors = useCategoricalColors();

  const rgQuery = useRecoveryGoals();
  const recoveryGoals =
    rgQuery.data && new Map(rgQuery.data.map((rg) => [rg.id, rg]));
  const recoveryGoalIdSet = new Set<string>(
    simulation.recoveryStrategy.paths.flatMap((p) =>
      p.steps.map((s) => s.recoveryGoalId),
    ),
  );

  const recoveryGoalIds = [...recoveryGoalIdSet];

  // TODO: can we delete this, it should always be 1
  const inputComposition =
    simulation.rootMaterialNode?.materialClassComposition;
  const inputCompositionTotal =
    inputComposition !== undefined
      ? Object.values(inputComposition).reduce((t, v) => t + v, 0)
      : 1;

  // TODO(2339): highlight row and column with color?
  return (
    <Table>
      <thead>
        <tr>
          <th>Material Class</th>
          <th style={{ textAlign: 'right' }}>Composition</th>
          <th style={{ textAlign: 'right' }}>Feedstock %</th>

          {recoveryGoalIds.map((rgId) => (
            <th
              key={rgId}
              style={{ textAlign: 'right' }}
              onClick={() => {
                // TODO(2339): Change the recovery goal
              }}
            >
              {recoveryGoals?.get(rgId)?.name ?? ''}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {simulation.materialClassSet.materialClasses.map((materialClass, i) => {
          const materialClassProportion =
            inputComposition !== undefined
              ? inputComposition[materialClass.id]
              : 0;

          return (
            <tr key={materialClass.id}>
              <td>
                <MaterialClassLegendItem
                  materialClass={materialClass}
                  color={colors[i]}
                />
              </td>

              <td style={{ textAlign: 'right' }}>
                <InputCompositionInput
                  key={materialClass.id}
                  materialClassId={materialClass.id}
                />
              </td>

              <td style={{ textAlign: 'right' }}>
                <Progress
                  h='100%'
                  radius='xs'
                  color={colors[i]}
                  value={
                    (100 * materialClassProportion) / inputCompositionTotal
                  }
                />
                {materialClassProportion >= 0
                  ? `${(100 * materialClassProportion).toFixed(2)}%`
                  : '?'}
              </td>

              {recoveryGoalIds.map((rgId) => {
                const rg = recoveryGoals?.get(rgId);
                const rgClasses = rg
                  ? new Set(rg.materialClasses.map((mc) => mc.id))
                  : undefined;
                const classIncluded = rgClasses?.has(materialClass.id);
                return (
                  <td key={`${rgId}-${materialClass.id}`}>
                    <Group position='right' noWrap spacing={0}>
                      {match(classIncluded)
                        .with(undefined, () => <Loader size='xs' />)
                        .with(true, () => (
                          <RecoveryGoalIncludedIcon
                            size='1.4em'
                            color='green'
                          />
                        ))
                        .with(false, () => (
                          <RecoveryGoalExcludedIcon
                            size='1.4em'
                            color='orange'
                          />
                        ))
                        .exhaustive()}
                      <RingProgress
                        size={25}
                        thickness={5}
                        sections={[
                          {
                            value:
                              simulation.recoveryGoalAccuracies[rgId][
                                materialClass.id
                              ] * 100,
                            color: 'teal',
                          },
                          {
                            value:
                              100 -
                              simulation.recoveryGoalAccuracies[rgId][
                                materialClass.id
                              ] *
                                100,
                            color: 'red',
                          },
                        ]}
                      />
                      <RecoveryGoalAccuracyInput
                        recoveryGoalId={rgId}
                        materialClassId={materialClass.id}
                      />
                    </Group>
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}
function InputCompositionInput(props: { materialClassId: string }) {
  const { materialClassId } = props;
  const { simulation } = useRecoveryStrategySimulationCtx();

  const patchMutation = usePatchMaterialClassSetComposition();
  const [value, setValue] = useState<number | undefined>(undefined);
  const [editing, setEditing] = useState(false);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const inputComposition = new Map(
      Object.entries(
        simulation.inputMaterialClassSetComposition.materialClassesProportions,
      ),
    );
    setValue(inputComposition.get(materialClassId));
  }, [
    materialClassId,
    simulation.inputMaterialClassSetComposition.materialClassesProportions,
    simulation.materialClassSet.materialClasses,
    simulation.rootMaterialNode,
  ]);

  const mutate = useCallback(() => {
    if (value === undefined) return;
    return patchMutation.mutate(
      {
        id: simulation.compositionSourceMaterialClassSetCompositionId,
        patch: { materialClassesProportions: { [materialClassId]: value } },
      },
      {
        onSuccess() {
          setEditing(false);
        },
      },
    );
  }, [
    value,
    patchMutation,
    simulation.compositionSourceMaterialClassSetCompositionId,
    materialClassId,
  ]);

  if (patchMutation.isError) {
    return (
      <Button
        color='red'
        onClick={() => {
          setEditing(false);
          patchMutation.reset();
          setValue(
            simulation.rootMaterialNode?.materialClassComposition[
              materialClassId
            ],
          );
        }}
      >
        <IconRefresh />
      </Button>
    );
  }

  return editing ? (
    <FocusTrap>
      <NumberInput
        data-autofocus
        hideControls
        disabled={patchMutation.isLoading}
        rightSection={patchMutation.isLoading ? <Loader size='xs' /> : null}
        w='8ch'
        value={value ?? ''}
        onChange={(v) => {
          if (v === '') {
            setValue(undefined);
          } else {
            setValue(v);
          }
        }}
        onBlur={() => mutate()}
        onKeyDown={(e) => {
          if (e.code === 'Enter') {
            mutate();
          }
        }}
        precision={2}
      />
    </FocusTrap>
  ) : (
    <Text ta='right' onClick={() => setEditing(true)}>
      {value?.toFixed(3) ?? '-'}
    </Text>
  );
}
function RecoveryGoalAccuracyInput(props: {
  recoveryGoalId: string;
  materialClassId: string;
}) {
  const { recoveryGoalId, materialClassId } = props;
  const { simulation } = useRecoveryStrategySimulationCtx();

  const [editing, setEditing] = useState(false);
  const patchMutation = usePatchRecoveryStrategySimulation();

  const currentAccuracy =
    simulation.recoveryGoalAccuracies[recoveryGoalId][materialClassId];

  const [accuracy, setAccuracy] = useState(currentAccuracy);

  const mutate = useCallback(() => {
    patchMutation.mutate(
      {
        simulationId: simulation.id,
        patch: {
          recoveryGoalAccuracies: {
            [recoveryGoalId]: { [materialClassId]: accuracy },
          },
        },
      },
      {
        onSuccess() {
          setEditing(false);
        },
      },
    );
  }, [patchMutation, simulation.id, recoveryGoalId, materialClassId, accuracy]);

  if (patchMutation.isError) {
    return (
      <Button
        color='red'
        onClick={() => {
          setEditing(false);
          patchMutation.reset();
          setAccuracy(currentAccuracy);
        }}
      >
        <IconRefresh />
      </Button>
    );
  }

  return editing ? (
    <NumberInput
      disabled={patchMutation.isLoading}
      rightSection={patchMutation.isLoading ? <Loader size='xs' /> : null}
      ref={(e) => e?.focus()}
      w='8ch'
      step={0.01}
      precision={2}
      hideControls
      value={accuracy * 100}
      onKeyDown={(e) => {
        if (e.code === 'Enter') {
          mutate();
        }
      }}
      onChange={(v) => {
        if (v === '') {
          setAccuracy(0);
        } else {
          setAccuracy(v / 100);
        }
      }}
      onBlur={() => mutate()}
    />
  ) : (
    <Text weight={500} onClick={() => setEditing(true)}>
      {(accuracy * 100).toFixed(2)}%
    </Text>
  );
}
