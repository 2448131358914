import { NumberInput, Select } from '@mantine/core';
import { UseFormReturnType, useForm, zodResolver } from '@mantine/form';
import dayjs, { Dayjs } from 'dayjs';
import { z } from 'zod';
import { useFacilityContext } from '../../Facility/FacilityContext';
import { DayjsDateInput } from '../../Time/DayjsDateTimePicker';
import { WeightUnit } from '../../rest-client';

const CommoditySpotPriceFormValues = z.object({
  pricedAt: z.instanceof(dayjs as unknown as typeof Dayjs),
  weightUnit: z.nativeEnum(WeightUnit),
  usdPerUnitOfWeight: z.number().refine((value) => value !== 0, {
    message: 'Price per weight unit cannot be zero',
  }),
});

export type CommoditySpotPriceFormValues = z.infer<
  typeof CommoditySpotPriceFormValues
>;

export interface CommoditySpotPriceFormFieldsProps {
  form: UseFormReturnType<CommoditySpotPriceFormValues>;
}

export function CommoditySpotPriceFormFields(
  props: CommoditySpotPriceFormFieldsProps,
) {
  const { form } = props;
  const facility = useFacilityContext();

  // Render Weight Units dynamically, when able.
  return (
    <>
      <DayjsDateInput
        label='Price Date'
        description='Date at which the spot price was recorded.'
        tz={facility.timeZoneId}
        required
        {...form.getInputProps('pricedAt')}
      />
      <Select
        data={['Pound']}
        label='Weight Units'
        disabled
        {...form.getInputProps('weightUnit')}
      />
      <NumberInput
        label='USD Per Unit Of Weight'
        hideControls
        precision={2}
        prefix='$'
        {...form.getInputProps('usdPerUnitOfWeight')}
      />
    </>
  );
}

export function useCommoditySpotPriceForm() {
  const facility = useFacilityContext();
  // const usdPerUnitOfWeight: number = 0;
  return useForm<CommoditySpotPriceFormValues>({
    initialValues: {
      pricedAt: dayjs().tz(facility.timeZoneId).startOf('day'),
      weightUnit: WeightUnit.POUND,
      usdPerUnitOfWeight: 0,
    },
    validate: zodResolver(CommoditySpotPriceFormValues),
  });
}
