import { SVGProps } from 'react';

const SvgValisLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id='Layer_1'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 2159.32 753.82'
    enableBackground='new 0 0 2159.32 753.82'
    {...props}
  >
    <style>
      {
        '.st0{fill:#02624d}.st1{fill:#00624d}.st2{fill:#9ed2a1}.st3{fill:#dbf5dc}'
      }
    </style>
    <switch>
      <foreignObject
        requiredExtensions='&ns_ai;'
        x='0'
        y='0'
        width='1'
        height='1'
      />
      <g>
        <g>
          <polygon
            className='st1'
            points='346.73,658.32 235.15,658.32 19.85,89.72 144.54,89.72 289.9,473.59 437.59,89.72 561.25,89.72'
          />
          <polygon
            className='st0'
            points='346.73,658.32 235.15,658.32 19.85,89.72 144.54,89.72 289.9,473.59 437.59,89.72 561.25,89.72'
          />
        </g>
        <path
          className='st0'
          d='M997.56 658.32h95.96L862.57 89.72H766.6l-231.64 568.6h121.59l44.67-103.58h250.73L997.56 658.32zM746.83 447.7l85.65-197.34 91.25 197.34H746.83z'
        />
        <path
          className='st2'
          d='M968.42 658.32h89.92l-224.8-545.15-77.44-.23L534.96 658.32h100.3l41.86-103.58H925.7L968.42 658.32zM711.34 470.03l88.77-219.67 90.63 219.67H711.34z'
        />
        <polygon
          className='st0'
          points='1309.37,658.32 1185.71,658.32 1185.71,89.72 1309.37,89.72 1309.37,541.37 1479.14,540.64 1479.14,658.32'
        />
        <path
          className='st2'
          d='M1191.77 112.95h78.14c2.96.0 5.36 2.4 5.36 5.36v530.86c0 5.06-4.1 9.16-9.16 9.16h-80.4V119.01C1185.71 115.66 1188.42 112.95 1191.77 112.95z'
        />
        <rect
          x='1560.69'
          y='89.72'
          className='st0'
          width='124.44'
          height='573.49'
        />
        <path
          className='st0'
          d='M2046.13 224.06l77.13-83.31c-109.02-93.59-297.23-78.16-334.25 61.71-26.74 156.33 79.69 194.16 152.72 217.82 71.49 25.93 82.8 75.29 48.86 107.17-41.82 45.07-93.39 16.41-148.1-36l-80.22 97.71c110.4 114.62 240.14 96.9 316.22 28.83 32.35-28.94 53.42-68.69 59.4-111.68 9.22-66.38-21.72-119.77-95.75-163.78-14.95-8.89-30.7-16.34-46.87-22.75-53.48-21.18-89.74-43.44-86.71-82.38 1.26-16.19 8.98-31.43 22.33-40.66C1961.57 175.51 2002.91 196.09 2046.13 224.06z'
        />
      </g>
    </switch>
    <title>VALIS Logo</title>
  </svg>
);
export default SvgValisLogo;
