import { Flex, Text } from '@mantine/core';
import { match, P } from 'ts-pattern';
import { CommodityName } from '../Commodity/CommodityName';
import { LabeledValue } from '../common';
import { RecoveryGoalName } from '../RecoveryGoal/RecoveryGoalName';
import {
  CommoditySpreadMaterialNodeDTO,
  CommoditySpreadRecoveryGoalNodeDTO,
  WeightUnit,
} from '../rest-client';
import { WithUnit } from '../util/WithUnit';
import { getWeightUnitAbbreviation } from '../Weights/units';

export function CommoditySpreadMaterialNodeBody(
  materialNode: CommoditySpreadMaterialNodeDTO,
) {
  return (
    <>
      <LabeledValue label='Commodity'>
        {match(materialNode)
          .with({ commodity: P.nonNullable }, (nodeWithKnownCommodity) => (
            <CommodityName commodity={nodeWithKnownCommodity.commodity} />
          ))
          .with(
            {
              commodity: null,
              consumingRecoveryGoalNode: null,
            },
            () => <Text c='red'>Unspecified</Text>,
          )
          .with(
            {
              commodity: null,
              consumingRecoveryGoalNode: P.nonNullable,
            },
            () => <Text>Intermediate</Text>,
          )
          .otherwise(() => (
            <Text c='red'>Unknown</Text>
          ))}
      </LabeledValue>
      <LabeledValue label='Mass'>
        <WithUnit
          unitSuffix={
            materialNode.weightUnit !== null
              ? getWeightUnitAbbreviation(materialNode.weightUnit)
              : 'lb'
          }
        >
          {Math.trunc(materialNode.mass)}
        </WithUnit>
      </LabeledValue>
      <LabeledValue label='Price'>
        <SpreadNumber value={materialNode.valuePerWeightUnit} />
      </LabeledValue>

      <LabeledValue label='Value'>
        <SpreadNumber value={materialNode.value} />
      </LabeledValue>
    </>
  );
}

export function CommoditySpreadRecoveryNodeBody(
  recoveryGoalNode: CommoditySpreadRecoveryGoalNodeDTO,
) {
  return (
    <>
      <LabeledValue label='Recovery Goal'>
        <RecoveryGoalName recoveryGoal={recoveryGoalNode.recoveryGoal} />
      </LabeledValue>
      <LabeledValue label='Direct Spread'>
        <Flex direction='column'>
          <SpreadNumber value={recoveryGoalNode.localSpreadValue} />
          <SpreadNumber
            value={recoveryGoalNode.localSpreadValuePerWeightUnit}
            weightUnit={recoveryGoalNode.weightUnit}
          />
        </Flex>
      </LabeledValue>
      <LabeledValue label='Aggregate Spread'>
        <Flex direction='column'>
          <SpreadNumber value={recoveryGoalNode.globalSpreadValue} />
          <SpreadNumber
            value={recoveryGoalNode.globalSpreadValuePerWeightUnit}
            weightUnit={recoveryGoalNode.weightUnit}
          />
        </Flex>
      </LabeledValue>
      <LabeledValue label='Aggregate Value'>
        <Flex direction='column'>
          <SpreadNumber
            value={recoveryGoalNode.descendentMaterialNodeAggregateValue}
          />
          <SpreadNumber
            value={
              recoveryGoalNode.descendentMaterialNodeAggregateValuePerWeightUnit
            }
            weightUnit={recoveryGoalNode.weightUnit}
          />
        </Flex>
      </LabeledValue>
    </>
  );
}

type SpreadNumberProps = {
  value: number | null;
  weightUnit?: WeightUnit | null;
};

export function SpreadNumber(props: SpreadNumberProps) {
  const { value, weightUnit } = props;
  if (value === null) return <Text color='red'>N/A</Text>;
  const formatted = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);
  return (
    <Text color={value !== 0 ? (value < 0 ? 'red' : 'green') : 'blue'}>
      <WithUnit
        unitPrefix='$'
        unitSuffix={
          weightUnit !== undefined
            ? weightUnit !== null
              ? `/${getWeightUnitAbbreviation(weightUnit)}`
              : '/lb'
            : undefined
        }
      >
        {formatted}
      </WithUnit>
    </Text>
  );
}
