import { useCallback, useEffect, useMemo } from 'react';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { Router } from '../router';

type EmptyRecord = Record<string | number | symbol, never>;

type AllRoutes = Exclude<ReturnType<typeof Router.useRoute>, undefined>;

export type RouteParams = {
  [K in AllRoutes as K['params'] extends EmptyRecord
    ? never
    : K['name']]: K['params'];
};

type JustOptionalRouteParams = {
  [R in keyof RouteParams]: {
    [K in keyof RouteParams[R] as undefined extends RouteParams[R][K]
      ? K
      : never]: RouteParams[R][K];
  };
};
export type OptionalRouteParams = {
  [R in keyof JustOptionalRouteParams as JustOptionalRouteParams[R] extends EmptyRecord
    ? never
    : R]: JustOptionalRouteParams[R];
};

type RouteName = keyof RouteParams;

export type RouteParamsSetterFn<R extends RouteName> = (
  newValue: Partial<RouteParams[R]>,
  opts?: { replace?: boolean },
) => void;

export type UseRouteParamsReturn<R extends RouteName> = readonly [
  RouteParams[R],
  RouteParamsSetterFn<R>,
];

type Simplify<T> = {
  [KeyType in keyof T]: T[KeyType];
} & {};

type ExpandRouteParamUnion<
  TRoutes extends readonly unknown[],
  A = never,
> = TRoutes extends [infer H, ...infer R]
  ? H extends RouteName
    ? ExpandRouteParamUnion<R, A | RouteParams[H]>
    : never
  : A;

type RouteParamUnion<TRoutes extends readonly RouteName[]> =
  ExpandRouteParamUnion<TRoutes>;
type CommonRouteParamUnion<TRoutes extends readonly RouteName[]> = Simplify<
  Pick<RouteParamUnion<TRoutes>, keyof ExpandRouteParamUnion<TRoutes>>
>;

export function useRouteParams<
  TRoutes extends readonly [RouteName, ...RouteName[]],
>(
  routes: TRoutes,
): readonly [
  RouteParamUnion<TRoutes>,
  (
    params: Partial<CommonRouteParamUnion<TRoutes>>,
    opts?: { replace?: boolean },
  ) => void,
] {
  if (routes.length === 0) throw new Error('at least one route is required');

  const currentRoute = Router.useRoute(routes);
  if (!currentRoute) {
    throw new Error('cannot use route params for different route');
  }
  const currentParams = currentRoute.params as RouteParamUnion<TRoutes>;
  const setter = useCallback(
    (
      newParams: Partial<CommonRouteParamUnion<TRoutes>>,
      opts?: { replace?: boolean },
    ) => {
      const { replace } = opts ?? {};

      const mergedParams = {
        // this type is not technically correct, but the union fails to compile
        ...currentParams,
        ...newParams,
      } as unknown as Parameters<typeof Router.replace>[1];

      if (replace) {
        Router.replace(currentRoute.name, mergedParams);
      } else {
        Router.push(currentRoute.name, mergedParams);
      }
    },
    [currentParams, currentRoute.name],
  );

  return [currentParams, setter];
}

interface RouteParamStore {
  routeParams: Partial<RouteParams>;
  setParams: <R extends RouteName>(
    routeName: R,
    params: RouteParams[R],
  ) => void;
}

const useRouteParamStore = create<RouteParamStore>()(
  persist(
    (set) => ({
      routeParams: {},
      setParams: (routeName, params) => {
        set((state) => ({
          routeParams: { ...state.routeParams, [routeName]: params },
        }));
      },
    }),
    {
      name: 'valis.route-params',
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
);

export function usePersistentRouteParams<
  TRoutes extends readonly [RouteName, ...RouteName[]],
>(routes: TRoutes) {
  if (routes.length === 0) throw new Error('at least one route is required');

  const storedParams = useRouteParamStore((s) => s.routeParams);
  const setStoredParams = useRouteParamStore((s) => s.setParams);

  const currentRoute = Router.useRoute(routes);

  const [currentRouteParams, setCurrentRouteParams] = useRouteParams(routes);
  if (currentRoute === undefined) throw new Error();

  const storedRoutedParams =
    currentRoute.name in storedParams
      ? storedParams[currentRoute.name]
      : undefined;

  // Merge the route params with the URL taking precedence
  const mergedParams = useMemo(
    () =>
      ({
        ...storedRoutedParams,
        ...(currentRouteParams as CommonRouteParamUnion<TRoutes>),
      }) as RouteParamUnion<TRoutes>,
    [currentRouteParams, storedRoutedParams],
  );

  useEffect(() => {
    if (!paramEqual(currentRouteParams, mergedParams)) {
      setCurrentRouteParams(mergedParams, { replace: true });
    }
    if (!paramEqual(storedRoutedParams ?? {}, mergedParams)) {
      setStoredParams(currentRoute.name, mergedParams);
    }
  }, [
    currentRoute.name,
    currentRouteParams,
    mergedParams,
    setCurrentRouteParams,
    setStoredParams,
    storedRoutedParams,
  ]);

  const setter = useCallback(
    (
      newParams: Partial<CommonRouteParamUnion<TRoutes>>,
      opts?: { replace?: boolean },
    ) => {
      for (const route of routes) {
        if (route === currentRoute.name) {
          setCurrentRouteParams(newParams, opts);
        } else {
          setStoredParams(route, {
            ...storedParams[route],
            ...newParams,
          });
        }
      }
    },
    [
      routes,
      currentRoute.name,
      setCurrentRouteParams,
      setStoredParams,
      storedParams,
    ],
  );

  return [mergedParams, setter] as const;
}

type GenericParams = Record<string, string | string[] | undefined>;

function paramEqual(p1: GenericParams, p2: GenericParams) {
  const p1Keys = new Set(Object.keys(p1));
  const p2Keys = new Set(Object.keys(p2));
  if (p1Keys.size !== p2Keys.size) return false;
  if (![...p1Keys].every((k) => p2Keys.has(k))) return false;
  for (const k of p1Keys) {
    const p1Value = p1[k];
    const p2Value = p2[k];
    if (typeof p1Value !== typeof p2Value) return false;
    if (typeof p1Value === 'string') {
      if (p1Value !== p2Value) return false;
    } else if (typeof p1Value === 'object') {
      if (p1Value.length !== p2Value?.length) return false;
      if (!p1Value.every((v, i) => v === p2Value[i])) return false;
    } else {
      if (p1Value !== p2Value) return false;
    }
  }
  return true;
}
