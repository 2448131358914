import {
  Alert,
  Center,
  Group,
  Loader,
  Paper,
  SelectProps,
  Text,
} from '@mantine/core';
import { IconMinus, IconPlus } from '@tabler/icons-react';
import { ReactFlowProvider } from 'reactflow';
import { match, P } from 'ts-pattern';
import { useDefaultCommodityAssignmentRecoveryTree } from '../api/defaultCommodityAssignment';
import { CommodityName } from '../Commodity/CommodityName';
import { LabeledValue } from '../common';
import {
  DefaultCommodityAssignmentMaterialNodeDTO,
  InternalMaterialSourceId,
  RecoveryStrategyId,
} from '../rest-client';
import {
  RecoveryTreeDiagram,
  RecoveryTreeNode,
  RecoveryTreeNodeVariant,
} from './RecoveryTreeGraph';

type DefaultCommodityAssignmentRecoveryTreeMaterialNodeSelectProps = {
  setSelectedMaterialNode: (
    selected: DefaultCommodityAssignmentMaterialNodeDTO | null,
  ) => void;
};

export type DefaultCommodityAssignmentSelectProps = {
  recoveryStrategyId: RecoveryStrategyId;
  internalMaterialSourceId: InternalMaterialSourceId;
} & DefaultCommodityAssignmentRecoveryTreeMaterialNodeSelectProps &
  Omit<SelectProps, 'data'>;

export function DefaultCommodityAssignmentSelect(
  props: DefaultCommodityAssignmentSelectProps,
) {
  const {
    recoveryStrategyId,
    internalMaterialSourceId,
    setSelectedMaterialNode,
  } = props;

  const {
    data: rootMaterialNode,
    isLoading,
    isLoadingError,
    error,
  } = useDefaultCommodityAssignmentRecoveryTree({
    recoveryStrategyId,
    internalMaterialSourceId,
  });

  if (isLoading) {
    return (
      <Center>
        <Loader variant='bars' size='xl' />
      </Center>
    );
  }
  if (isLoadingError) {
    throw error;
  }

  if (rootMaterialNode === undefined) {
    return (
      <Alert title='No Recovery Tree'>
        There has either been no recovery sequence configured for the selected
        recovery strategy or multiple incompatible sequences configured. Please
        specify a recovery sequence or compatible sequences.
      </Alert>
    );
  }

  return (
    <DefaultCommodityAssignmentRecoveryTree
      rootMaterialNode={rootMaterialNode}
      setSelectedMaterialNode={setSelectedMaterialNode}
    />
  );
}

type DefaultCommodityAssignmentRecoveryTreeProps = {
  rootMaterialNode: DefaultCommodityAssignmentMaterialNodeDTO;
} & DefaultCommodityAssignmentRecoveryTreeMaterialNodeSelectProps;
function DefaultCommodityAssignmentRecoveryTree(
  props: DefaultCommodityAssignmentRecoveryTreeProps,
) {
  const { rootMaterialNode, setSelectedMaterialNode } = props;
  return (
    <Paper withBorder p='xs' w='100%' h={500}>
      <ReactFlowProvider>
        <RecoveryTreeDiagram
          rootNode={{ ...rootMaterialNode, type: 'material' }}
          nodeVariant={RecoveryTreeNodeVariant.defaultCommodityAssignment}
          onNodeSelect={(recoveryTreeNode: RecoveryTreeNode | null) => {
            match(recoveryTreeNode?.data)
              .with(
                {
                  type: 'material',
                  variant: RecoveryTreeNodeVariant.defaultCommodityAssignment,
                },
                ({ materialNode }) =>
                  setSelectedMaterialNode(
                    materialNode as DefaultCommodityAssignmentMaterialNodeDTO,
                  ),
              )
              .otherwise(() => null);
          }}
        />
      </ReactFlowProvider>
    </Paper>
  );
}

export function DefaultCommodityAssignmentMaterialNodeLabel(
  materialNodeDto: DefaultCommodityAssignmentMaterialNodeDTO,
) {
  return (
    <Text>
      {match(materialNodeDto.isNegative)
        .with(null, () => 'Feedstock')
        .with(true, false, (isNegative) => (
          <Group spacing='xs'>
            {isNegative ? <IconMinus /> : <IconPlus />}
            <Text>Fraction</Text>
          </Group>
        ))
        .exhaustive()}
    </Text>
  );
}

export function DefaultCommodityAssignmentMaterialNodeBody(
  materialNodeDto: DefaultCommodityAssignmentMaterialNodeDTO,
) {
  return match(materialNodeDto)
    .with({ commodity: P.nonNullable }, ({ commodity }) => (
      <LabeledValue label='Commodity'>
        <CommodityName commodity={commodity} noLink />
      </LabeledValue>
    ))
    .with(
      { commodity: P.nullish, consumingRecoveryGoalNode: P.nonNullable },
      () => <LabeledValue label='Commodity'>Intermediate</LabeledValue>,
    )
    .with(
      { commodity: P.nullish, consumingRecoveryGoalNode: P.nullish },
      () => <Text c='red'>Unknown</Text>,
    )
    .otherwise(() => <Text c='dimmed'>?</Text>);
}
