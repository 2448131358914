import { Group, MantineColor, Text } from '@mantine/core';
import dayjs from 'dayjs';
import { ReactNode } from 'react';
import { useFacilityContext } from '../Facility/FacilityContext';
import { FeedFlowGroupIcon } from '../Icons';
import { LinkText } from '../Link';
import { RecoveryGoalPathName } from '../RecoveryGoal/RecoveryGoalPathName';
import { IconText } from '../Repository/RepositoryName';
import { useFeedFlowGroup } from '../api/feedFlowGroup';
import {
  FeedFlowGroupDTO,
  ProcessOutputPortRecoveryGoalPathDTO,
} from '../rest-client';
import { Router } from '../router';
import cssClasses from './FeedFlowGroupName.module.css';

export type FeedFlowGroupIdNameProps = Omit<
  FeedFlowGroupNameProps,
  'feedFlowGroup'
> & {
  feedFlowGroupId: string;
};

export function FeedFlowGroupIdName(props: FeedFlowGroupIdNameProps) {
  const { feedFlowGroupId, ...rest } = props;
  const ffgQuery = useFeedFlowGroup(feedFlowGroupId);
  if (ffgQuery.data !== undefined) {
    return <FeedFlowGroupName feedFlowGroup={ffgQuery.data} {...rest} />;
  }
  if (ffgQuery.isLoadingError) {
    return <Text color='red'>Error getting process run name</Text>;
  }
  return <Text color='dimmed'>Loading...</Text>;
}

export interface FeedFlowGroupNameProps {
  feedFlowGroup: FeedFlowGroupDTO;
  noRecoveryGoal?: boolean;
  noProcessName?: boolean;
  noTimestamp?: boolean;
  timestampFormat?: string;
  noLink?: boolean;
  withIcon?: boolean;
  color?: 'dimmed' | MantineColor;
}

export function FeedFlowGroupName(props: FeedFlowGroupNameProps) {
  const {
    feedFlowGroup,
    noRecoveryGoal,
    noProcessName,
    noTimestamp,
    timestampFormat = 'LT',
    noLink,
    withIcon,
    color,
  } = props;
  const { timeZoneId } = useFacilityContext();

  const processSegment = noProcessName
    ? []
    : [feedFlowGroup.processState.processName];

  const recoveryGoalSegment = noRecoveryGoal
    ? []
    : [
        <FeedFlowGroupRecoveryGoalPathName
          recoveryGoalPaths={
            feedFlowGroup.processState.processRecoveryGoalPaths
          }
          key={feedFlowGroup.processState.processId}
          noLink
        />,
      ];

  const timestampSegment =
    noTimestamp || feedFlowGroup.activeIntervals.length === 0
      ? []
      : [
          [
            dayjs
              .utc(feedFlowGroup.activeIntervals[0].start)
              .tz(timeZoneId)
              .format(timestampFormat),
          ],
        ];

  const label = [
    ...processSegment,
    ...recoveryGoalSegment,
    ...timestampSegment,
  ].reduce<ReactNode>(
    (acc, segment) =>
      acc === null ? (
        segment
      ) : (
        <>
          {acc}
          {
            // biome-ignore lint/correctness/useJsxKeyInIterable:
            <Text mx='0.5ch' weight={700} span>
              •
            </Text>
          }
          {segment}
        </>
      ),
    null,
  );

  const IconWrapper = ({ children }: { children: ReactNode }) => {
    if (withIcon) {
      return (
        <IconText>
          <FeedFlowGroupIcon />
          {children}
        </IconText>
      );
    } else {
      return <>{children}</>;
    }
  };

  return (
    <IconWrapper>
      {noLink ? (
        <Text color={color} className={cssClasses.ffgNameTextWrapper}>
          {label}
        </Text>
      ) : (
        <LinkText
          to={Router.FeedFlowGroupDetail({ feedFlowGroupId: feedFlowGroup.id })}
          className={cssClasses.ffgNameTextWrapper}
        >
          {label}
        </LinkText>
      )}
    </IconWrapper>
  );
}

export function FeedFlowGroupRecoveryGoalPathName(props: {
  recoveryGoalPaths: ProcessOutputPortRecoveryGoalPathDTO[];
  noLink: boolean;
}) {
  const { recoveryGoalPaths, noLink } = props;

  let firstNegativeOutputPortPathIdx = -1;
  // If we know the path for all output ports, we can omit one of them
  if (!recoveryGoalPaths.some((path) => path.recoveryGoalPath === null)) {
    // We don't have a great hueristic for which path to omit, but we prefer + over - and long over short
    firstNegativeOutputPortPathIdx = recoveryGoalPaths.findIndex((path) => {
      if (path.recoveryGoalPath === null) {
        throw new Error('path cannot be null');
      }
      return path.recoveryGoalPath.steps[path.recoveryGoalPath.steps.length - 1]
        .negative;
    });
    if (firstNegativeOutputPortPathIdx === -1) {
      throw new Error('expected at least one output to be negative');
    }
  }

  if (recoveryGoalPaths.every((path) => path.recoveryGoalPath === null)) {
    return <Text c='dimmed'>Unknown Recovery Path</Text>;
  }

  return (
    <Group noWrap spacing={5} className={cssClasses.ffgRecoverGoalNameWrapper}>
      {recoveryGoalPaths
        .filter((_, i) => i !== firstNegativeOutputPortPathIdx)
        .map((path) =>
          path.recoveryGoalPath === null ? (
            <Text c='dimmed' key={path.outputPortId}>
              Unknown
            </Text>
          ) : (
            <RecoveryGoalPathName
              steps={path.recoveryGoalPath.steps}
              key={path.outputPortId}
              noLink={noLink}
            />
          ),
        )
        .reduce<ReactNode>(
          (acc, pathName) =>
            acc === null ? (
              pathName
            ) : (
              // biome-ignore lint/correctness/useJsxKeyInIterable: <explanation>
              <>
                {acc} {pathName}
              </>
            ),
          null,
        )}
    </Group>
  );
}
