import { useMutation, useQuery } from '@tanstack/react-query';
import {
  InsightExportService,
  InsightSampleExportCreationDTO,
} from '../rest-client';
import { QueryFunctionContexts } from './queryKeyTypeUtils';
import { queryKeys, useQueryKeyInvalidator } from './queryKeys';

async function fetchExportPreview() {
  return await InsightExportService.getExportPreview();
}

export function useExportPreview() {
  return useQuery({
    queryKey: queryKeys.sample.insightExport.preview,
    queryFn: fetchExportPreview,
  });
}

async function fetchInsightSampleExport(
  ctx: QueryFunctionContexts['sample']['insightExport']['detail'],
) {
  const [{ id }] = ctx.queryKey;
  return await InsightExportService.getSampleExport(id);
}

export function useInsightSampleExport(id: string) {
  return useQuery({
    queryKey: queryKeys.sample.insightExport.detail(id),
    queryFn: fetchInsightSampleExport,
  });
}

async function fetchInsightSampleExports() {
  return await InsightExportService.getSampleExports();
}

export function useInsightSampleExports() {
  return useQuery({
    queryKey: queryKeys.sample.insightExport.list(),
    queryFn: fetchInsightSampleExports,
  });
}

async function createInsightSampleExport(dto: InsightSampleExportCreationDTO) {
  await InsightExportService.createSampleExport(dto);
}

export function useCreateInsightSampleExport() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: createInsightSampleExport,
    onSettled() {
      invalidator.invalidateKeys(queryKeys.sample.insightExport.all);
    },
  });
}
