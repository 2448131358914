import { Loader, Stack } from '@mantine/core';
import dayjs from 'dayjs';
import { CompositionChart } from '../../../CompositionChart';
import Temporal from '../../../Temporal/temporal.ts';
import { useRedWaveTelemetry } from '../../../api/redWave';
import {
  RedWaveCompositionChartControls,
  useRedWaveChartControls,
} from '../../../redwave/RedWaveCompositionChartControls.tsx';
import {
  RedWaveSortSystemDTO,
  RedWaveStream,
  RedWaveTelemetryDTO,
} from '../../../rest-client';
import { mixture } from '../../../util/mixture';
import { RedWaveMaterialClassObjectCountChart } from './RedWaveMaterialClassObjectCountChart';

function CumulativeRedWaveSystemMetricsCharts(props: {
  stream: RedWaveStream;
  telemetry: RedWaveTelemetryDTO;
}) {
  const { telemetry, stream } = props;

  const filteredClassMetadata = Object.fromEntries(
    Object.entries(telemetry.materialClasses)
      .filter(
        ([, { ejected }]) =>
          stream === RedWaveStream.INPUT ||
          ejected === (stream === RedWaveStream.EJECT),
      )
      .map(([classNumber, metadata]) => [classNumber, metadata]),
  );

  const initiallySelectedClasses = mixture(
    telemetry.cumulativeTelemetry.classAreaProportions,
  )
    .submixture((c) => c !== '0')
    .topKeys(7)
    .map(Number);

  const { chartComposition, chartCounts, controlState, setControlState } =
    useRedWaveChartControls(filteredClassMetadata, {
      backgroundClassIncluded: false,
      selectedClasses: new Set(initiallySelectedClasses),
      renormalize: false,
    });

  const classAreaProportions = chartComposition(
    mixture(
      Object.keys(filteredClassMetadata).map((classNumber) => [
        Number(classNumber),
        telemetry.cumulativeTelemetry.classAreaProportions[classNumber],
      ]),
    ),
  );

  const classObjectCounts = chartCounts(
    mixture(
      Object.keys(filteredClassMetadata).map((classNumber) => [
        Number(classNumber),
        telemetry.cumulativeTelemetry.classObjectCounts[classNumber],
      ]),
    ),
  );

  return (
    <Stack>
      <RedWaveCompositionChartControls
        classMetadata={filteredClassMetadata}
        value={controlState}
        onChange={setControlState}
      />
      <CompositionChart
        composition={classAreaProportions}
        title='Material Class Areas'
        xAxisText='Material Class'
        yAxisText='Proportion'
        rotateXAxisLabels={false}
      />
      <RedWaveMaterialClassObjectCountChart
        classObjectCounts={classObjectCounts}
      />
    </Stack>
  );
}

export function CumulativeRedWaveSystemMetrics(props: {
  system: RedWaveSortSystemDTO;
  stream: RedWaveStream;
  startTime: Temporal.Instant;
  endTime?: Temporal.Instant;
}) {
  const { system, stream, startTime, endTime } = props;
  const start = dayjs.utc(startTime.epochMilliseconds);
  const end = endTime ? dayjs.utc(endTime.epochMilliseconds) : undefined;
  const telemetryQuery = useRedWaveTelemetry(system.id, start, end);

  if (telemetryQuery.isLoadingError) {
    throw telemetryQuery.error;
  }

  if (telemetryQuery.isLoading) {
    return <Loader />;
  }

  const telemetry = telemetryQuery.data;
  return (
    <CumulativeRedWaveSystemMetricsCharts
      telemetry={telemetry}
      stream={stream}
    />
  );
}
