import { Container, Stack } from '@mantine/core';
import { useEffect } from 'react';
import { ReactFlowProvider } from 'reactflow';
import {
  RecoveryTreeDiagram,
  RecoveryTreeNodeVariant,
} from '../RecoveryTree/RecoveryTreeGraph';
import { useRecoveryStrategySimulationCtx } from './RecoveryStrategySimulationContext';

export function RecoveryStrategySimulationTree() {
  const { simulation } = useRecoveryStrategySimulationCtx();

  if (simulation.rootMaterialNode === null) {
    return undefined;
  }

  return (
    <Stack>
      <Container p='xs' w='100%' h={700}>
        <ReactFlowProvider>
          <RecoveryTreeDiagram
            rootNode={{ ...simulation.rootMaterialNode, type: 'material' }}
            nodeVariant={RecoveryTreeNodeVariant.commoditySpread}
          />
        </ReactFlowProvider>
      </Container>
    </Stack>
  );
}
