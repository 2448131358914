import { Group, Input, Switch } from '@mantine/core';
import { UseFormReturnType, useForm, zodResolver } from '@mantine/form';
import { z } from 'zod';
import SwitchAsLabeledInputClasses from '../../Input/SwitchAsLabeledInput.module.css';
import { RecoveryPathEdit } from '../../RecoveryGoal/RecoveryPathEdit';
import { CommoditySelect } from '../CommoditySelect';

const DefaultCommodityAssignmentFormSchema = z
  .object({
    inputCommodityId: z
      .string()
      .uuid()
      .nullable()
      .refine((val) => val !== null, {
        message: 'Input commodity is required',
      }),
    steps: z
      .object({
        recoveryGoalId: z
          .string()
          .uuid()
          .nullable()
          .refine((val) => val !== null, {
            message: 'Recovery goal is required for each step',
          }),
        negative: z.boolean(),
      })
      .array()
      .nonempty({ message: 'At least one recovery step is required' }),
    outputCommodityId: z.string().uuid().nullable(),
    intermediate: z.boolean(),
  })
  .refine(
    ({ outputCommodityId, intermediate }) =>
      (intermediate && outputCommodityId === null) ||
      (!intermediate && outputCommodityId !== null),
    {
      message:
        'Output commodity is required unless the output is marked as intermediate',
      path: ['outputCommodityId'],
    },
  );

export type DefaultCommodityAssignmentFormValues = z.infer<
  typeof DefaultCommodityAssignmentFormSchema
>;

export function useDefaultCommodityAssignmentForm() {
  return useForm<DefaultCommodityAssignmentFormValues>({
    initialValues: {
      // biome-ignore lint/style/noNonNullAssertion: <explanation>
      inputCommodityId: null!,
      // biome-ignore lint/style/noNonNullAssertion: <explanation>
      steps: [{ recoveryGoalId: null!, negative: false }],
      outputCommodityId: null,
      intermediate: false,
    },
    validate: zodResolver(DefaultCommodityAssignmentFormSchema),
  });
}

export function DefaultCommodityAssignmentFormFields(props: {
  form: UseFormReturnType<DefaultCommodityAssignmentFormValues>;
}) {
  const { form } = props;

  return (
    <>
      <CommoditySelect
        label='Input Commodity'
        description='The input commodity this default recovery path behavior is predicated upon.'
        placeholder='select commodity'
        withAsterisk
        searchable
        {...form.getInputProps('inputCommodityId')}
      />
      <Input.Wrapper
        label='Recovery Path'
        description='The ordered recovery steps performed on the input commodity that result in the output commodity.'
        withAsterisk
      >
        <Group>
          <RecoveryPathEdit form={form} accessor={(v) => v} />
        </Group>
      </Input.Wrapper>
      <Input.Wrapper
        label='Intermediate Output'
        description='Is the output of the specified recovery steps on the specified input commidity expected to be an intermediate product and not a commodity?'
      >
        <Switch
          size='lg'
          className={SwitchAsLabeledInputClasses.switch}
          checked={form.values.intermediate}
          onChange={(e) => {
            form.setFieldValue('intermediate', e.currentTarget.checked);
            // reset the output commodity
            if (e.currentTarget.checked) {
              form.setFieldValue('outputCommodityId', null);
            }
          }}
        />
      </Input.Wrapper>
      <CommoditySelect
        label='Output Commodity'
        description='The output commodity to assign by default to material processed by the specified recovery path behavior on the specified input commodity.'
        placeholder={
          form.values.intermediate ? 'Intermediate' : 'Select commodity'
        }
        withAsterisk={!form.values.intermediate}
        disabled={form.values.intermediate}
        searchable
        {...form.getInputProps('outputCommodityId')}
      />
    </>
  );
}
