import { Dayjs } from 'dayjs';
import { LineChart } from 'echarts/charts';
import {
  DatasetComponent,
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent,
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { match } from 'ts-pattern';
import {
  useChutecMaterialClassAreaWindows,
  useChutecMaterialClasses,
} from '../../../api/chutec';
import { EChart } from '../../../echarts/BareEChart';
import { ChutecStream, SortSystemId } from '../../../rest-client';
import cssClasses from './ChutecMaterialClassTimeseriesChart.module.css';

echarts.use([
  LineChart,
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent,
  DatasetComponent,
]);

export interface ChutecMaterialClassTimeseriesChartProps {
  systemId: SortSystemId;
  startTime: Dayjs;
  endTime: Dayjs | undefined;
  stream: ChutecStream;
  mode: 'cumulative' | 'differential';
}
export function ChutecMaterialClassTimeseriesChart(
  props: ChutecMaterialClassTimeseriesChartProps,
) {
  const { systemId, startTime, endTime, stream, mode } = props;
  const windowsQuery = useChutecMaterialClassAreaWindows(
    systemId,
    stream,
    startTime,
    endTime,
  );

  const materialClassIds =
    windowsQuery.data && Object.keys(windowsQuery.data.areasM2);
  const populatedClassIds = materialClassIds?.filter(
    (materialClassId) =>
      !windowsQuery.data?.areasM2[materialClassId].every((v) => v === 0),
  );

  const materialClassQueries = useChutecMaterialClasses(materialClassIds);

  const materialClassNames = Object.fromEntries(
    materialClassQueries.flatMap((q) =>
      q.data ? [[q.data.id, q.data.name]] : [],
    ),
  );

  const cumulativeAreasM2 = windowsQuery.data?.areasM2 ?? {};
  const totalAreasM2 = windowsQuery.data?.totalAreasM2 ?? [];
  // TODO(chutec): Move this into memo/only calculate what's needed
  const relativeCumulativeAreas = Object.fromEntries(
    Object.entries(cumulativeAreasM2).map(([materialClassId, areas]) => [
      materialClassId,
      areas.slice(1).map((a, i) => {
        const totalArea = totalAreasM2[i + 1];
        if (totalArea === 0) {
          return null;
        }
        return a / totalArea;
      }),
    ]),
  );

  const differentialCumulativeAreas = Object.fromEntries(
    Object.entries(cumulativeAreasM2).map(([materialClassId, areas]) => [
      materialClassId,
      areas.slice(1).map((a, i) => {
        const differentialTotalArea = totalAreasM2[i + 1] - totalAreasM2[i];
        if (differentialTotalArea === 0) {
          return null;
        }

        return (a - areas[i]) / differentialTotalArea;
      }),
    ]),
  );

  const areas = match(mode)
    .with('cumulative', () => relativeCumulativeAreas)
    .with('differential', () => differentialCumulativeAreas)
    .exhaustive();

  return (
    <EChart
      className={cssClasses.timeseriesChart}
      option={{
        title: {
          text: match(mode)
            .with('cumulative', () => `Cumulative ${stream} distribution`)
            .with('differential', () => `Differential ${stream} distribution`)
            .exhaustive(),
        },
        dataset: {
          source: {
            timestamps: windowsQuery.data?.timestamps ?? [],
            ...areas,
          },
        },
        xAxis: {
          type: 'time',
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            // TODO(chutec): useCallback
            formatter: (val: number | null) =>
              `${val === null ? 'unknown ' : val * 100}%`,
          },
        },
        series: (populatedClassIds ?? []).map((materialClassId) => ({
          type: 'line',
          encode: { x: 'timestamps', y: materialClassId },
          name: materialClassNames[materialClassId] || 'Loading...',
        })),
        tooltip: {
          trigger: 'axis',
          // TODO(chutec): useCallback
          valueFormatter: (val: number | null) =>
            `${val === null ? 'unknown ' : (val * 100).toFixed(1)}%`,
        },
        legend: {},
      }}
    />
  );
}
