import { Alert, Loader, Select, SelectProps } from '@mantine/core';
import { ReactNode } from 'react';
import { useRecoveryStrategies } from '../api/recoveryStrategy';
import { RecoveryStrategyId } from '../rest-client';

export interface RecoveryStrategySelectSpecificProps {
  value: RecoveryStrategyId | null;
  onChange: (id: RecoveryStrategyId | null) => void;
  emptyContent?: ReactNode;
}

export type RecoveryStrategySelectProps = Omit<
  SelectProps,
  keyof RecoveryStrategySelectSpecificProps | 'data'
> &
  RecoveryStrategySelectSpecificProps;

export function RecoveryStrategySelect(props: RecoveryStrategySelectProps) {
  const {
    value,
    onChange,
    emptyContent = (
      <Alert title='No Recovery Strategies' color='red'>
        There are no recovery strategies to select from.
      </Alert>
    ),
    ...selectProps
  } = props;

  const {
    isError,
    error,
    isLoading,
    data: recoveryStrategies,
  } = useRecoveryStrategies();

  if (isError) {
    throw error;
  }

  if (isLoading) {
    return <Select data={[]} disabled rightSection={<Loader size='xs' />} />;
  }

  if (recoveryStrategies.length === 0) {
    return <>{emptyContent}</>;
  }

  return (
    <Select
      label='Recovery Strategy'
      placeholder='select recovery strategy'
      value={value}
      onChange={onChange}
      data={recoveryStrategies.map((rs) => ({
        value: rs.id,
        label: rs.name,
      }))}
      {...selectProps}
    />
  );
}
