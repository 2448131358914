import {
  Anchor,
  AnchorProps,
  Button,
  ButtonProps,
  MantineColor,
  NavLink,
} from '@mantine/core';
import { useLinkProps } from '@swan-io/chicane';
import { ReactNode } from 'react';

interface LinkProps {
  to: string;
  label: ReactNode;
  icon?: ReactNode;
  color?: 'dimmed' | MantineColor;
  className?: string;
}

export function Link({ to, icon, label, className = '' }: LinkProps) {
  const linkProps = useLinkProps({ href: to });
  return (
    <NavLink {...linkProps} icon={icon} label={label} className={className} />
  );
}

export type LinkTextProps = Omit<AnchorProps, 'href' | 'onClick'> & {
  to: string;
  replace?: boolean;
  newTab?: boolean;
};

export function LinkText(props: LinkTextProps) {
  const { to, replace = false, newTab = false, ...rest } = props;
  const { active, onClick } = useLinkProps({ href: to, replace });
  const newTabProps = newTab
    ? { target: '_blank', rel: 'noopener noreferrer' }
    : { onClick };
  return <Anchor href={to} {...newTabProps} {...rest} />;
}

type LinkButtonProps = Omit<ButtonProps, 'component' | 'href' | 'onClick'> & {
  to: string;
  replace?: boolean;
};
export function LinkButton(props: LinkButtonProps) {
  const { to, replace, children, ...other } = props;
  const { active, onClick } = useLinkProps({ href: to, replace });

  return (
    <Button component='a' href={to} onClick={onClick} {...other}>
      {children}
    </Button>
  );
}
