import {
  Flex,
  Group,
  NumberInput,
  Select,
  Stack,
  Textarea,
  Title,
} from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { useForm, UseFormReturnType, zodResolver } from '@mantine/form';
import React, { useState } from 'react';
import { match, P } from 'ts-pattern';
import { z } from 'zod';
import { AppPage } from '../App/AppPage';
import { CheckboxInput } from '../Form/CheckboxInput';
import { DatetimePickerWithNowActionIcon } from '../Form/DatetimePickerWithNowActionIcon';
import { FormActionButton } from '../Form/FormActionButton';
import { useFormNavBlocker } from '../Form/useFormNavBlocker';
import { WeightUnit } from '../rest-client';
import QuantizedWeightTicksInput from '../Weights/QuantizedWeightTicksInput';
import { OptionalWeightSchema } from '../Weights/WeightZodSchemas';

/**
 * Notes
 *
 * production interval = [firstBaleOnInfeedTime, lastCanAtBalerTime]
 * Supplier should be uneditable. Load number (control number) specifies supplier.
 */

const downtimeSchema = z.object({
  reason: z.string(),
  minutes: z.number().positive(),
});

const loadSchema = z.string();

const datetimeSchema = z.instanceof(Date).nullable();

const dailyProductionFormSchema = z.object({
  loadNumber: loadSchema.nullable(),
  date: datetimeSchema,
  moveLoadToInfeedTime: datetimeSchema,
  firstBaleOnInfeedTime: datetimeSchema,
  firtCanAtBalerTime: datetimeSchema,
  firstBaleAtBalerTime: datetimeSchema,
  lastCanAtBalerTime: datetimeSchema,
  lastBaleAtBalerTime: datetimeSchema,
  finalWeight: OptionalWeightSchema,
  looseCansWeight: OptionalWeightSchema,
  looseCansFromOtherLoadNumber: loadSchema.nullable(),
  numberOfPeople: z.number().int().positive(),
  lunchDowntime: downtimeSchema.nullable(),
  nonLunchDowntimes: z.array(downtimeSchema),
  notes: z.string().nullable(),
});

type DailyProductionFormValues = z.infer<typeof dailyProductionFormSchema>;

const datetimeFormat = 'YYYY-MM-DD hh:mm a';

function useDailyProductionForm() {
  return useForm<DailyProductionFormValues>({
    initialValues: {
      loadNumber: '',
      date: null,
      moveLoadToInfeedTime: null,
      firstBaleOnInfeedTime: null,
      firtCanAtBalerTime: null,
      firstBaleAtBalerTime: null,
      lastCanAtBalerTime: null,
      lastBaleAtBalerTime: null,
      finalWeight: {
        enabled: false,
        unit: WeightUnit.POUND,
        tickSize: 1,
        ticks: null,
      },
      looseCansWeight: {
        enabled: false,
        unit: WeightUnit.POUND,
        tickSize: 1,
        ticks: null,
      },
      looseCansFromOtherLoadNumber: '',
      numberOfPeople: 0,
      lunchDowntime: {
        reason: 'Lunch',
        minutes: 0,
      },
      nonLunchDowntimes: [],
      notes: '',
    },
    validate: zodResolver(dailyProductionFormSchema),
  });
}

function DailyProductionFormFields(props: {
  form: UseFormReturnType<DailyProductionFormValues>;
}) {
  const { form } = props;

  const suppliers = ['Supplier Name'];
  const downtimeReasons = ['Lunch', 'Baler Down'];

  const [looseCansLoadNumberIsSame, setLooseCansLoadNumberIsSame] =
    useState<boolean>(false);

  return (
    <Flex direction='column' maw={700}>
      <Flex direction='column' align='flex-start' gap='xl'>
        <Flex direction='column'>
          <Title order={4}>Details</Title>
          <Flex align='flex-start' gap='xl'>
            <DateInput
              label='Date'
              placeholder='select date'
              withAsterisk
              {...form.getInputProps('date')}
            />
            <Select
              label='Supplier'
              data={suppliers}
              value={suppliers[0]}
              disabled
            />
            <NumberInput
              label='Load Number'
              placeholder='enter load number'
              hideControls
              withAsterisk
              min={0}
              {...form.getInputProps('loadNumber')}
            />
          </Flex>
        </Flex>

        <Flex align='start' gap='xl'>
          <Flex direction='column'>
            <Title order={4}>Times</Title>
            <Flex direction='column' align='start' gap='xl'>
              <DatetimePickerWithNowActionIcon
                variant='datetime'
                label='First Can at Baler Time'
                placeholder='select date-time'
                valueFormat={datetimeFormat}
                withAsterisk
                {...form.getInputProps('firtCanAtBalerTime')}
              />
              <DatetimePickerWithNowActionIcon
                variant='datetime'
                label='First Bale at Baler Time'
                placeholder='select date-time'
                valueFormat={datetimeFormat}
                withAsterisk
                {...form.getInputProps('firstBaleAtBalerTime')}
              />
              <DatetimePickerWithNowActionIcon
                variant='datetime'
                label='Last Can at Baler Time'
                placeholder='select date-time'
                valueFormat={datetimeFormat}
                withAsterisk
                {...form.getInputProps('lastCanAtBalerTime')}
              />
              <DatetimePickerWithNowActionIcon
                variant='datetime'
                label='Last Bale at Baler Time'
                placeholder='select date-time'
                valueFormat={datetimeFormat}
                withAsterisk
                {...form.getInputProps('lastBaleAtBalerTime')}
              />
            </Flex>
          </Flex>
          <Flex direction='column'>
            <Title order={4}>Labor</Title>
            <Flex direction='column' align='start' gap='xl'>
              <NumberInput
                label='Number of People'
                placeholder='enter people #'
                withAsterisk
                min={0}
                {...form.getInputProps('numberOfPeople')}
              />
              <Flex align='start' gap='xl'>
                <DowntimeReasonTimeInput
                  downtimeReasonInputProps={{
                    defaultValue: downtimeReasons[0],
                    error: form.errors.lunchDowntime,
                  }}
                  downtimeMinutesInputProps={{
                    ...form.getInputProps('lunchDowntime.minutes'),
                  }}
                  downtimeReasons={downtimeReasons}
                />
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Flex direction='column'>
          <Title order={4}>Weights</Title>
          <Flex direction='column' align='start' gap='xl'>
            <QuantizedWeightTicksInput
              label='Final Weight'
              placeholder='enter weight'
              unit={form.values.finalWeight.unit ?? WeightUnit.POUND}
              tickSize={form.values.finalWeight.tickSize ?? 1}
              min={0}
              {...form.getInputProps('finalWeight.ticks')}
            />
            <Flex align='start' gap='xl'>
              <QuantizedWeightTicksInput
                label='Loose Cans Weight'
                placeholder='enter weight'
                unit={form.values.looseCansWeight.unit ?? WeightUnit.POUND}
                tickSize={form.values.looseCansWeight.tickSize ?? 1}
                min={0}
                {...form.getInputProps('looseCansWeight.ticks')}
              />
              <Flex gap='sm'>
                <NumberInput
                  label='Loose Cans Load Number'
                  placeholder='enter load number'
                  min={0}
                  step={1}
                  hideControls
                  disabled={looseCansLoadNumberIsSame}
                  {...(looseCansLoadNumberIsSame
                    ? { value: form.values.loadNumber, onChange: undefined }
                    : form.getInputProps('looseCansFromOtherLoadNumber'))}
                />
                <CheckboxInput
                  label='Same Load Number'
                  checked={looseCansLoadNumberIsSame}
                  onChange={(e) =>
                    setLooseCansLoadNumberIsSame(e.target.checked)
                  }
                />
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Title order={4} mt='xl'>
        Notes
      </Title>
      <Textarea
        placeholder='enter any notes'
        {...form.getInputProps('notes')}
      />
    </Flex>
  );
}

type FormInputProps<TValue> = {
  value: TValue;
  onChange: (value: TValue) => void;
  checked?: boolean;
  error?: React.ReactNode;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
};

type FormDefaultedInputProps<TValue> = Omit<
  FormInputProps<TValue>,
  'value' | 'onChange'
> & {
  defaultValue: TValue;
};

function DowntimeReasonTimeInput(props: {
  downtimeReasonInputProps:
    | FormInputProps<string>
    | FormDefaultedInputProps<string>;
  downtimeMinutesInputProps: FormInputProps<number>;
  downtimeReasons: string[];
}) {
  const {
    downtimeReasonInputProps,
    downtimeMinutesInputProps,
    downtimeReasons,
  } = props;

  return (
    <>
      <Select
        label='Downtime Reason'
        placeholder='select reason'
        data={downtimeReasons}
        withAsterisk
        {...match(downtimeReasonInputProps)
          .with({ defaultValue: P.string }, (formInputProps) => {
            return { value: formInputProps.defaultValue, ...formInputProps };
          })
          .with({ value: P.string }, (formInputProps) => {
            return { ...formInputProps };
          })
          .exhaustive()}
      />
      <NumberInput
        label='Downtime Minutes'
        placeholder='enter minutes'
        min={0}
        step={1}
        withAsterisk
        {...downtimeMinutesInputProps}
      />
    </>
  );
}

export function AddSchupanDailyProductionPage() {
  const form = useFormNavBlocker(useDailyProductionForm());

  return (
    <AppPage title='Add Daily Production Report'>
      <AppPage.Section>
        <form onSubmit={() => undefined}>
          <DailyProductionFormFields form={form} />
          <Stack spacing='md' mt='2rem' mx={0} mb={0} p={0}>
            <Group position='right'>
              <FormActionButton
                action='cancel'
                onClick={() => undefined}
                loading={false}
              />
              <FormActionButton
                type='submit'
                action={'saveCreation'}
                disabled={false}
                loading={false}
              />
            </Group>
          </Stack>
        </form>
      </AppPage.Section>
    </AppPage>
  );
}
