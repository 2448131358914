import { NavigationClient, NavigationOptions } from '@azure/msal-browser';
import { pushUnsafe, replaceUnsafe } from '@swan-io/chicane';

export class ChicaneNavigationClient extends NavigationClient {
  async navigateInternal(url: string, options: NavigationOptions) {
    const relativePath = url.replace(window.location.origin, '');
    if (options.noHistory) {
      replaceUnsafe(relativePath);
    } else {
      pushUnsafe(relativePath);
    }

    return false;
  }
}
