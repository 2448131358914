import { Group, Stack, Title } from '@mantine/core';
import { useFlattenedSamples } from '../api/sample';
import { AppPage } from '../App/AppPage';
import { useTenantContext } from '../App/TenantContext';
import { SamplingIcon } from '../Icons';
import { LinkButton } from '../Link';
import { MaterialClassSetSelect } from '../MaterialClassSet/MaterialClassSetSelect';
import { Router } from '../router';
import { TableLoader } from '../Table/TableLoading';
import { InsightExportButton } from './InsightExport';
import { AllSamplesTable } from './SampleTable/AllSamplesTable';
import { useAllSamplesTableStore } from './SampleTable/AllSamplesTableState';
import { IncompleteSampleTable } from './SampleTable/IncompleteSampleTable';

export function SamplingPage() {
  const { materialClassSetId, setMaterialClassSetId } =
    useAllSamplesTableStore();
  const {
    data: materialClassSetSamples,
    error,
    isLoadingError,
    isLoading,
    failureCount,
  } = useFlattenedSamples({ commodityId: undefined });

  const tenant = useTenantContext();

  if (isLoadingError) {
    throw error;
  }

  return (
    <AppPage
      title='Samples'
      titleRight={
        <Group align='flex-end'>
          <MaterialClassSetSelect
            value={materialClassSetId}
            onChange={setMaterialClassSetId}
            firstSelectedByDefault
          />
          <LinkButton
            leftIcon={<SamplingIcon />}
            to={Router.SampleCreateNotional()}
          >
            Record New Material Sample
          </LinkButton>
          <LinkButton
            leftIcon={<SamplingIcon />}
            to={Router.SampleCreateContainer()}
            disabled
          >
            Record New Container Sample
          </LinkButton>
        </Group>
      }
    >
      <AppPage.Section>
        <Stack>
          <Title order={2}>Incomplete Samples</Title>
          {materialClassSetId === null || isLoading ? (
            <TableLoader height='200px' failureCount={failureCount} />
          ) : (
            <IncompleteSampleTable
              unanalyzedSamplesMetadata={
                materialClassSetSamples.unanalyzedSamples
              }
              inProgressSamples={
                materialClassSetSamples.materialClassSetToSamplesMap[
                  materialClassSetId
                ].incompleteSamples
              }
            />
          )}
        </Stack>
      </AppPage.Section>
      <AppPage.Section>
        <Stack>
          <Group>
            <Title order={2}>Complete Samples</Title>

            {tenant.tenantId === 1 ? <InsightExportButton /> : null}
          </Group>
          {materialClassSetId === null || isLoading ? (
            <TableLoader height='450px' failureCount={failureCount} />
          ) : (
            <AllSamplesTable
              materialClassSetSamples={
                materialClassSetSamples.materialClassSetToSamplesMap[
                  materialClassSetId
                ]
              }
            />
          )}
        </Stack>
      </AppPage.Section>
    </AppPage>
  );
}
