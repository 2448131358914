import { Temporal } from '@js-temporal/polyfill';
import {
  ActionIcon,
  Button,
  Checkbox,
  Group,
  Loader,
  Stack,
  Table,
  useMantineTheme,
} from '@mantine/core';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useFacilityContext } from '../Facility/FacilityContext';
import { SaveIcon } from '../Icons';
import { LinkText } from '../Link';
import { RecoveryGoalPathName } from '../RecoveryGoal/RecoveryGoalPathName';
import { TableEmptyBasicContent } from '../Table/TableEmptyBasicContent';
import { useCommodities } from '../api/commodity';
import {
  useCreateDefaultCommodityAssignment,
  useUnassignedCommodityPaths,
} from '../api/defaultCommodityAssignment';
import { UnassignedCommodityPathDTO } from '../rest-client';
import { Router } from '../router';
import { CommoditySelect } from './CommoditySelect';

export function UnassignedCommodityPathTable() {
  const theme = useMantineTheme();
  const {
    data: unassignedComodityPaths,
    isLoading,
    isLoadingError,
    error,
  } = useUnassignedCommodityPaths();

  if (isLoadingError) {
    throw error;
  }

  const isEmpty =
    unassignedComodityPaths && unassignedComodityPaths.length === 0;

  return (
    <Table>
      {(isLoading || isEmpty) && (
        <caption
          style={{
            captionSide: 'bottom',
            textAlign: 'center',
            padding: theme.spacing.md,
          }}
        >
          {isLoading && <Loader />}
          {!isLoading && isEmpty && (
            <TableEmptyBasicContent>
              No Unassigned Commodity Paths
            </TableEmptyBasicContent>
          )}
        </caption>
      )}
      <thead>
        <tr>
          <th>Input Commodity</th>
          <th>Path</th>
          <th>Process Runs</th>
          <th>Output Commodity</th>
        </tr>
      </thead>
      <tbody>
        {unassignedComodityPaths?.map((unassignedPath) => {
          const { commodityPath } = unassignedPath;
          const key =
            commodityPath.inputCommodityId +
            commodityPath.path.steps
              .map((s) => (s.negative ? '-' : '+') + s.recoveryGoalId)
              .join('');
          return (
            <UnassignedCommodityPathRow
              key={key}
              unassignedPath={unassignedPath}
            />
          );
        }) ?? (
          <tr>
            <td colSpan={3}>
              <Loader />
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
}

function UnassignedCommodityPathRow(props: {
  unassignedPath: UnassignedCommodityPathDTO;
}) {
  const { unassignedPath } = props;
  const { timeZoneId } = useFacilityContext();
  const addMutataion = useCreateDefaultCommodityAssignment();

  const [outputCommodityId, setOutputCommodityId] = useState<string | null>(
    null,
  );
  const [intermediate, setIntermediate] = useState(false);

  const commoditiesQuery = useCommodities();
  const commodityNameLookup =
    commoditiesQuery.data &&
    new Map(commoditiesQuery.data.map((c) => [c.id, c.name]));

  const [showAllFeedFlowGroups, setShowAllFeedFlowGroups] = useState(false);

  if (addMutataion.isSuccess) {
    // optimistic mutation because material state can be slow
    return null;
  }

  const firstOutputTransaction = unassignedPath.outputFlowGroupTransactions[0];
  return (
    <tr>
      <td>
        {commodityNameLookup?.get(
          unassignedPath.commodityPath.inputCommodityId,
        ) ?? 'Loading...'}
      </td>
      <td>
        <RecoveryGoalPathName steps={unassignedPath.commodityPath.path.steps} />
      </td>
      <td>
        <Group position='apart'>
          <LinkText
            to={Router.FeedFlowGroupDetail({
              feedFlowGroupId: firstOutputTransaction.feedFlowGroupId.value,
            })}
          >
            {Temporal.Instant.from(firstOutputTransaction.effectiveTimestamp)
              .toZonedDateTimeISO(timeZoneId)
              .toLocaleString()}
          </LinkText>
          {unassignedPath.outputFlowGroupTransactions.length > 1 ? (
            <Button
              onClick={() => setShowAllFeedFlowGroups((s) => !s)}
              variant='outline'
              compact
              color='blue'
            >
              {showAllFeedFlowGroups
                ? 'Hide'
                : `Show remaining ${
                    unassignedPath.outputFlowGroupTransactions.length - 1
                  }`}
            </Button>
          ) : null}
          {showAllFeedFlowGroups ? (
            <Stack>
              {unassignedPath.outputFlowGroupTransactions
                .filter((_, i) => i > 0)
                .map((txn) => (
                  <LinkText
                    key={txn.feedFlowGroupId.value}
                    to={Router.FeedFlowGroupDetail({
                      feedFlowGroupId: txn.feedFlowGroupId.value,
                    })}
                  >
                    {Temporal.Instant.from(txn.effectiveTimestamp)
                      .toZonedDateTimeISO(timeZoneId)
                      .toLocaleString()}
                  </LinkText>
                ))}
            </Stack>
          ) : null}
        </Group>
      </td>
      <td>
        <Group noWrap>
          <CommoditySelect
            placeholder={intermediate ? 'intermediate' : 'Select output'}
            disabled={intermediate}
            value={outputCommodityId}
            onChange={setOutputCommodityId}
          />
          <Checkbox
            label='Intermediate'
            checked={intermediate}
            onChange={(e) => setIntermediate(e.currentTarget.checked)}
          />

          {outputCommodityId !== null || intermediate ? (
            <ActionIcon
              loading={addMutataion.isLoading}
              color='teal'
              onClick={() => {
                addMutataion.mutate({
                  id: uuidv4(),
                  inputCommodityId:
                    unassignedPath.commodityPath.inputCommodityId,
                  path: {
                    id: uuidv4(),
                    steps: unassignedPath.commodityPath.path.steps.map(
                      (step) => ({
                        id: uuidv4(),
                        recoveryGoalId: step.recoveryGoalId,
                        negative: step.negative,
                      }),
                    ),
                  },
                  outputCommodityId,
                  intermediate,
                });
              }}
            >
              <SaveIcon size='2rem' />
            </ActionIcon>
          ) : null}
        </Group>
      </td>
    </tr>
  );
}
