import { Flex, Input, Switch, TextInput } from '@mantine/core';
import { UseFormReturnType, useForm, zodResolver } from '@mantine/form';
import dayjs, { Dayjs } from 'dayjs';
import { z } from 'zod';
import { useFacilityContext } from '../Facility/FacilityContext';
import SwitchAsLabeledInputClasses from '../Input/SwitchAsLabeledInput.module.css';
import { InternalMaterialSourceSelect } from '../InternalMaterialSource/InternalMaterialSourceSelect';
import { SelectedFacilityRequiredDateTimePicker } from '../Time/FacilityDateTimePicker';
import QuantizedWeightTicksInput from '../Weights/QuantizedWeightTicksInput';
import { OptionalWeightSchema } from '../Weights/WeightZodSchemas';
import { InternalMaterialSourceId, WeightUnit } from '../rest-client';

const InternallySourcedMaterialFormSchema = z.object({
  name: z.string().min(1, { message: 'Name is required' }),
  internalMaterialSourceId: z
    .string()
    .uuid()
    .nullable()
    .refine((val) => val !== null, {
      message: 'Upstream source is required',
    }),
  creationTime: z.instanceof(dayjs as unknown as typeof Dayjs, {
    message: 'Time sourced is required',
  }),
  weight: OptionalWeightSchema,
});

export type InternallySourcedMaterialFormValues = z.infer<
  typeof InternallySourcedMaterialFormSchema
>;

export type InternallySourcedMaterialFormProps = {
  internalMaterialSourceId?: InternalMaterialSourceId;
};

export function useInternallySourcedMaterialForm(
  props: InternallySourcedMaterialFormProps,
) {
  const { internalMaterialSourceId } = props;

  const facility = useFacilityContext();
  return useForm<InternallySourcedMaterialFormValues>({
    initialValues: {
      // biome-ignore lint/style/noNonNullAssertion: <explanation>
      internalMaterialSourceId: internalMaterialSourceId ?? null!,
      name: '',
      creationTime: dayjs.utc().tz(facility.timeZoneId),
      weight: {
        enabled: false,
        unit: WeightUnit.POUND,
        tickSize: 1,
        ticks: null,
      },
    },
    validate: zodResolver(InternallySourcedMaterialFormSchema),
  });
}

export function InternallySourcedMaterialFormFields(props: {
  form: UseFormReturnType<InternallySourcedMaterialFormValues>;
}) {
  const { form } = props;

  const internalMaterialSourceSelectDisabled =
    form.values.internalMaterialSourceId !== null &&
    !form.isDirty('internalMaterialSourceId');

  return (
    <>
      <TextInput
        label='Sourced Material Name'
        description='Reference label for batch of material from the upstream source.'
        placeholder='Name'
        withAsterisk
        {...form.getInputProps('name')}
      />
      <InternalMaterialSourceSelect
        label='Upstream Material Source'
        description='Upstream material source the material was sourced from.'
        placeholder='select upstream source'
        disabled={internalMaterialSourceSelectDisabled}
        withAsterisk
        firstSelectedByDefault={false}
        {...form.getInputProps('internalMaterialSourceId')}
      />
      <SelectedFacilityRequiredDateTimePicker
        label='Time Sourced'
        description='When the material was considered to be sourced from the upstream material source.'
        maxDate={dayjs()}
        withAsterisk
        {...form.getInputProps('creationTime')}
      />

      <Flex gap='md'>
        <Input.Wrapper
          label='Record Sourced Material Weight'
          description='Is the net weight of the sourced material known?'
          maw='50%'
        >
          <Switch
            size='lg'
            maw='50%'
            className={SwitchAsLabeledInputClasses.switch}
            checked={form.values.weight.enabled}
            onChange={(e) => {
              form.setFieldValue('weight.enabled', e.currentTarget.checked);
              // reset weight on switch being disabled
              if (!e.currentTarget.checked) {
                form.setFieldValue('weight.ticks', null);
              }
            }}
          />
        </Input.Wrapper>

        <QuantizedWeightTicksInput
          label='Net Weight Sourced'
          description='The net weight of the sourced material.'
          placeholder='Weight'
          withAsterisk={form.values.weight.enabled}
          disabled={!form.values.weight.enabled}
          maw='20ch'
          unit={form.values.weight.unit ?? WeightUnit.POUND}
          tickSize={form.values.weight.tickSize ?? 1}
          {...form.getInputProps('weight.ticks')}
        />
      </Flex>
    </>
  );
}
