import { Paper, Stack, Text, Title } from '@mantine/core';
import { Fragment, useCallback, useMemo } from 'react';
import { renderToString } from 'react-dom/server';
import { EChart } from '../echarts/BareEChart';
import classes from './RimasReportCompositionLineChart.module.css';
import {
  RowOutputPoundsData,
  massFractionValueCalculator,
  useRimasTableStore,
} from './RimasTableState';

interface LineDef {
  id: string;
  name: string;
  key: {
    [k in keyof RowOutputPoundsData]-?: RowOutputPoundsData[k] extends
      | number
      | null
      ? k
      : never;
  }[keyof RowOutputPoundsData];
  hideDefault: boolean;
}
const LINES: LineDef[] = [
  {
    id: 'ubc',
    name: 'UBC',
    key: 'ubcPounds',
    hideDefault: false,
  },
  {
    id: 'byproducts',
    name: 'Byproducts',
    key: 'totalByproductPounds',
    hideDefault: false,
  },
  {
    id: 'reconciled',
    name: 'Yield Loss',
    key: 'reconciledPounds',
    hideDefault: false,
  },
  {
    id: 'al-fines',
    name: 'Al Fines',
    key: 'alFinesPounds',
    hideDefault: true,
  },
  {
    id: 'ferrous',
    name: 'Ferrous',
    key: 'ferrousPounds',
    hideDefault: true,
  },
  {
    id: 'mixed-clip',
    name: 'Mixed Clip',
    key: 'mixedClipPounds',
    hideDefault: true,
  },
  {
    id: 'trash',
    name: 'Trash',
    key: 'trashPounds',
    hideDefault: true,
  },
];

export function RimasReportCompositionLineChart() {
  const { filteredRows, includeReconciled } = useRimasTableStore();

  const tooltipFormatter = useCallback(
    (
      params: {
        axisValue: number;
        color: string;
        name: string;
        seriesName: string;
        value: [string, number];
      }[],
    ) => {
      const controlNumberGroups = Object.groupBy(params, (p) => p.name);
      const date = params[0].value[0];
      // TODO: Sort by control number instead of series
      return renderToString(
        <div>
          <Title order={5}>{date}</Title>
          <Stack spacing='xs'>
            {Object.entries(controlNumberGroups).map(
              ([controlNumber, params]) => (
                <Paper
                  p='xs'
                  key={controlNumber}
                  className={classes.rimasGraphTooltip}
                  withBorder
                >
                  {(params ?? []).map((p, i) => (
                    <Fragment key={p.seriesName + p.name}>
                      <Title order={6}>{i === 0 ? p.name : ''}</Title>
                      <Text c={p.color} align='right' fw='bold'>
                        {p.value[1].toFixed(1)}%
                      </Text>
                      <Text c={p.color} fw='bolder' align='left'>
                        {p.seriesName}
                      </Text>
                    </Fragment>
                  ))}
                </Paper>
              ),
            )}
          </Stack>
        </div>,
      );
    },
    [],
  );

  const option = useMemo(() => {
    const completeRows =
      filteredRows?.flatMap((row) =>
        row.status === 'complete' ? [row] : [],
      ) ?? [];

    return {
      tooltip: {
        trigger: 'axis',
        formatter: tooltipFormatter,
      },
      xAxis: {
        type: 'time',
      },
      yAxis: {
        type: 'value',
        name: 'Fraction of Recovered Total Mass',
        axisLabel: {
          formatter: '{value} %',
        },
      },
      legend: {
        selected: Object.fromEntries(
          LINES.map((l) => [l.name, !l.hideDefault]),
        ),
      },
      series: LINES.map((line) => ({
        type: 'line',
        id: line.id,
        name: line.name,
        data: completeRows.map((row) => ({
          name: row.controlNumber,
          value: [
            row.startDate,
            massFractionValueCalculator(
              line.key,
              // biome-ignore lint/style/noNonNullAssertion: <explanation>
              row.outputs!,
              includeReconciled || line.key === 'reconciledPounds',
            ).toPercent(),
          ],
        })),
      })),
    };
  }, [includeReconciled, filteredRows, tooltipFormatter]);

  return <EChart option={option} h={350} />;
}
