import { useMsal } from '@azure/msal-react';
import {
  Alert,
  Burger,
  Group,
  Header,
  MediaQuery,
  Title,
  useMantineTheme,
} from '@mantine/core';
import { useNavbarStore } from './AppNavbar';
import { GlobalFacilitySelector } from './GlobalFacilitySelector';

const valisMsftTenantId = '974f33eb-4ac1-4bea-ad31-fb462bbde74a';

export function AppHeader() {
  const theme = useMantineTheme();

  const navbarOpened = useNavbarStore((s) => s.opened);
  const toggleOpen = useNavbarStore((s) => s.toggleOpen);

  const { instance } = useMsal();

  const allMsalAccounts = instance.getAllAccounts();
  const isValisEmployee = allMsalAccounts.some(
    (a) => a.tenantId === valisMsftTenantId,
  );

  const isProd = window.location.hostname.includes('valisinsights.com');
  const isStage = window.location.hostname.includes('valisinsights.cloud');
  const showEnvWarningBanner = isValisEmployee && (isProd || isStage);

  return (
    <Header height={{ base: 50, md: 60 }} p='md' withBorder>
      <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
        <MediaQuery largerThan='sm' styles={{ display: 'none' }}>
          <Burger
            opened={navbarOpened}
            onClick={toggleOpen}
            size='sm'
            color={theme.colors.gray[6]}
            mr='xl'
          />
        </MediaQuery>

        <Group position='apart' w='100%'>
          <div>
            <Title color='teal'>VALIS</Title>
          </div>
          {showEnvWarningBanner ? (
            <Alert variant='filled' color={isProd ? 'red' : 'orange'}>
              <Title order={2}>{isProd ? 'PRODUCTION' : 'STAGING'}</Title>
            </Alert>
          ) : null}
          <div>
            <GlobalFacilitySelector />
          </div>
        </Group>
      </div>
    </Header>
  );
}
