import { Temporal } from '@js-temporal/polyfill';
import {
  QueryKey,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import produce from 'immer';
import { match } from 'ts-pattern';
import {
  SamplingSuiteCaptureDTO,
  SamplingSuiteCapturePatchDTO,
  SamplingSuiteService,
  SamplingSuiteSessionDTO,
} from '../rest-client';
import { QueryFunctionContexts, QueryKeyTypes } from './queryKeyTypeUtils';
import { queryKeys, useQueryKeyInvalidator } from './queryKeys';
import { client } from './restClient';

async function fetchSamplingSuites() {
  const { data } = await client.GET('/sampling-suites');
  if (!data) throw new Error();
  return data;
}

export function useSamplingSuites() {
  return useQuery({
    queryKey: queryKeys.samplingSuite.list(),
    queryFn: fetchSamplingSuites,
  });
}

async function fetchSamplingSuiteCaptures(
  ctx: QueryFunctionContexts['samplingSuiteCapture']['list'],
) {
  const [{ unlinked, suiteId, archived }] = ctx.queryKey;
  return await SamplingSuiteService.getSamplingSuiteCaptures(
    suiteId ?? undefined,
    unlinked ?? undefined,
    archived ?? undefined,
  );
}

export function useSamplingSuiteCaptures(args: {
  unlinked: boolean | null;
  suiteId: string | null;
  archived: boolean | null;
}) {
  return useQuery({
    queryKey: queryKeys.samplingSuiteCapture.list(args),
    queryFn: fetchSamplingSuiteCaptures,
  });
}

async function fetchSamplingSuiteCaptureSegmentation(
  ctx: QueryFunctionContexts['samplingSuiteCapture']['segmentation'],
) {
  const [{ captureId }] = ctx.queryKey;
  return await SamplingSuiteService.getCaptureSegmentation(captureId);
}

export function useSamplingSuiteCaptureSegmentation(captureId: string) {
  return useQuery({
    queryKey: queryKeys.samplingSuiteCapture.segmentation(captureId),
    queryFn: fetchSamplingSuiteCaptureSegmentation,
    // TODO(2298): Figure out how to refetch on interval while pending but hold in cache forever if completed
    refetchIntervalInBackground: false,
  });
}

async function patchSamplingSuiteCapture(args: {
  capture: SamplingSuiteCaptureDTO;
  patch: SamplingSuiteCapturePatchDTO;
}) {
  await client.PATCH('/sampling-suites/captures/{captureGuid}', {
    params: {
      path: {
        captureGuid: args.capture.captureId,
      },
    },
    body: args.patch,
  });
}

export function usePatchSamplingSuiteCapture() {
  const invalidator = useQueryKeyInvalidator();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: patchSamplingSuiteCapture,
    onMutate: async ({ capture, patch }) => {
      if (patch.sampleAnalysisLink === undefined) return;

      // cancel any outgoing queries to any session so they don't race with our optimistic update
      await queryClient.cancelQueries({
        queryKey: queryKeys.samplingSession.details(),
      });
      await queryClient.cancelQueries({
        queryKey: queryKeys.samplingSuiteCapture.lists(),
      });

      // find if the capture is currently used anywhere and remove it
      const sessionsQueryData: [
        QueryKey,
        SamplingSuiteSessionDTO | undefined,
      ][] = queryClient.getQueriesData({
        queryKey: queryKeys.samplingSession.details(),
      });

      // update sessions
      for (const [sessionQueryKey] of sessionsQueryData) {
        queryClient.setQueryData(
          sessionQueryKey,
          (session: SamplingSuiteSessionDTO | undefined) => {
            if (session === undefined) return undefined;

            return produce(session, (draft) => {
              // remove the capture from anywhere it is currently
              const linkedCaptureIdx = draft.linkedCaptures.findIndex(
                (c) => c.captureId === capture.captureId,
              );
              if (linkedCaptureIdx !== -1) {
                1;
                draft.linkedCaptures.splice(linkedCaptureIdx, 1);
              }

              for (const materialClassState of draft.materialClassStates) {
                const assignedCaptureIdx =
                  materialClassState.assignedCaptures.findIndex(
                    (c) => c.captureId === capture.captureId,
                  );
                if (assignedCaptureIdx !== -1) {
                  materialClassState.assignedCaptures.splice(
                    assignedCaptureIdx,
                    1,
                  );
                  // TODO: Update the weight
                }
              }

              if (
                patch.sampleAnalysisLink?.samplingSuiteSampleAnalysisId ===
                draft.samplingSuiteSampleAnalysisId
              ) {
                // the capture is being moved to the analysis that this session is operating on
                const assignment =
                  patch.sampleAnalysisLink.materialClassAssignment;
                if (assignment === null) {
                  // the capture is being moved to linked, unassigned state
                  draft.linkedCaptures.push(capture);

                  // linked captures are sorted by capture instant
                  draft.linkedCaptures.sort(
                    (a, b) =>
                      Temporal.Instant.from(a.captureInstant)
                        .epochMilliseconds -
                      Temporal.Instant.from(b.captureInstant).epochMilliseconds,
                  );
                } else {
                  const { materialClassId } = assignment;
                  if (
                    draft.autoProceed &&
                    assignment.materialClassId === draft.activeMaterialClassId
                  ) {
                    const stateSequence = draft.materialClassStates.map(
                      (s) => s.materialClassId,
                    );
                    const activeStateIndex = stateSequence.indexOf(
                      draft.activeMaterialClassId,
                    );
                    const nextIdx = Math.min(
                      activeStateIndex + 1,
                      stateSequence.length - 1,
                    );
                    draft.activeMaterialClassId = stateSequence[nextIdx];
                  }

                  const materialClassState = draft.materialClassStates.find(
                    (s) => s.materialClassId === materialClassId,
                  );

                  materialClassState?.assignedCaptures.push(capture);
                  // assigned captures are sorted by capture instant
                  materialClassState?.assignedCaptures.sort(
                    (a, b) =>
                      Temporal.Instant.from(a.captureInstant)
                        .epochMilliseconds -
                      Temporal.Instant.from(b.captureInstant).epochMilliseconds,
                  );
                }
              }
            });
          },
        );
      }

      const capturesQueryData = queryClient.getQueriesData<
        SamplingSuiteCaptureDTO[]
      >({
        queryKey: queryKeys.samplingSuiteCapture.lists(),
      });

      for (const [capturesQueryKey, captures] of capturesQueryData) {
        const [{ unlinked, suiteId, archived }] =
          capturesQueryKey as QueryKeyTypes['samplingSuiteCapture']['list'];

        if (captures === undefined) continue;

        const idx = captures.findIndex(
          (c) => c.captureId === capture.captureId,
        );
        const isMember = idx !== -1;

        const becomingArchived = patch.archived === true;

        const shouldBeMember =
          (suiteId === null || capture.suiteId === suiteId) &&
          (unlinked === null ||
            unlinked === (patch.sampleAnalysisLink === null)) &&
          match(archived)
            .with(null, false, () => !becomingArchived)
            .with(true, () => becomingArchived)
            .exhaustive();

        if (isMember === shouldBeMember) continue;

        queryClient.setQueryData<SamplingSuiteCaptureDTO[]>(
          capturesQueryKey,
          (old) => {
            if (old === undefined) return undefined;
            return produce(old, (draft) => {
              if (shouldBeMember) {
                draft.push(capture);
                draft.sort(
                  (a, b) =>
                    Temporal.Instant.from(a.captureInstant).epochMilliseconds -
                    Temporal.Instant.from(b.captureInstant).epochMilliseconds,
                );
              } else {
                draft.splice(idx, 1);
              }
            });
          },
        );

        return { sessionsQueryData, capturesQueryData };
      }
    },
    onError(_error, _variables, context) {
      if (context === undefined) return;
      const { sessionsQueryData, capturesQueryData } = context;
      for (const [q, d] of [...sessionsQueryData, ...capturesQueryData]) {
        queryClient.setQueryData(q, d);
      }
    },
    onSettled() {
      invalidator.invalidateKeys(
        queryKeys.samplingSuiteCapture.all,
        queryKeys.sampleAnalysis.all,
        queryKeys.samplingSession.all,
      );
      invalidator.invalidateMaterialState();
    },
  });
}
