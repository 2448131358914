import { ActionIcon, ActionIconProps, Flex, InputProps } from '@mantine/core';
import { DateInput, DateTimePicker, TimeInput } from '@mantine/dates';
import { IconStopwatch } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { match } from 'ts-pattern';

type TemporalPickerVariant = 'date' | 'time' | 'datetime';

export function DatetimePickerWithNowActionIcon(
  props: {
    variant: TemporalPickerVariant;
  } & InputProps & {
      value: Date | null;
      onChange: (value: Date | null) => void;
      valueFormat: string;
      placeholder?: string;
      label?: string;
      withAsterisk?: boolean;
    },
) {
  const {
    variant,
    value,
    onChange,
    valueFormat,
    placeholder = `select ${variant}`,
    label,
    withAsterisk,
    miw = `${valueFormat.length}ch`,
    ...dateInputProps
  } = props;

  return (
    <Flex align='flex-end' gap='.3rem'>
      {match(variant)
        .with('datetime', () => (
          <DateTimePicker
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            label={label}
            valueFormat={valueFormat}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
            withAsterisk={withAsterisk}
            miw={miw}
            {...dateInputProps}
          />
        ))
        .with('date', () => (
          <DateInput
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            label={label}
            valueFormat={valueFormat}
            withAsterisk={withAsterisk}
            miw={miw}
            {...dateInputProps}
          />
        ))
        .with('time', () => (
          <TimeInput
            value={value !== null ? value.toTimeString() : undefined}
            onChange={(e) => onChange(new Date(e.target.value))}
            label={label}
            placeholder={placeholder}
            withAsterisk={withAsterisk}
            miw={miw}
            {...dateInputProps}
          />
        ))
        .exhaustive()}
      <NowActionIcon onClick={onChange} />
    </Flex>
  );
}

function NowActionIcon(
  props: Omit<ActionIconProps, 'onClick'> & { onClick: (date: Date) => void },
) {
  const {
    onClick,
    variant = 'filled',
    size = 'lg',
    color = 'teal',
    ...actionIconProps
  } = props;
  // const { timeZoneId } = useFacilityContext();

  // TODO: add .tz(timeZoneId) once we have Schupan facility timezone
  return (
    <ActionIcon
      onClick={() => onClick(dayjs().toDate())}
      variant={variant}
      size={size}
      color={color}
      {...actionIconProps}
    >
      <IconStopwatch />
    </ActionIcon>
  );
}
