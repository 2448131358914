import { Text, TextProps } from '@mantine/core';
import { P, match } from 'ts-pattern';
import { NetWeightDTO, WeightUnit } from '../rest-client';
import { WithUnit } from '../util/WithUnit';

type Verbosity = 'verbose' | 'normal' | 'succinct';
type TimeBasis = 'day' | 'hour' | 'minute';

export type NetWeightThroughputWithUnitsProps = TextProps & {
  netWeightPerHour: NetWeightDTO;
  decimals?: number;
  verbosity?: Verbosity;
  timeBasis?: TimeBasis;
};

export function NetWeightThroughputWithUnits(
  props: NetWeightThroughputWithUnitsProps,
) {
  const { netWeightPerHour, ...otherProps } = props;

  if (!(WeightUnit.POUND in netWeightPerHour.unitExpectedTotals)) {
    return <Text color='dimmed'>Unknown</Text>;
  }

  const lbsPerHour = Number(
    netWeightPerHour.unitExpectedTotals[WeightUnit.POUND],
  );

  return <PoundsThroughputWithUnits lbsPerHour={lbsPerHour} {...otherProps} />;
}

export type PoundsThroughputWithUnitsProps = Omit<
  NetWeightThroughputWithUnitsProps,
  'netWeightPerHour'
> & {
  lbsPerHour: number;
};

export function PoundsThroughputWithUnits(
  props: PoundsThroughputWithUnitsProps,
) {
  const {
    lbsPerHour,
    decimals = 0,
    verbosity = 'succinct',
    timeBasis = 'hour',
  } = props;

  interface UnitMatch {
    verbosity: Verbosity;
    timeBasis: TimeBasis;
  }

  const unitMatch: UnitMatch = {
    verbosity: verbosity,
    timeBasis: timeBasis,
  };

  const unit = `lb/${match(unitMatch)
    .with({ verbosity: 'verbose', timeBasis: 'day' }, () => 'day')
    .with({ verbosity: 'verbose', timeBasis: 'hour' }, () => 'hour')
    .with({ verbosity: 'verbose', timeBasis: 'minute' }, () => 'minute')
    .with(
      { verbosity: P.union('normal', 'succinct'), timeBasis: 'day' },
      () => 'd',
    )
    .with({ verbosity: 'normal', timeBasis: 'hour' }, () => 'hr')
    .with({ verbosity: 'normal', timeBasis: 'minute' }, () => 'min')
    .with({ verbosity: 'succinct', timeBasis: 'hour' }, () => 'h')
    .with({ verbosity: 'succinct', timeBasis: 'minute' }, () => 'm')
    .exhaustive()}`;

  const throughput = match(timeBasis)
    .with('day', () => Number(lbsPerHour) * 24)
    .with('hour', () => Number(lbsPerHour))
    .with('minute', () => Number(lbsPerHour) / 60)
    .exhaustive();

  return (
    <WithUnit unitSuffix={unit}>
      {new Intl.NumberFormat().format(Number(throughput.toFixed(decimals)))}
    </WithUnit>
  );
}
