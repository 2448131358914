import {
  Center,
  Flex,
  Grid,
  Group,
  Loader,
  SegmentedControl,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import dayjs from 'dayjs';
import { useCallback, useState } from 'react';
import { match } from 'ts-pattern';
import { AppPage } from '../App/AppPage';
import { CommodityName } from '../Commodity/CommodityName';
import { CommodityIcon, RecoveryGoalIcon } from '../Icons';
import { MaterialClassSetName } from '../MaterialClassSet/MaterialClassSetName';
import { BinaryConfusionMatrixStats } from '../RecoveryGoal/BinaryConfusionMatrixStats';
import { RecoveryGoalName } from '../RecoveryGoal/RecoveryGoalName';
import { RecoveryStrategyName } from '../RecoveryStrategy/RecoveryStategyName';
import { CommoditySpreadMaterialNodeBody } from '../RecoveryTree/CommoditySpreadRecoveryTreeNodes';
import { useRecoveryStrategySimulation } from '../api/recoveryStrategySimulation';
import { LabeledValue } from '../common';
import { EChart } from '../echarts/BareEChart';
import { useCategoricalColors } from '../lib/colors';
import {
  CommoditySpreadMaterialNodeDTO,
  CommoditySpreadRecoveryGoalNodeDTO,
} from '../rest-client';
import { Router } from '../router';
import { formatPercentage } from '../util/percentages';
import { DeleteRecoveryStrategySimulationButton } from './DeleteRecoveryStrategySimulationButton';
import {
  RecoveryStrategySimulationCtxProvider,
  useRecoveryStrategySimulationCtx,
} from './RecoveryStrategySimulationContext';
import { RecoveryStrategySimulationDiagram } from './RecoveryStrategySimulationDiagram';
import { RecoveryStrategySimulationTree } from './RecoveryStrategySimulationTree';
import { SimulationParameterTabs } from './SimulationParameters/SimulationParametersTabs';

export default function RecoveryStrategySimulationDetailPage(props: {
  simulationId: string;
}) {
  const { simulationId } = props;

  const { data: simulation } = useRecoveryStrategySimulation(simulationId);

  const [selectedMaterialClassId, setSelectedMaterialClassId] = useState<
    string | null
  >(null);
  const [selectedRecoveryGoalNode, setSelectedRecoveryGoalNode] =
    useState<CommoditySpreadRecoveryGoalNodeDTO | null>(null);
  const [selectedMaterialNode, setSelectedMaterialNode] =
    useState<CommoditySpreadMaterialNodeDTO | null>(null);
  const [feedTotal, setFeedTotal] = useState<number | null>(null);
  const [visualizationType, setVisualizationType] = useState<string>('flow');

  if (!simulation) {
    return (
      <Center>
        <Loader variant='bars' size='xl' />
      </Center>
    );
  }

  return (
    <AppPage
      breadcrumbs={[
        {
          title: 'Recovery Strategies',
          routeName: Router.RecoveryStrategyList(),
        },
        {
          title: simulation.recoveryStrategy.name,
          routeName: Router.RecoveryStrategyDetail({
            recoveryStrategyId: simulation.recoveryStrategy.id,
          }),
        },
        simulation.name,
      ]}
      titleRight={
        <DeleteRecoveryStrategySimulationButton simulationId={simulationId} />
      }
    >
      <RecoveryStrategySimulationCtxProvider
        simulation={simulation}
        selectedMaterialClassId={selectedMaterialClassId}
        setSelectedMaterialClassId={setSelectedMaterialClassId}
        selectedRecoveryGoalNode={selectedRecoveryGoalNode}
        setSelectedRecoveryGoaNode={setSelectedRecoveryGoalNode}
        selectedMaterialNode={selectedMaterialNode}
        setSelectedMaterialNode={setSelectedMaterialNode}
        feedTotal={feedTotal}
        setFeedTotal={setFeedTotal}
      >
        <RecoveryStrategySimulationDetailsSection />
        <AppPage.Section>
          <Stack>
            <Title order={3}>Simulation Parameters</Title>
            <SimulationParameterTabs />
          </Stack>
        </AppPage.Section>
        <>
          <SegmentedControl
            value={visualizationType}
            onChange={setVisualizationType}
            size='xl'
            data={[
              {
                label: 'Material Class Mass Flow by Recovery Step',
                value: 'flow',
              },
              { label: 'Commodity Spread Aanlysis', value: 'spread' },
            ]}
          />

          {match(visualizationType)
            .with('flow', () => (
              <>
                <AppPage.Section>
                  <RecoveryStrategySimulationDiagram />
                </AppPage.Section>
                <Grid>
                  <Grid.Col span={4}>
                    <SelectedRecoveryGoalMetrics />
                  </Grid.Col>
                  <Grid.Col span={8}>
                    <SelectedOutputComposition />
                  </Grid.Col>
                </Grid>
              </>
            ))
            .with('spread', () => (
              <AppPage.Section>
                <RecoveryStrategySimulationTree />
              </AppPage.Section>
            ))
            .otherwise(() => undefined)}
        </>
      </RecoveryStrategySimulationCtxProvider>
    </AppPage>
  );
}

function RecoveryStrategySimulationDetailsSection() {
  const { simulation } = useRecoveryStrategySimulationCtx();
  // const [editing, setEditing] = useState<boolean>(false);

  return (
    <AppPage.Section>
      <Stack>
        <Stack spacing='xs'>
          <Title order={3}>Simulation Details</Title>
          <Group>
            <LabeledValue label='Name'>{simulation.name}</LabeledValue>
            <LabeledValue label='Created at'>
              {dayjs.utc(simulation.insertedAt).format('LLL')}
            </LabeledValue>
            <LabeledValue label='Recovery Strategy'>
              <RecoveryStrategyName
                recoveryStrategy={simulation.recoveryStrategy}
              />
            </LabeledValue>
            <LabeledValue label='Sample Analysis Material Class Set'>
              <MaterialClassSetName
                materialClassSet={simulation.materialClassSet}
              />
            </LabeledValue>
          </Group>
        </Stack>
      </Stack>
    </AppPage.Section>
  );
}

function SelectedOutputComposition() {
  const { simulation, selectedMaterialNode, feedTotal } =
    useRecoveryStrategySimulationCtx();

  const colors = useCategoricalColors();

  const valueFormatter = useCallback(
    (val: number | null) => (val === null ? '?' : val.toFixed(2)),
    [],
  );

  if (simulation.rootMaterialNode === null) {
    return null;
  }

  if (selectedMaterialNode === null) {
    return null;
  }

  return (
    <AppPage.Section>
      <Flex direction='column' gap='md'>
        <Flex gap='sm' align='center'>
          <Title order={3}>Output Material Details</Title>
          <CommodityIcon />
          <Title order={3}>
            {selectedMaterialNode.commodity ? (
              <CommodityName commodity={selectedMaterialNode.commodity} />
            ) : (
              <Text c='dimmed'>Unknown Commodity</Text>
            )}
          </Title>
        </Flex>
        <Flex>
          <Stack>{CommoditySpreadMaterialNodeBody(selectedMaterialNode)}</Stack>

          <EChart
            h={500}
            w='100%'
            option={{
              grid: { containLabel: true, top: 0, bottom: 0 },
              tooltip: {
                valueFormatter: feedTotal ? valueFormatter : formatPercentage,
              },
              yAxis: {
                type: 'category',
                data: simulation.materialClassSet.materialClasses
                  .map((mc) => mc.name)
                  .reverse(),
              },
              xAxis: {
                type: 'value',
                axisLabel: {
                  formatter: feedTotal ? undefined : formatPercentage,
                },
              },
              series: [
                {
                  type: 'bar',
                  data: simulation.materialClassSet.materialClasses
                    .map((mc, i) => ({
                      value: feedTotal
                        ? feedTotal *
                          selectedMaterialNode.materialClassComposition[mc.id]
                        : selectedMaterialNode.materialClassComposition[mc.id],
                      itemStyle: {
                        color: colors[i],
                      },
                    }))
                    .reverse(),
                },
              ],
            }}
          />
        </Flex>
      </Flex>
    </AppPage.Section>
  );
}

function SelectedRecoveryGoalMetrics() {
  const { simulation, selectedRecoveryGoalNode } =
    useRecoveryStrategySimulationCtx();

  if (simulation.rootMaterialNode === null) {
    return null;
  }

  if (selectedRecoveryGoalNode === null) {
    return null;
  }

  const positiveCommodityName =
    selectedRecoveryGoalNode.positiveMaterialNode.commodity?.name;
  const negativeCommodityName =
    selectedRecoveryGoalNode.negativeMaterialNode.commodity?.name;

  const targetMaterialClasses =
    selectedRecoveryGoalNode.recoveryGoal.materialClasses;
  const targetMaterialClassIds = targetMaterialClasses.map((mc) => mc.id);
  const dropMaterialClasses =
    simulation.materialClassSet.materialClasses.filter(
      (mc) => !targetMaterialClassIds.includes(mc.id),
    );

  return (
    <AppPage.Section h='100%'>
      <Flex direction='column' gap='lg'>
        <Flex gap='sm' align='center'>
          <Title order={3}>Recovery Step Performance</Title>
          <RecoveryGoalIcon />
          <Title order={3}>
            <RecoveryGoalName
              recoveryGoal={selectedRecoveryGoalNode.recoveryGoal}
            />
          </Title>
        </Flex>

        <Flex direction='column' gap='sm'>
          <BinaryConfusionMatrixStats
            matrix={selectedRecoveryGoalNode.binaryConfusionMatrix}
            withCardWrapper={false}
            prevalenceLabel={`Input ${positiveCommodityName ?? 'Target Commodity'} Proportion`}
            positivePredictiveValueLabel={`${negativeCommodityName ?? 'Positive'} Proportion`}
            falseDiscoveryRateLabel={`${positiveCommodityName ?? 'Target Commodity'} Proportion`}
            truePositiveRateLabel={`${positiveCommodityName ?? 'Fraction'} Recovery Rate`}
            falseNegativeRateLabel='Loss'
            trueNegativeRateLabel={`Correctly Dropped ${negativeCommodityName ?? 'Fraction'}`}
            trueNegativeRateInverseLabel='Incorrectly Recovered'
          />
        </Flex>
        <LabeledValue label='Target Material Classes'>
          <Flex gap='md'>
            {targetMaterialClasses.map((mc) => (
              <Text key={mc.id}>{mc.name}</Text>
            ))}
          </Flex>
        </LabeledValue>
        <LabeledValue label='Drop Material Classes'>
          <Flex gap='md' wrap='wrap' rowGap={0}>
            {dropMaterialClasses.map((mc) => (
              <Text key={mc.id}>{mc.name}</Text>
            ))}
          </Flex>
        </LabeledValue>
      </Flex>
    </AppPage.Section>
  );
}
