import { Alert, List } from '@mantine/core';
import {
  MaterialTransactionConflictErrorDTO,
  TransactionClaimConcurrencyErrorDTO,
} from '../../rest-client';

export function TransactionClaimConcurrencyErrorExplanation({
  error,
}: {
  error: TransactionClaimConcurrencyErrorDTO;
}) {
  return (
    <Alert color='red' title='Transaction-Claim Concurrency Conflict'>
      This transaction cannot be applied because it affects a container that was
      measured at exactly the same time. Either move this transaction to a
      different time or adjust the time of the measurement.
      {/* TODO(1809): Link to measurement */}
    </Alert>
  );
}

export function TransactionConflictExplanation(props: {
  conflict: MaterialTransactionConflictErrorDTO;
}) {
  // TODO(1809): Link to conflicting transaction
  const { conflict } = props;
  return (
    <Alert color='red' title='Transaction Concurrency Conflict'>
      This transaction cannot be applied because it conflicts with another
      transaction that occurs at the same time. Possible resolutions:
      <List>
        <List.Item>
          Move one of the transactions before or after the other transaction.
        </List.Item>
        <List.Item>
          Change the conflicting containers if they are incorrect.
        </List.Item>
      </List>
    </Alert>
  );
}
