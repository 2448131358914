import {
  IconAdjustmentsAlt,
  IconArrowAutofitContent,
  IconArrowsSplit2,
  IconBook2,
  IconBoxOff,
  IconBuildingStore,
  IconCertificate,
  IconChartBar,
  IconChartLine,
  IconClipboardList,
  IconClockDown,
  IconClockHour4,
  IconClockPlay,
  IconDeviceFloppy,
  IconDroplet,
  IconDropletX,
  IconEdit,
  IconGitBranch,
  IconHomeCog,
  IconHomeMove,
  IconHomePlus,
  IconMicroscope,
  IconPackage,
  IconPackageExport,
  IconPackageImport,
  IconPlus,
  IconProps,
  IconReceipt,
  IconReceipt2,
  IconReplace,
  IconReplaceOff,
  IconReportMoney,
  IconRowInsertBottom,
  IconRowRemove,
  IconScale,
  IconSquare,
  IconSquareHalf,
  IconSquareX,
  IconStatusChange,
  IconTargetArrow,
  IconTexture,
  IconTournament,
  IconTransitionBottom,
  IconTrash,
  IconTriangleSquareCircle,
  IconTruckDelivery,
  IconX,
} from '@tabler/icons-react';
import React from 'react';

export function SortSystemIcon(props: IconProps) {
  return <IconArrowAutofitContent {...props} />;
}

export function CommodityIcon(props: IconProps) {
  return <IconCertificate {...props} />;
}

export function AnalysisIcon(props: IconProps) {
  return <IconChartBar {...props} />;
}

export function ScaleIcon(props: IconProps) {
  return <IconScale {...props} />;
}

export function RecoveryGoalIcon(props: IconProps) {
  return <IconTargetArrow {...props} />;
}

export function InsightsIcon(props: IconProps) {
  return <IconChartLine {...props} />;
}

export function MaterialClassIcon(props: IconProps) {
  return <IconTriangleSquareCircle {...props} />;
}

export function RecoveryStrategyIcon(props: IconProps) {
  return <IconTournament {...props} />;
}

// Sampling icons
export function SamplingIcon(props: IconProps) {
  return <IconMicroscope {...props} />;
}

export function DrySampleIcon(props: IconProps) {
  return <IconDropletX {...props} />;
}

export function WetSampleIcon(props: IconProps) {
  return <IconDroplet {...props} />;
}

// CRUD icons
export function AddIcon(props: IconProps) {
  return <IconPlus {...props} />;
}

export function EditIcon(props: IconProps) {
  return <IconEdit {...props} />;
}

export function DeleteIcon(props: IconProps) {
  return <IconTrash {...props} />;
}

export function SaveIcon(props: IconProps) {
  return <IconDeviceFloppy {...props} />;
}

export function CancelIcon(props: IconProps) {
  return <IconX {...props} />;
}

export function RunIcon(props: IconProps) {
  return <IconClockPlay {...props} />;
}

// Transaction Icons

export function TransactionLedgerIcon(props: IconProps) {
  return <IconBook2 {...props} />;
}

export function FeedFlowGroupIcon(props: IconProps) {
  return <IconArrowAutofitContent {...props} />;
}

export function OutputFlowGroupIcon(props: IconProps) {
  return <IconTransitionBottom {...props} />;
}

export function FullTransferIcon(props: IconProps) {
  return <IconStatusChange {...props} />;
}

export function PartialTransferIcon(props: IconProps) {
  return <IconGitBranch {...props} />;
}

export function IncomingTransferIcon(props: IconProps) {
  return <IconStatusChange {...props} style={{ transform: 'scaleX(-1)' }} />;
}

export function OutgoingTransferIcon(props: IconProps) {
  return <IconStatusChange {...props} />;
}

export function PartitionIcon(props: IconProps) {
  return <IconArrowsSplit2 {...props} />;
}

export function ProcessBufferTransferIcon(props: IconProps) {
  return <IconTransitionBottom {...props} />;
}

// Process Icons

export function ProcessIcon(props: IconProps) {
  return <IconArrowsSplit2 {...props} />;
}

export function OutputContainerChangeIcon(props: IconProps) {
  return <IconReplace {...props} />;
}

export function OutputContainerRemovedIcon(props: IconProps) {
  return <IconReplaceOff {...props} />;
}

export function ProcessParametersIcon(props: IconProps) {
  return <IconAdjustmentsAlt {...props} />;
}

// Production Icons
export function ProductionIcon(props: IconProps) {
  return <IconClipboardList {...props} />;
}

export function ProductionIntervalIcon(props: IconProps) {
  return <IconClockHour4 {...props} />;
}

export function ProcessStopIcon(props: IconProps) {
  return <IconClockDown {...props} />;
}

export function ProcessBufferRestorationIcon(props: IconProps) {
  return <IconRowInsertBottom {...props} />;
}

export function ProcessBufferDepletionIcon(props: IconProps) {
  return <IconRowRemove {...props} />;
}

// Inventory Icons

export const TareWeightIcon = IconSquare;
export const NetWeightIcon = IconTexture;
export const GrossWeightIcon = React.forwardRef<SVGSVGElement, IconProps>(
  function GrossWeightIcon(props, ref) {
    const { color = 'currentColor', size = 24, stroke = 2, ...rest } = props;

    return (
      <svg
        ref={ref}
        className='tabler-icon'
        width={size}
        height={size}
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 24 24'
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'
        stroke={color}
        strokeWidth={stroke}
        {...rest}
      >
        <path stroke='none' d='M0 0h24v24H0z' fill='none' />
        <path d='M3 3m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z' />
        <path stroke='none' d='M0 0h24v24H0z' fill='none' />
        <path d='M6 3l-3 3' />
        <path d='M21 18l-3 3' />
        <path d='M11 3l-8 8' />
        <path d='M16 3l-13 13' />
        <path d='M20.5 3.5l-17 17' />
        <path d='M21 8l-13 13' />
        <path d='M21 13l-8 8' />
      </svg>
    );
  },
);

export function TruckLoadIcon(props: IconProps) {
  return <IconTruckDelivery {...props} />;
}

export function BoxIcon(props: IconProps) {
  return <IconPackage {...props} />;
}

export function ContainerIcon(props: IconProps) {
  return <IconPackage {...props} />;
}

export function NoContainerIcon(props: IconProps) {
  return <IconBoxOff {...props} />;
}

export function EmptyContainerIcon(props: IconProps) {
  return <IconSquareX {...props} />;
}

export function PartiallyFullContainerIcon(props: IconProps) {
  return <IconSquareHalf {...props} style={{ transform: 'rotate(90deg)' }} />;
}

export const FullContainerIcon = GrossWeightIcon;

export function InternalMaterialSourceIcon(props: IconProps) {
  return <IconHomeCog {...props} />;
}

export function InternallySourcedMaterialIcon(props: IconProps) {
  return <IconHomePlus {...props} />;
}

export function InternallySourcedMaterialPartitionTransferIcon(
  props: IconProps,
) {
  return <IconPackageImport {...props} />;
}

export function InternalMaterialSourcePartitionTransferIcon(props: IconProps) {
  return <IconPackageImport {...props} />;
}

export function InternalMaterialSinkIcon(props: IconProps) {
  return <IconHomeMove {...props} />;
}

export function InternalMaterialSinkTransferIcon(props: IconProps) {
  return <IconPackageExport {...props} />;
}

// Purchasing and Sales Icons

export function VendorIcon(props: IconProps) {
  return <IconBuildingStore {...props} />;
}

export function CustomerIcon(props: IconProps) {
  return <IconReportMoney {...props} />;
}

export function PurchaseIcon(props: IconProps) {
  return <IconReceipt {...props} />;
}

export function SaleIcon(props: IconProps) {
  return <IconReceipt2 {...props} />;
}
