import {
  Accordion,
  Alert,
  Checkbox,
  Flex,
  Grid,
  Group,
  Input,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';
import { useForm, UseFormReturnType, zodResolver } from '@mantine/form';
import dayjs, { Dayjs } from 'dayjs';
import { match, P } from 'ts-pattern';
import { z } from 'zod';
import { useTenantContext } from '../App/TenantContext';
import { CommoditySelect } from '../Commodity/CommoditySelect';
import { useFacilityContext } from '../Facility/FacilityContext';
import { InternalMaterialSinkSelect } from '../InternalMaterialSink/InternalMaterialSinkSelect';
import { InternalMaterialSourceSelect } from '../InternalMaterialSource/InternalMaterialSourceSelect';
import { ProcessOutputPortSelect } from '../Process/ProcessOutputPortSelect';
import { ProcessSelect } from '../Process/ProcessSelect';
import { RecoveryStrategySelect } from '../RecoveryStrategy/RecoveryStrategySelect';
import { DefaultCommodityAssignmentSelect } from '../RecoveryTree/DefaultCommodityAssignmentRecoveryTreeNodes';
import { DayjsDateTimePicker } from '../Time/DayjsDateTimePicker';
import { useDetailedProcess } from '../api/process';
import {
  CommodityId,
  InternalMaterialSinkId,
  InternalMaterialSourceId,
  ProcessId,
  RecoveryStrategyId,
} from '../rest-client';

const NotionalSampleFormSchema = z
  .object({
    timestamp: z
      .instanceof(dayjs as unknown as typeof Dayjs, {
        message: 'Sample time is required.',
      })
      .nullable(),
    name: z.string(),
    materialProcessed: z.boolean(),
    commodityId: z.string().uuid().nullable(),
    intermediateProduct: z.boolean(),
    internalMaterialSourceId: z.string().uuid().nullable(),
    mixedInternalMaterialSources: z.boolean(),
    processId: z.string().uuid().nullable(),
    outputPortId: z.string().uuid().nullable(),
    recoveryStrategyId: z.string().uuid().nullable(),
    recoveryStrategyPathId: z.string().uuid().nullable(),
    recoveryStrategyPathStepOrder: z.number().int().nonnegative().nullable(),
    recoveryStrategyPathStepNegative: z.boolean(),
    internalMaterialSinkId: z.string().uuid().nullable(),
  })
  // CommodityId validation
  .refine(
    ({ timestamp }) => {
      if (timestamp === null) return false;
      return true;
    },
    {
      message: 'Sample time is required',
      path: ['timestamp'],
    },
  )
  .refine(
    ({ materialProcessed, mixedInternalMaterialSources, commodityId }) => {
      if (
        !materialProcessed &&
        mixedInternalMaterialSources &&
        commodityId === null
      ) {
        return false;
      }
      return true;
    },
    {
      message:
        'Commodity of the sampled material is required since it cannot be derived from the upstream source.',
      path: ['commodityId'],
    },
  )
  .refine(
    ({ materialProcessed, commodityId, intermediateProduct }) => {
      if (materialProcessed && !intermediateProduct && commodityId === null) {
        return false;
      }
      return true;
    },
    {
      message:
        'Commodity of the sampled material is required if the material was processed, unless it is an intermediate product.',
      path: ['commodityId'],
    },
  )
  // InternalMaterialSourceId validation
  .refine(
    ({
      materialProcessed,
      internalMaterialSourceId,
      mixedInternalMaterialSources,
    }) => {
      if (
        !materialProcessed &&
        !mixedInternalMaterialSources &&
        internalMaterialSourceId === null
      ) {
        return false;
      }
      return true;
    },
    {
      message:
        'Upstream source being sampled is required unless the material was derived from mixed sources.',
      path: ['internalMaterialSourceId'],
    },
  )
  // OutputPortId validation
  .refine(
    ({ processId, outputPortId }) =>
      outputPortId === null || (outputPortId !== null && processId !== null),
    {
      message: 'Process must be provided for the process output port.',
      path: ['outputPortId'],
    },
  )
  // RecoveryStrategyPathId validation
  .refine(
    ({
      recoveryStrategyId,
      recoveryStrategyPathId,
      recoveryStrategyPathStepOrder,
    }) =>
      recoveryStrategyPathId === null ||
      (recoveryStrategyId !== null &&
        recoveryStrategyPathId !== null &&
        recoveryStrategyPathStepOrder !== null),
    {
      message:
        'Recovery strategy must be provided for the recovery strategy path.',
      path: ['recoveryStrategyPathId'],
    },
  );

export type NotionalSampleFormValues = z.infer<typeof NotionalSampleFormSchema>;

export type NotionalSampleFormProps = {
  timestamp?: Dayjs;
  commodityId?: CommodityId;
  processId?: ProcessId;
  recoveryStrategyId?: RecoveryStrategyId;
  internalMaterialSourceId?: InternalMaterialSourceId;
  internalMaterialSinkId?: InternalMaterialSinkId;
};

export function useNotionalSampleForm(props: NotionalSampleFormProps) {
  const {
    timestamp,
    commodityId,
    internalMaterialSourceId,
    processId,
    recoveryStrategyId,
    internalMaterialSinkId,
  } = props;

  return useForm<NotionalSampleFormValues>({
    initialValues: {
      timestamp: timestamp?.utc() ?? null,
      name: '',
      materialProcessed: true,
      commodityId: commodityId ?? null,
      intermediateProduct: false,
      internalMaterialSourceId: internalMaterialSourceId ?? null,
      mixedInternalMaterialSources: false,
      processId: processId ?? null,
      outputPortId: null,
      recoveryStrategyId: recoveryStrategyId ?? null,
      recoveryStrategyPathId: null,
      recoveryStrategyPathStepOrder: null,
      recoveryStrategyPathStepNegative: true,
      internalMaterialSinkId: internalMaterialSinkId ?? null,
    },
    validate: zodResolver(NotionalSampleFormSchema),
  });
}

export type NotionalSampleFormFieldsProps = {
  form: UseFormReturnType<NotionalSampleFormValues>;
};

export function NotionalSampleFormFields(props: NotionalSampleFormFieldsProps) {
  const { form } = props;

  const tenant = useTenantContext();
  const isRadius = tenant.tenantId === 1;

  const { timeZoneId } = useFacilityContext();
  const { data: process, isLoading: processIsLoading } = useDetailedProcess(
    form.values.processId ?? undefined,
  );

  const materialUnprocessedPlaceholder = 'sampled material is unprocessed';

  const commoditySelectDisabled =
    form.values.intermediateProduct ||
    (!form.values.materialProcessed &&
      !form.values.mixedInternalMaterialSources);
  const commoditySelectRequired = form.values.materialProcessed;
  const intermediateCommodityDisabled = !form.values.materialProcessed;

  const internalMaterialSourceSelectDisabled =
    form.values.mixedInternalMaterialSources;
  const internalMaterialSourceSelectRequired =
    !form.values.materialProcessed && !form.values.mixedInternalMaterialSources;

  const processSelectDisabled = !form.values.materialProcessed;
  const outputPortSelectDisabled =
    !form.values.materialProcessed ||
    process === undefined ||
    form.values.processId === null;

  const recoveryStrategySelectDisabled = !form.values.materialProcessed;

  const SampleTimePicker = (
    <DayjsDateTimePicker
      label='Sample Time'
      description='Time at which the material was removed to be sampled.'
      tz={timeZoneId}
      withAsterisk
      placeholder='Enter Sample Time'
      {...form.getInputProps('timestamp')}
    />
  );

  const UpstreamSourceSelect = (
    <InternalMaterialSourceSelect
      label='Upstream Source'
      description='The upstream material source this material was derived from.'
      placeholder={
        form.values.mixedInternalMaterialSources
          ? 'mixed upstream sources'
          : 'select upstream source'
      }
      disabled={internalMaterialSourceSelectDisabled}
      withAsterisk={internalMaterialSourceSelectRequired}
      clearable
      w='50%'
      firstSelectedByDefault
      {...form.getInputProps('internalMaterialSourceId')}
    />
  );

  const SourcesMixedCheckbox = (
    <Flex gap='md' maw='50%'>
      <Input.Wrapper
        label='Sources Mixed'
        description='Check this box if the sampled material was derived from multiple upstream sources.'
      >
        <Checkbox
          size='xl'
          mt='.3rem'
          checked={form.values.mixedInternalMaterialSources}
          onChange={(e) => {
            form.setFieldValue(
              'mixedInternalMaterialSources',
              e.target.checked,
            );
            form.setFieldValue('internalMaterialSourceId', null);
          }}
          error={form.errors.mixedInternalMaterialSources}
        />
      </Input.Wrapper>
    </Flex>
  );

  const EmployedRecoveryStrategySelect = (
    <RecoveryStrategySelect
      label='Employed Recovery Strategy'
      description='If the sampled material was processed, the recovery strategy employed to product the material.'
      placeholder={
        form.values.materialProcessed
          ? 'select recovery strategy'
          : materialUnprocessedPlaceholder
      }
      disabled={recoveryStrategySelectDisabled}
      clearable
      w='100%'
      value={form.values.recoveryStrategyId}
      onChange={(newRecoveryStrategyId) => {
        form.setFieldValue('recoveryStrategyId', newRecoveryStrategyId);
        form.setFieldValue('recoveryStrategyPathId', null);
        form.setFieldValue('recoveryStrategyPathStepOrder', null);
        form.setFieldValue('recoveryStrategyPathStepNegative', true);
      }}
      error={form.errors.recoveryStrategyId}
    />
  );

  const RecoveryOutputProductSelect = (
    <Input.Wrapper
      label='Recovery Output Product Select'
      description='Select the upstream source and recovery strategy. Select the output node from the recovery tree to infer the sampled commodity.'
    >
      {form.values.recoveryStrategyId !== null &&
      form.values.internalMaterialSourceId !== null ? (
        <DefaultCommodityAssignmentSelect
          recoveryStrategyId={form.values.recoveryStrategyId}
          internalMaterialSourceId={form.values.internalMaterialSourceId}
          error={form.errors.recoveryStrategyPathId}
          setSelectedMaterialNode={(node) => {
            match(node)
              // output or intermediate product with known commodity inference
              .with(
                {
                  recoveryStrategyPathId: P.string,
                  recoveryStrategyPathDepth: P.number,
                  isNegative: P.boolean, // can't be the root node
                  commodity: P.nonNullable, // known commodity
                  // consuming recovery goal can be null or defined
                },
                ({
                  recoveryStrategyPathId,
                  recoveryStrategyPathDepth: recoveryStrategyPathStepOrder,
                  isNegative: recoveryStrategyPathStepNegative,
                  commodity,
                }) => {
                  form.setValues({
                    recoveryStrategyPathId,
                    recoveryStrategyPathStepOrder,
                    recoveryStrategyPathStepNegative,
                    commodityId: commodity.id,
                    intermediateProduct: false,
                    materialProcessed: true,
                  });
                },
              )
              // output product with unknown commodity inference
              .with(
                {
                  recoveryStrategyPathId: P.string,
                  recoveryStrategyPathDepth: P.number,
                  isNegative: P.boolean, // can't be the root node
                  commodity: null, // unknown commodity inference
                  consumingRecoveryGoalNode: null, // leaf node
                },
                ({
                  recoveryStrategyPathId,
                  recoveryStrategyPathDepth,
                  isNegative,
                }) => {
                  form.setFieldValue(
                    'recoveryStrategyPathId',
                    recoveryStrategyPathId,
                  );
                  form.setFieldValue(
                    'recoveryStrategyPathStepOrder',
                    recoveryStrategyPathDepth,
                  );
                  form.setFieldValue(
                    'recoveryStrategyPathStepNegative',
                    isNegative,
                  );
                  form.setFieldValue('commodityId', null);
                  form.setFieldValue('intermediateProduct', false);
                  form.setFieldValue('materialProcessed', true);
                },
              )
              // intermediate product with unknown commodity inference
              .with(
                {
                  recoveryStrategyPathId: P.string,
                  recoveryStrategyPathDepth: P.number,
                  isNegative: P.boolean, // can't be the root node
                  commodity: null, // unknown commodity inference
                  consumingRecoveryGoalNode: P.nonNullable, // not a leaf node
                },
                ({
                  recoveryStrategyPathId,
                  recoveryStrategyPathDepth,
                  isNegative,
                }) => {
                  form.setFieldValue(
                    'recoveryStrategyPathId',
                    recoveryStrategyPathId,
                  );
                  form.setFieldValue(
                    'recoveryStrategyPathStepOrder',
                    recoveryStrategyPathDepth,
                  );
                  form.setFieldValue(
                    'recoveryStrategyPathStepNegative',
                    isNegative,
                  );
                  form.setFieldValue('commodityId', null);
                  form.setFieldValue('intermediateProduct', true);
                  form.setFieldValue('materialProcessed', true);
                },
              )
              // input material with known commodity from internal material source
              .with(
                P.intersection(P.nonNullable, {
                  recoveryStrategyPathId: null,
                  recoveryStrategyPathDepth: null,
                  isNegative: null, // root node
                  commodity: P.nonNullable, // known commodity inference
                  index: 0, // root node
                }),
                ({ commodity }) => {
                  form.setFieldValue('recoveryStrategyPathId', null);
                  form.setFieldValue('recoveryStrategyPathStepOrder', null);
                  form.setFieldValue('commodityId', commodity.id); // TODO: should we set this or do we rely on internal material source
                  form.setFieldValue('intermediateProduct', false);
                  form.setFieldValue('materialProcessed', false);
                },
              )
              .otherwise(() => null);
          }}
        />
      ) : (
        <Alert title='Recovery Output Product Select' color='blue'>
          <Text>
            Once you select the upstream source and the recovery strategy for
            the sampled material, the material recovery tree for that recovery
            methodology will be shown.
          </Text>
          <Text>
            By selecting the recovery output from the tree, we can infer the
            commodity that was sampled and the relationship between this sample
            and other samples in your recovery process.
          </Text>
        </Alert>
      )}
    </Input.Wrapper>
  );

  const MaterialProcessedCheckbox = (
    <Flex gap='md' maw='50%'>
      <Input.Wrapper
        label='Material Processed'
        description='Check this box if the sampled material has been processed.'
      >
        <Checkbox
          size='xl'
          mt='.3rem'
          checked={form.values.materialProcessed}
          onChange={(e) => {
            form.setFieldValue('materialProcessed', e.target.checked);
            if (!e.target.checked) {
              form.setFieldValue('commodityId', null);
              form.setFieldValue('intermediateProduct', false);
              form.setFieldValue('processId', null);
              form.setFieldValue('outputPortId', null);
              form.setFieldValue('recoveryStrategyId', null);
              form.setFieldValue('recoveryStrategyPathId', null);
              form.setFieldValue('recoveryStrategyPathStepOrder', null);
              form.setFieldValue('recoveryStrategyPathStepNegative', true);
            }
            form.clearErrors();
          }}
          error={form.errors.materialProcessed}
        />
      </Input.Wrapper>
    </Flex>
  );

  const SampledCommoditySelect = (
    <CommoditySelect
      label='Commodity'
      description='The commodity of the sampled material, unless it was taken from an intermediate product.'
      placeholder={
        form.values.materialProcessed
          ? form.values.intermediateProduct
            ? 'intermediate'
            : 'select commodity'
          : form.values.mixedInternalMaterialSources
            ? 'select commodity'
            : 'inferred from upstream source'
      }
      withAsterisk={commoditySelectRequired}
      clearable
      searchable
      disabled={commoditySelectDisabled}
      {...form.getInputProps('commodityId')}
    />
  );

  const IntermediateCommodityCheckbox = (
    <Flex gap='md' maw='50%'>
      <Input.Wrapper
        label='Intermediate Commodity'
        description='Check this box if the sampled material is from an intermediate product.'
      >
        <Checkbox
          size='xl'
          mt='.3rem'
          disabled={intermediateCommodityDisabled}
          checked={form.values.intermediateProduct}
          onChange={(e) => {
            form.setFieldValue('intermediateProduct', e.target.checked);
            form.setFieldValue('commodityId', null);
          }}
          error={form.errors.intermediateProduct}
        />
      </Input.Wrapper>
    </Flex>
  );

  const ProductingProcessSelect = (
    <ProcessSelect
      label='ProducingProcess'
      description='If the sampled material was processed, the process it came from.'
      placeholder={
        form.values.materialProcessed
          ? 'select process'
          : materialUnprocessedPlaceholder
      }
      disabled={processSelectDisabled}
      clearable
      w='50%'
      value={form.values.processId}
      onChange={(newProcessId) => {
        form.setFieldValue('processId', newProcessId);
        if (newProcessId === null) {
          form.setFieldValue('outputPortId', null);
        }
      }}
      error={form.errors.processId}
    />
  );

  const ProducingProcessOutputPortSelect = (
    <ProcessOutputPortSelect
      label='Output Port'
      description='If the sampled material was processed, the output port it came from.'
      placeholder={
        form.values.materialProcessed
          ? outputPortSelectDisabled
            ? 'select process'
            : 'select output port'
          : materialUnprocessedPlaceholder
      }
      processOutputPorts={process?.outputs}
      isLoading={processIsLoading}
      disabled={outputPortSelectDisabled}
      clearable
      maw='50%'
      {...form.getInputProps('outputPortId')}
    />
  );

  const ExportDestinationSelect = (
    <InternalMaterialSinkSelect
      label='Export Destination'
      description='If the sampled material is being exported, the destination of the export.'
      placeholder='select export destination'
      clearable
      {...form.getInputProps('internalMaterialSinkId')}
    />
  );

  const SampleIDInput = (
    <TextInput
      label='Sample ID'
      description='Optionally explicitly name this sample. The default value is an auto-incrementing unique number.'
      placeholder='override default id'
      {...form.getInputProps('name')}
    />
  );

  if (isRadius) {
    return (
      <Grid>
        <Grid.Col span={6}>
          <Flex direction='column' gap='md' align='start' w='20rem'>
            {SampleTimePicker}
            {SampledCommoditySelect}
          </Flex>
        </Grid.Col>
        <Grid.Col span={6}>
          <Accordion>
            <Accordion.Item value='optional-fields'>
              <Accordion.Control>
                <Text c='dimmed'>Optional Sampling Fields</Text>
              </Accordion.Control>
              <Accordion.Panel>
                <Stack>
                  <Group noWrap align='top'>
                    {UpstreamSourceSelect}
                    {SourcesMixedCheckbox}
                  </Group>
                  {SampleIDInput}
                </Stack>
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
        </Grid.Col>
      </Grid>
    );
  }

  return (
    <Flex align='start' gap='xl'>
      <Stack>
        {SampleTimePicker}
        <Group noWrap align='top'>
          {UpstreamSourceSelect}
          {/* TODO: factor out input-styled checkbox into reusable component */}
          {SourcesMixedCheckbox}
        </Group>
        {EmployedRecoveryStrategySelect}
        {RecoveryOutputProductSelect}
        {/* TODO(3095): implement check display for if the default commodity path assignment matches the commodity field */}
      </Stack>
      <Stack>
        {MaterialProcessedCheckbox}
        <Group noWrap align='top'>
          <div style={{ width: '50%' }}>{SampledCommoditySelect}</div>

          {IntermediateCommodityCheckbox}
        </Group>
        <Group noWrap align='top'>
          {ProductingProcessSelect}
          {ProducingProcessOutputPortSelect}
        </Group>
        {ExportDestinationSelect}

        {SampleIDInput}
      </Stack>
    </Flex>
  );
}
