import { Alert, Table, Text } from '@mantine/core';
import { useRecoveryGoals } from '../api/recoveryGoal';
import { RecoveryGoalName } from './RecoveryGoalName';

export function RecoveryGoalTable() {
  const recoveryGoalsQuery = useRecoveryGoals();

  return (
    <Table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Short name</th>
          <th>Description</th>
        </tr>
      </thead>
      <tbody>
        {recoveryGoalsQuery.data && recoveryGoalsQuery.data.length === 0 ? (
          <tr>
            <td colSpan={3}>
              <Alert color='blue'>
                No recovery goals have been defined yet
              </Alert>
            </td>
          </tr>
        ) : undefined}
        {recoveryGoalsQuery.data?.map((g) => (
          <tr key={g.id}>
            <td>
              <RecoveryGoalName recoveryGoal={g} />
            </td>
            <td>{g.shortName ?? <Text color='dimmed'>none</Text>}</td>
            <td>{g.description ?? <Text color='dimmed'>none</Text>}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
