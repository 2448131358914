import { Alert, List } from '@mantine/core';
import {
  AdditionFullDestinationErrorDTO,
  ExtractionEmptySourceErrorDTO,
} from '../../rest-client';
import { InventoryLedgerErrorExplanationRow } from '../InventoryLedgerErrorExplanationRow';
import {
  EmptyingTransactionStatus,
  FillingTransactionStatus,
} from '../LedgerStatusContext';

export function EmptySourceErrorExplanation({
  error,
}: {
  error: ExtractionEmptySourceErrorDTO;
}) {
  return (
    <Alert color='red' title='Empty Source'>
      This transaction cannot be applied because it is attempting to move
      material from an empty container. The container was empty at the transfer
      time because{' '}
      {error.emptyingExtraction ? (
        // TODO(1809): Implement jump to transaction, or check if it's on this page?
        <>a previous transaction emptied it.</>
      ) : (
        <>it has never had material transferred into it.</>
      )}
    </Alert>
  );
}

export function FullDestinationErrorExplanation(props: {
  error: AdditionFullDestinationErrorDTO;
}) {
  // TODO(1809): Link to filling transaction
  const { error } = props;

  return (
    <Alert color='red' title='Full Destination'>
      This transaction cannot be applied because it is attempting to move
      material into a full container.
    </Alert>
  );
}

export function EmptyingStatusExplanationRow(props: {
  status: EmptyingTransactionStatus;
}) {
  const {
    // TODO(1809): Link to emptying transaction
    status: { error },
  } = props;

  return (
    <InventoryLedgerErrorExplanationRow>
      {/* TODO(1809): Link to time of other transaction */}
      <Alert color='orange' title='Previous Emptying Transaction'>
        A later transaction cannot be applied because this transaction emptied
        the source container. There are several possible causes for this issue:
        <List>
          <List.Item>
            This transaction is mistakenly using the wrong source.
          </List.Item>
          <List.Item>
            The later transaction is mistakenly using the wrong source.
          </List.Item>
          <List.Item>
            This transaction was mistakenly entered as emptying its source.
          </List.Item>
          <List.Item>
            A transaction that added material to the source container between
            this transaction and the later transaction was mistakenly omitted
            from the ledger, or entered with the wrong time.
          </List.Item>
          <List.Item>
            The later transaction was mistakenly entered with the wrong time.
          </List.Item>
          <List.Item>
            This transaction was mistakenly entered with the wrong time.
          </List.Item>
        </List>
      </Alert>
    </InventoryLedgerErrorExplanationRow>
  );
}

export function FillingStatusExplanationRow(props: {
  status: FillingTransactionStatus;
}) {
  const {
    // TODO(1809): Use error details to render something more informative
    status: { error },
  } = props;
  return (
    <InventoryLedgerErrorExplanationRow>
      <Alert color='orange' title='Previous Filling Transaction'>
        The later transaction cannot be applied because this transaction filled
        the destination container. There are several possible causes for this
        issue:
        <List>
          <List.Item>
            This transaction is mistakenly using the wrong destination.
          </List.Item>
          <List.Item>
            The later transaction is mistakenly using the wrong destination.
          </List.Item>
          <List.Item>
            This transaction was mistakenly entered as filling its destination.
          </List.Item>
          <List.Item>
            A transaction that removed material from the destination container
            between this transaction and the later transaction was mistakenly
            omitted from the ledger, or entered with the wrong time.
          </List.Item>
          <List.Item>
            This transaction was mistakently entered with the wrong time.
          </List.Item>
          <List.Item>
            The later transaction was mistakenly entered with the wrong time.
          </List.Item>
        </List>
      </Alert>
    </InventoryLedgerErrorExplanationRow>
  );
}
