import dayjs from 'dayjs';
import { match } from 'ts-pattern';
import { AddNotionalSamplePage } from '../NotionalSample/AddNotionalSamplePage';
import { The404 } from '../The404';
import { Router } from '../router';
import { CreateContainerSamplePage } from './ContainerSample/CreateContainerSamplePage';
import {
  SampleInsightExportDetailPage,
  SampleInsightExportPage,
} from './InsightExport';
import { SampleDetailPage } from './SampleDetailPage';
import { SamplingPage } from './SamplingPage';

export function SampleArea() {
  const route = Router.useRoute([
    'SampleList',
    'SampleDetail',
    'SampleCreateContainer',
    'SampleCreateNotional',
    'SampleInsightExportList',
    'SampleInsightExportDetail',
  ]);

  if (route === undefined) return <The404 />;

  return match(route)
    .with({ name: 'SampleList' }, () => <SamplingPage />)
    .with({ name: 'SampleDetail' }, ({ params: { sampleId } }) => (
      <SampleDetailPage sampleId={sampleId} />
    ))
    .with(
      { name: 'SampleCreateContainer' },
      ({ params: { containerId, timestamp } }) => (
        <CreateContainerSamplePage
          containerId={containerId}
          sampleTakenTimestamp={
            timestamp !== undefined ? dayjs.utc(timestamp) : undefined
          }
        />
      ),
    )
    .with(
      { name: 'SampleCreateNotional' },
      ({
        params: {
          timestamp,
          commodityId,
          internalMaterialSourceId,
          processId,
          recoveryStrategyId,
          internalMaterialSinkId,
        },
      }) => (
        <AddNotionalSamplePage
          timestamp={timestamp !== undefined ? dayjs.utc(timestamp) : undefined}
          commodityId={commodityId}
          internalMaterialSourceId={internalMaterialSourceId}
          processId={processId}
          recoveryStrategyId={recoveryStrategyId}
          internalMaterialSinkId={internalMaterialSinkId}
        />
      ),
    )
    .with({ name: 'SampleInsightExportDetail' }, ({ params: { exportId } }) => (
      <SampleInsightExportDetailPage exportId={exportId} />
    ))
    .with({ name: 'SampleInsightExportList' }, () => (
      <SampleInsightExportPage />
    ))
    .exhaustive();
}
