import { Button, Group, Skeleton, Stack, Text, Title } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { P, match } from 'ts-pattern';
import { AppPage, TitleRoute } from '../App/AppPage';
import { DeleteIcon, EditIcon } from '../Icons';
import { CommoditySampleTable } from '../Sample/SampleTable/CommoditySampleTable';
import { useCommodity, useDeleteCommodity } from '../api/commodity';
import { LabeledValue } from '../common';
import { CommodityDTO } from '../rest-client';
import { Router } from '../router';
import { Price } from '../util/Money';
import { CommodityInternalMaterialSourceCompositions } from './CommodityInternalMaterialSourceCompositions';
import AddCommoditySpotPriceButton from './CommoditySpotPrice/AddCommoditySpotPriceButton';
import { CommoditySpotPricesTable } from './CommoditySpotPrice/CommoditySpotPricesTable';
import { EditCommodityDrawerForm } from './EditCommodityDrawerForm';

export function CommodityDetailPage({ commodityId }: { commodityId: string }) {
  const deleteMutation = useDeleteCommodity();

  const { data: commodity, isLoadingError, error } = useCommodity(commodityId);
  const [editDrawerOpened, { open: openEditDrawer, close: closeEditDrawer }] =
    useDisclosure(false);

  if (isLoadingError) {
    throw error;
  }
  const breadcrumbs: TitleRoute[] = [
    {
      routeName: Router.CommodityList(),
      title: 'Commodities',
    },
  ];

  return (
    <AppPage breadcrumbs={breadcrumbs} title={commodity?.name ?? null}>
      <AppPage.Section>
        <Stack>
          <Group>
            <Title order={2}>{commodity?.name ?? 'Loading...'} Details</Title>
            <Group ml='auto'>
              <>
                <Button
                  onClick={openEditDrawer}
                  leftIcon={<EditIcon />}
                  variant='outline'
                >
                  Edit
                </Button>
                <EditCommodityDrawerForm
                  opened={editDrawerOpened}
                  onClose={closeEditDrawer}
                  commodityId={commodityId}
                />
              </>
              <Button
                variant='outline'
                leftIcon={<DeleteIcon />}
                color='red'
                disabled={commodity === undefined}
                loading={deleteMutation.isLoading}
                onClick={() => {
                  deleteMutation.mutate(commodityId, {
                    onError() {
                      showNotification({
                        title: 'Error Deleting Commodity',
                        message: 'An error occured deleting the commodity.',
                        color: 'red',
                        icon: <IconX />,
                      });
                    },
                    onSuccess() {
                      Router.push('CommodityList');
                      showNotification({
                        title: 'Commodity Deleted',
                        message: 'Commodity was successfully deleted.',
                        color: 'green',
                        icon: <IconCheck />,
                      });
                    },
                  });
                }}
              >
                Delete
              </Button>
            </Group>
          </Group>
          <Group>
            <CommodityDetails commodity={commodity} />
          </Group>
          {/* TODO(2301): Link to commodity paths (input and output) */}
        </Stack>
      </AppPage.Section>
      <AppPage.Section>
        <Stack spacing='xs'>
          <Title order={2}>{commodity?.name ?? 'Loading'} Samples</Title>
          <CommoditySampleTable commodityId={commodityId} />
        </Stack>
      </AppPage.Section>
      <AppPage.Section>
        <Stack>
          <Title order={2}>Upstream Material Sources</Title>
          <CommodityInternalMaterialSourceCompositions
            commodityId={commodityId}
          />
        </Stack>
      </AppPage.Section>
      <AppPage.Section>
        <Stack>
          <Group>
            <Title order={2}>{commodity?.name ?? 'Loading'} Spot Prices</Title>
            <AddCommoditySpotPriceButton
              addCommoditySpotPriceDrawerFormProps={{ commodityId }}
            />
          </Group>
          <CommoditySpotPricesTable commodityId={commodityId} />
        </Stack>
      </AppPage.Section>
    </AppPage>
  );
}

function CommodityDetails(props: { commodity: CommodityDTO | undefined }) {
  const { commodity } = props;
  return (
    <>
      <LabeledValue label='Commodity Name'>
        {commodity?.name ?? <Skeleton>Loading...</Skeleton>}
      </LabeledValue>
      <LabeledValue label='Description'>
        {match(commodity?.description)
          .with(undefined, () => <Skeleton>Loading...</Skeleton>)
          .with(P.union(null, ''), () => <Text color='dimmed'>none</Text>)
          .otherwise((d) => d)}
      </LabeledValue>
      <LabeledValue label='Current Price'>
        {match(commodity?.currentCommoditySpotPrice)
          .with(undefined, () => <Skeleton>Loading...</Skeleton>)
          .with(null, () => <Text c='dimmed'>none</Text>)
          .otherwise(({ usdPerUnitOfWeight, weightUnit }) => (
            <Price
              usdPerWeightUnit={usdPerUnitOfWeight}
              weightUnit={weightUnit}
            />
          ))}
      </LabeledValue>
      <LabeledValue label='Date Priced'>
        {match(commodity?.currentCommoditySpotPrice)
          .with(undefined, () => <Skeleton>Loading...</Skeleton>)
          .with(null, () => <Text c='dimmed'>none</Text>)
          .otherwise(({ pricedAt }) => (
            <Text>{dayjs(pricedAt).format('L')}</Text>
          ))}
      </LabeledValue>
    </>
  );
}
