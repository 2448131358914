import { useQuery } from '@tanstack/react-query';
import { CustomTrayService } from '../rest-client';
import { QueryFunctionContexts } from './queryKeyTypeUtils';
import { queryKeys } from './queryKeys';

async function fetchCustomTrays(
  ctx: QueryFunctionContexts['customTray']['list'],
) {
  return await CustomTrayService.getCustomTrays();
}

export function useCustomTrays() {
  return useQuery({
    queryKey: queryKeys.customTray.list(),
    queryFn: fetchCustomTrays,
  });
}
