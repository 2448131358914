import {
  ActionIcon,
  Alert,
  Button,
  Flex,
  Group,
  Loader,
  NumberInput,
  Stack,
  Table,
  Text,
} from '@mantine/core';
import { IconLink, IconRefresh } from '@tabler/icons-react';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  useCreateMaterialClassSetConversion,
  useDeleteMaterialClassSetConversion,
  useMaterialClassSetConversionGraph,
  usePatchMaterialClassSetConversion,
} from '../api/materialClassSetConversion';
import { AppPage } from '../App/AppPage';
import { LabeledValue } from '../common';
import { useDeleteEntityModal } from '../Form/DeleteEntity';
import { AddIcon, DeleteIcon, EditIcon, SaveIcon } from '../Icons';
import { LinkText } from '../Link';
import { MaterialClassSetConversionDTO, PathDTO } from '../rest-client';
import { Router } from '../router';
import { PCTFORMAT } from '../util/percentages';

export function MaterialClassSetConversionPage(props: {
  sourceMaterialClassSetId: string;
  targetMaterialClassSetId: string;
}) {
  const { sourceMaterialClassSetId, targetMaterialClassSetId } = props;

  return (
    <AppPage
      title='Material Class Set Conversion'
      breadcrumbs={[
        { routeName: Router.MaterialClassList(), title: 'Material Classes' },
      ]}
    >
      <AppPage.Section>
        <MaterialClassSetConversionDetail
          sourceMaterialClassSetId={sourceMaterialClassSetId}
          targetMaterialClassSetId={targetMaterialClassSetId}
        />
      </AppPage.Section>
    </AppPage>
  );
}

function MaterialClassSetConversionDetail(props: {
  sourceMaterialClassSetId: string;
  targetMaterialClassSetId: string;
}) {
  const { sourceMaterialClassSetId, targetMaterialClassSetId } = props;
  const { data: graph } = useMaterialClassSetConversionGraph();

  const createMutation = useCreateMaterialClassSetConversion();

  const createConversion = () => {
    createMutation.mutate(
      {
        id: uuidv4(),
        sourceMaterialClassSetId,
        targetMaterialClassSetId,
      },
      {
        onSuccess() {},
        onSettled() {
          createMutation.reset();
        },
      },
    );
  };

  if (!graph) {
    return <Loader />;
  }

  const invalidConversion = graph.invalidConversions.find(
    (invalidConversion) =>
      invalidConversion.sourceMaterialClassSet.id ===
        sourceMaterialClassSetId &&
      invalidConversion.targetMaterialClassSet.id === targetMaterialClassSetId,
  );
  if (invalidConversion) {
    return (
      <SpecifiedMaterialClassSetConversionDetail
        conversion={invalidConversion}
      />
    );
  }

  const conversionLookup = Object.fromEntries(
    [...graph.invalidConversions, ...graph.validConversions].map((conv) => [
      conv.id,
      conv,
    ]),
  );

  const u = graph.materialClassSets.findIndex(
    (mcs) => mcs.id === sourceMaterialClassSetId,
  );
  const sourceMaterialClassSet = graph.materialClassSets[u];
  const v = graph.materialClassSets.findIndex(
    (mcs) => mcs.id === targetMaterialClassSetId,
  );
  const targetMaterialClassSet = graph.materialClassSets[v];

  if (u < 0 || v < 0) {
    return (
      <Alert title='Material Class Set Not Found' color='red' variant='filled'>
        One of the material class sets does not exist.
      </Alert>
    );
  }

  const path = graph.pathMatrix[u][v];
  if (!path) {
    return (
      <Alert color='blue' title='Create conversion?'>
        <Stack>
          <Text>
            There is currently no conversion from{' '}
            <Text span fw='bold'>
              {sourceMaterialClassSet.name}
            </Text>{' '}
            to{' '}
            <Text span fw='bold'>
              {targetMaterialClassSet.name}
            </Text>
            . Would you like to create one?
          </Text>
          <Button
            loading={createMutation.isLoading}
            onClick={createConversion}
            leftIcon={<AddIcon />}
          >
            Create Conversion
          </Button>
        </Stack>
      </Alert>
    );
  }

  if (path.conversions.length === 1) {
    const specifiedConversionId = path.conversions[0];
    return (
      <SpecifiedMaterialClassSetConversionDetail
        conversion={conversionLookup[specifiedConversionId]}
      />
    );
  }

  return (
    <DerivedMaterialCLassSetConversionDetail
      path={path}
      conversionLookup={conversionLookup}
    />
  );
}

function DerivedMaterialCLassSetConversionDetail(props: {
  path: PathDTO;
  conversionLookup: Record<string, MaterialClassSetConversionDTO>;
}) {
  const { path, conversionLookup } = props;

  const conversions = path.conversions.map((cId) => conversionLookup[cId]);
  const firstConversion = conversions[0];
  const lastConversion = conversions[conversions.length - 1];

  const { sourceMaterialClassSet } = firstConversion;
  const { targetMaterialClassSet } = lastConversion;

  const creationMutation = useCreateMaterialClassSetConversion();

  return (
    <Stack>
      <Group>
        <LabeledValue label='Source Material Class Set'>
          {firstConversion.sourceMaterialClassSet.name}
        </LabeledValue>
        <LabeledValue label='Target Material Class Set'>
          {lastConversion.targetMaterialClassSet.name}
        </LabeledValue>
      </Group>
      <LabeledValue label='Conversion Sequence'>
        <Table highlightOnHover withBorder w='fit-content'>
          <thead>
            <tr>
              <th>Source</th>
              <th>Target</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {conversions.map((conversion, i) => (
              <tr key={i}>
                <td>{conversion.sourceMaterialClassSet.name}</td>
                <td>{conversion.targetMaterialClassSet.name}</td>
                <td>
                  <LinkText
                    to={Router.MaterialClassSetConversionDetail({
                      sourceMaterialClassSetId:
                        conversion.sourceMaterialClassSet.id,
                      targetMaterialClassSetId:
                        conversion.targetMaterialClassSet.id,
                    })}
                  >
                    <IconLink />
                  </LinkText>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </LabeledValue>
      <LabeledValue label='Derived Conversion Matrix'>
        <Table withBorder style={{ tableLayout: 'fixed', textAlign: 'right' }}>
          <thead>
            <tr>
              <th>Target/Source</th>
              {sourceMaterialClassSet.materialClasses.map((mc) => (
                <th key={mc.id} style={{ textAlign: 'right' }}>
                  {mc.shortName ?? mc.name}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {targetMaterialClassSet.materialClasses.map((targetMc, i) => (
              <tr key={targetMc.id}>
                <th>{targetMc.shortName ?? targetMc.name}</th>
                {sourceMaterialClassSet.materialClasses.map((sourceMc, j) => (
                  <td key={sourceMc.id}>
                    {PCTFORMAT.format(path.coefficients[i][j])}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </LabeledValue>
      <Button
        variant='outline'
        loading={creationMutation.isLoading}
        onClick={() => {
          creationMutation.mutate(
            {
              id: uuidv4(),
              sourceMaterialClassSetId: sourceMaterialClassSet.id,
              targetMaterialClassSetId: targetMaterialClassSet.id,
            },
            {
              onSettled() {
                creationMutation.reset();
              },
            },
          );
        }}
      >
        Override Matrix
      </Button>
    </Stack>
  );
}

function SpecifiedMaterialClassSetConversionDetail(props: {
  conversion: MaterialClassSetConversionDTO;
}) {
  const { conversion } = props;

  const deleteMutation = useDeleteMaterialClassSetConversion();
  const openDeleteModal = useDeleteEntityModal({
    mutationVariables: conversion.id,
    deleteMutation,
    entityName: 'Material Class Set Conversion',
  });

  return (
    <Stack>
      <Group>
        <LabeledValue label='Source Material Class Set'>
          <LinkText
            to={Router.MaterialClassSetDetail({
              materialClassSetId: conversion.sourceMaterialClassSet.id,
            })}
          >
            {conversion.sourceMaterialClassSet.name}
          </LinkText>
        </LabeledValue>
        <LabeledValue label='Target Material Class Set'>
          <LinkText
            to={Router.MaterialClassSetDetail({
              materialClassSetId: conversion.targetMaterialClassSet.id,
            })}
          >
            {conversion.targetMaterialClassSet.name}
          </LinkText>
        </LabeledValue>

        <ActionIcon
          ml='auto'
          variant='outline'
          color='red'
          onClick={openDeleteModal}
        >
          <DeleteIcon />
        </ActionIcon>
      </Group>
      <MaterialClassSetConversionTable conversion={conversion} />
    </Stack>
  );
}

function MaterialClassSetConversionTable(props: {
  conversion: MaterialClassSetConversionDTO;
}) {
  const { conversion } = props;

  const patchMutation = usePatchMaterialClassSetConversion(conversion.id);

  // target -> source -> number
  const initialCoefficients: Record<
    string,
    Record<string, number>
  > = Object.fromEntries(
    conversion.targetMaterialClassSet.materialClasses.map((targetmc) => [
      targetmc.id,
      Object.fromEntries(
        conversion.sourceMaterialClassSet.materialClasses.map((sourceMc) => [
          sourceMc.id,
          0,
        ]),
      ),
    ]),
  );

  for (const {
    targetMaterialClassId,
    terms,
  } of conversion.targetMaterialClassSetEquations) {
    for (const { sourceMaterialClassId, coefficient } of terms) {
      initialCoefficients[targetMaterialClassId][sourceMaterialClassId] =
        coefficient;
    }
  }

  const [coefficients, setCoefficients] = useState(initialCoefficients);
  const [editing, setEditing] = useState(false);

  return (
    <>
      <Table
        style={{ textAlign: 'right', tableLayout: 'fixed' }}
        striped
        highlightOnHover
        withBorder
      >
        <thead>
          <tr>
            <th>Target/Source</th>
            {conversion.sourceMaterialClassSet.materialClasses.map(
              (sourceMc) => (
                <th key={sourceMc.id} style={{ textAlign: 'right' }}>
                  {sourceMc.shortName ?? sourceMc.name}
                </th>
              ),
            )}
          </tr>
        </thead>

        <tbody>
          {conversion.targetMaterialClassSet.materialClasses.map((targetMc) => (
            <tr key={targetMc.id}>
              <th>{targetMc.shortName ?? targetMc.name}</th>
              {conversion.sourceMaterialClassSet.materialClasses.map(
                (sourceMc) => {
                  const coefficient = coefficients[targetMc.id][sourceMc.id];
                  return (
                    <td key={sourceMc.id}>
                      {editing ? (
                        <Flex justify={'flex-end'}>
                          <NumberInput
                            hideControls
                            w='7ch'
                            value={coefficient * 100.0}
                            onChange={(v) =>
                              setCoefficients((c) => ({
                                ...c,
                                [targetMc.id]: {
                                  ...c[targetMc.id],
                                  [sourceMc.id]: v === '' ? 0 : v / 100.0,
                                },
                              }))
                            }
                          />
                        </Flex>
                      ) : coefficient === 0 ? (
                        <Text color='dimmed' size='xs'>
                          -
                        </Text>
                      ) : (
                        <Text>{PCTFORMAT.format(coefficient)}</Text>
                      )}
                    </td>
                  );
                },
              )}
            </tr>
          ))}
          <tr>
            <th>Total</th>
            {conversion.sourceMaterialClassSet.materialClasses.map(
              (sourceMc) => {
                const total = conversion.targetMaterialClassSet.materialClasses
                  .map((targetMc) => coefficients[targetMc.id][sourceMc.id])
                  .reduce((a, b) => a + b);

                const at100 = Math.abs(total - 1.0) < 0.001;
                return (
                  <td key={sourceMc.id} style={{ textAlign: 'right' }}>
                    <Text
                      weight={at100 ? undefined : 'bold'}
                      color={at100 ? 'green' : 'red'}
                    >
                      {(total * 100.0).toFixed(1)}%
                    </Text>
                  </td>
                );
              },
            )}
          </tr>
        </tbody>
      </Table>
      <Button
        color={patchMutation.isError ? 'red' : undefined}
        leftIcon={
          patchMutation.isError ? (
            <IconRefresh />
          ) : editing ? (
            <SaveIcon />
          ) : (
            <EditIcon />
          )
        }
        loading={patchMutation.isLoading}
        onClick={() => {
          if (patchMutation.isError) {
            patchMutation.reset();
            return;
          }

          if (editing) {
            patchMutation.mutate(
              {
                coefficients: Object.entries(coefficients)
                  .flatMap(([targetMaterialClassId, sourceCoefficients]) =>
                    Object.entries(sourceCoefficients).map(
                      ([sourceMaterialClassId, coefficient]) => ({
                        sourceMaterialClassId,
                        targetMaterialClassId,
                        coefficient,
                      }),
                    ),
                  )
                  .filter(({ coefficient }) => coefficient !== 0),
              },
              {
                onSuccess() {
                  patchMutation.reset();
                  setEditing(false);
                },
              },
            );
          } else {
            setEditing(true);
          }
        }}
      >
        {patchMutation.isError ? 'Reset Error' : editing ? 'Save' : 'Edit'}
      </Button>
    </>
  );
}
