import { UseFormReturnType, useForm, zodResolver } from '@mantine/form';
import dayjs, { Dayjs } from 'dayjs';
import { match } from 'ts-pattern';
import { ZodType, z } from 'zod';
import ContainerSelect from '../Container/ContainerSelect';
import { useFacilityContext } from '../Facility/FacilityContext';
import { ScaleSelect } from '../Scale/ScaleSelect';
import { RequiredDayjsDateTimePicker } from '../Time/DayjsDateTimePicker';
import { TruckLoadSelect } from '../TruckLoad/TruckLoadSelect';
import ScaleDisplayDivisionsInput from '../Weights/ScaleDisplayDivisionsInput';
import {
  ScaleDTO,
  ScaleReadingRepositoryAssociationDTO,
  WeightUnit,
} from '../rest-client';
import { pascalPad } from '../util/pascalPad';

export const ScaleSchema: ZodType<ScaleDTO> = z.object({
  id: z.string().uuid(),
  scaleTypeId: z.string().uuid(),
  name: z.string().min(1),
  unit: z.nativeEnum(WeightUnit),
  displayDivision: z.number().positive(),
  weighableContainerTypeIds: z.string().uuid().array(),
});

export const RepositoryKindSchema: z.ZodType<
  ScaleReadingRepositoryAssociationDTO['kind']
> = z.enum(['Container', 'TruckLoad']);

const ScaleReadingFormSchema = z
  .object({
    timestamp: z
      .instanceof(dayjs as unknown as typeof Dayjs)
      .nullable()
      .refine((val) => val !== null, { message: 'Timestamp is required' }),
    scale: ScaleSchema.nullable(),
    displayDivisions: z
      .number({ message: 'Weight is required' })
      .positive({ message: 'Weight must be positive' }),
    repositoryKind: RepositoryKindSchema,
    repositoryId: z.string().uuid().nullable(),
  })
  .refine(({ scale }) => scale !== null, {
    path: ['scale'],
    message: 'Scale is required',
  })
  .refine(({ repositoryId }) => repositoryId !== null)
  .refine(
    ({ scale, displayDivisions }) =>
      scale && displayDivisions % scale.displayDivision === 0,
    {
      message: 'Weight must be a multiple of the scale display division',
      path: ['displayDivisions'],
    },
  )
  .refine(
    ({ repositoryKind, repositoryId }) => repositoryKind && repositoryId,
    ({ repositoryKind }) => ({
      message: `${pascalPad(repositoryKind ?? 'Repository')} is required `,
      path: ['repositoryId'],
    }),
  );

export type ScaleReadingFormValues = z.infer<typeof ScaleReadingFormSchema>;

export type ScaleReadingFormProps = {
  timestamp?: Dayjs;
  repositoryKind?: ScaleReadingRepositoryAssociationDTO['kind'];
  repositoryId?: string;
  scale?: ScaleDTO;
};

export function useScaleReadingForm(props: ScaleReadingFormProps) {
  const {
    timestamp,
    repositoryKind = 'Container',
    repositoryId,
    scale,
  } = props;

  return useForm<ScaleReadingFormValues>({
    initialValues: {
      timestamp: timestamp ?? dayjs().utc(),
      scale: scale ?? null,
      displayDivisions: 0,
      repositoryKind: repositoryKind,
      repositoryId: repositoryId ?? null,
    },
    validate: zodResolver(ScaleReadingFormSchema),
  });
}

export function ScaleReadingFormFields(props: {
  form: UseFormReturnType<ScaleReadingFormValues>;
}) {
  const { form } = props;
  const facility = useFacilityContext();

  const scaleSelectDisabled =
    form.values.scale !== null && !form.isDirty('scale');
  const repositorySelectDisabled =
    form.values.repositoryId !== null && !form.isDirty('repositoryId');

  return (
    <>
      <RequiredDayjsDateTimePicker
        label='Scale Reading Time'
        description='The time at which the scale reading was generated.'
        placeholder='select time'
        tz={facility.timeZoneId}
        withAsterisk
        {...form.getInputProps('timestamp')}
      />
      {match(form.values.repositoryKind)
        .with('Container', () => (
          <ContainerSelect
            label='Container'
            description='Non-empty container having a scale reading recorded.'
            timestamp={form.values.timestamp ?? undefined}
            facilityId={facility.id}
            disabled={repositorySelectDisabled}
            hideFull={false}
            hideEmpty
            withAsterisk
            {...form.getInputProps('repositoryId')}
          />
        ))
        .with('TruckLoad', () => (
          <TruckLoadSelect
            label='Truck Load'
            description='Truck load having a scale reading recorded.'
            placeholder=''
            timestamp={form.values.timestamp ?? undefined}
            disabled
            withAsterisk
            {...form.getInputProps('repositoryId')}
          />
        ))
        .exhaustive()}
      <ScaleSelect
        label='Scale'
        description='Which scale at the facility generated this scale reading?'
        type='object'
        facilityId={facility.id}
        disabled={scaleSelectDisabled}
        withAsterisk
        {...form.getInputProps('scale')}
      />
      <ScaleDisplayDivisionsInput
        label='Gross Weight'
        placeholder='weight'
        description='Gross weight of container, including the tare weight plus the container contents'
        disabled={form.values.scale === null}
        withAsterisk
        scaleId={form.values.scale?.id ?? null}
        {...form.getInputProps('displayDivisions')}
      />
    </>
  );
}
