import {
  ActionIcon,
  Alert,
  Button,
  Center,
  Divider,
  Group,
  Kbd,
  Loader,
  Modal,
  ModalProps,
  Paper,
  Stack,
  Switch,
  Table,
  Text,
  Title,
  Tooltip,
} from '@mantine/core';
import { useHotkeys } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import {
  IconBroadcast,
  IconBroadcastOff,
  IconCamera,
  IconCheck,
  IconCloudDownload,
  IconCloudUpload,
  IconHelpCircle,
  IconPlayerStop,
  IconRefresh,
  IconScale,
  IconSettings,
} from '@tabler/icons-react';
import { useIsFetching, useIsMutating } from '@tanstack/react-query';
import { useState } from 'react';
import { match } from 'ts-pattern';
import { queryKeys, useQueryKeyInvalidator } from '../../api/queryKeys';
import { useSamplingSessionSignalRStore } from '../../api/samplingSessionSignalR';
import {
  useCloseSamplingSuiteSession,
  usePatchSamplingSuiteSession,
  useSamplingSuiteSession,
} from '../../api/samplingSuiteSession';
import { AppPage } from '../../App/AppPage';
import { MutationErrorAlert } from '../../Form/MutationErrorAlert';
import { LinkButton } from '../../Link';
import {
  SamplingSessionEndReason,
  SamplingSuiteSessionDTO,
} from '../../rest-client';
import { Router } from '../../router';
import { CaptureArchiveDrawerButton } from './ArchivedCaptures';
import {
  AssignedCapturesContainer,
  AssignedCapturesLightbox,
  BottomRow,
  MaterialClassRow,
} from './AssignedCaptureTable';
import classes from './SamplingSuiteAnalysis.module.css';
import { SamplingSuiteAnalysisContextProvider } from './SamplingSuiteAnalysisContext';
import sessionCss from './SamplingSuiteSession.module.css';
import {
  UnassignedCapturesLightbox,
  UnassignedCaptureTable,
} from './UnassignedCaptures';
import { UnlinkedCaptures } from './UnlinkedCaptures';

function SessionSignalRConnectivityStatusIndicator() {
  const state = useSamplingSessionSignalRStore((s) => s.state);
  const invalidator = useQueryKeyInvalidator();

  return match(state)
    .with('connected', () => (
      <Title color='teal'>
        <Tooltip label='Live Connection Established'>
          <IconBroadcast size={'1em'} style={{ marginBottom: '-.2em' }} />
        </Tooltip>
      </Title>
    ))
    .with('connecting', () => (
      <Tooltip label='Connecting...'>
        <Loader color='blue' />
      </Tooltip>
    ))
    .with('reconnecting', () => (
      <Tooltip label='Reconnecting...'>
        <Loader color='yellow' />
      </Tooltip>
    ))
    .with('disconnected', () => (
      <Title color='red'>
        <Group noWrap>
          <Tooltip label='Failed to Establish Live Connection'>
            <IconBroadcastOff size={'1em'} style={{ marginBottom: '-.2em' }} />
          </Tooltip>
          <ActionIcon
            onClick={() =>
              invalidator.invalidateKey(queryKeys.samplingSession.all)
            }
          >
            <IconRefresh />
          </ActionIcon>
        </Group>
      </Title>
    ))
    .exhaustive();
}

export function SamplingSuiteSessionPage(props: { sessionId: string }) {
  const { sessionId } = props;

  return (
    <AppPage
      title='VALI-Sample Session'
      titleRight={
        // TODO: Show connectivity status
        <Group align='flex-end' w='100%'>
          <SessionSignalRConnectivityStatusIndicator />
          <SessionSettingsButton sessionId={sessionId} />
          <HelpButton />
          <CaptureArchiveDrawerButton />
        </Group>
      }
    >
      <SamplingSuiteSession sessionId={sessionId} />
    </AppPage>
  );
}

function SessionSettingsButton(props: { sessionId: string }) {
  const { sessionId } = props;
  const sessionQuery = useSamplingSuiteSession(sessionId);

  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      {sessionQuery.data && (
        <SessionSettingsModal
          session={sessionQuery.data}
          onClose={() => setIsOpen(false)}
          opened={isOpen}
        />
      )}
      <Button
        variant='outline'
        leftIcon={<IconSettings />}
        color='gray'
        loading={sessionQuery.data === undefined}
        onClick={() => setIsOpen(true)}
      >
        Session Settings
      </Button>
    </>
  );
}

function SyncStatus() {
  const isMutating = useIsMutating();
  const isFetching = useIsFetching();

  return (
    <Group align='flex-end' ml='auto' className={sessionCss.stickyTool}>
      <IconCloudUpload
        strokeWidth={2}
        className={`${sessionCss.mutatingIcon} ${isMutating ? sessionCss.activeCloudIcon : ''}`}
      />
      <IconCloudDownload
        strokeWidth={2}
        className={`${sessionCss.fetchingIcon} ${isFetching ? sessionCss.activeCloudIcon : ''}`}
      />
    </Group>
  );
}

function SessionSettingsModal(
  props: { session: SamplingSuiteSessionDTO } & ModalProps,
) {
  const { session, ...modalProps } = props;

  return (
    <Modal title='Session Settings' {...modalProps}>
      <Stack>
        <Divider />
        <SessionSettingsCluster session={session} />
        <Divider />
        <InstanceActionCluster />
      </Stack>
    </Modal>
  );
}

function HelpButton() {
  const [opened, setOpened] = useState(false);

  return (
    <>
      <Button
        color='blue'
        leftIcon={<IconHelpCircle />}
        onClick={() => setOpened(true)}
      >
        Info
      </Button>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title='VALI-Sample Session Info'
      >
        <Title order={4}>Keyboard Shortcuts</Title>
        <Table>
          <thead>
            <tr>
              <th>Key</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <Kbd>s</Kbd>
              </td>
              <td>Skip selected material class (toggle)</td>
            </tr>
            <tr>
              <td>
                <Kbd>↓</Kbd>
              </td>
              <td>Select next material class</td>
            </tr>
            <tr>
              <td>
                <Kbd>↑</Kbd>
              </td>
              <td>Select previous material class</td>
            </tr>
          </tbody>
        </Table>
      </Modal>
    </>
  );
}

function SamplingSuiteSession(props: { sessionId: string }) {
  const { sessionId } = props;
  const sessionQuery = useSamplingSuiteSession(sessionId);

  if (sessionQuery.data) {
    const session = sessionQuery.data;

    if (session.endDetails != null) {
      const returnToSampleButton = (
        <LinkButton
          replace
          to={Router.SampleDetail({ sampleId: session.sampleId })}
        >
          Return to the sample
        </LinkButton>
      );

      return match(session.endDetails.endReason)
        .with(SamplingSessionEndReason.ANALYSIS_COMPLETED, () => (
          <Alert
            color='teal'
            title='Session Complete'
            withCloseButton
            onClose={() =>
              Router.replace('SampleDetail', { sampleId: session.sampleId })
            }
          >
            This session was ended because the analysis was completed
            succesfully.
            {returnToSampleButton}
          </Alert>
        ))
        .with(SamplingSessionEndReason.CLOSED_WITHOUT_COMPLETING, () => (
          <Alert
            color='yellow'
            title='Session Closed'
            withCloseButton
            onClose={() =>
              Router.replace('SampleDetail', { sampleId: session.sampleId })
            }
          >
            This session was closed without completing the analysis.
            {/* TODO: Button to create a new session with state copied from previous session, if it is also the most recent */}
            {returnToSampleButton}
          </Alert>
        ))
        .with(SamplingSessionEndReason.FORCEFULLY_CLOSED, () => (
          <Alert
            color='orange'
            title='Session Closed by Another User'
            withCloseButton
            onClose={() =>
              Router.replace('SampleDetail', { sampleId: session.sampleId })
            }
          >
            This session was closed because another user started a session while
            this one was still open.
            {/* TODO: Button to create a new session with state copied from previous session, if it is also the most recent */}
            {returnToSampleButton}
          </Alert>
        ))
        .exhaustive();
    }

    return (
      <SamplingSuiteAnalysisContextProvider
        samplingSuiteSampleAnalysisId={session.samplingSuiteSampleAnalysisId}
      >
        <Group
          spacing='sm'
          align='flex-start'
          noWrap
          className={classes.topLevel}
        >
          <AssignedCaptureTable session={session} />

          <Paper className={sessionCss.stickyTool} p='sm' withBorder>
            <Stack align='center'>
              <Title order={3}>Staged</Title>
              <UnassignedCaptureTable
                captures={session.linkedCaptures}
                activeMaterialClass={session.activeMaterialClassId}
              />
            </Stack>
          </Paper>

          <UnlinkedCaptureSection />

          <SyncStatus />
        </Group>

        <AssignedCapturesLightbox
          namedCaptures={session.materialClassStates.flatMap((s) =>
            s.assignedCaptures.map((capture) => ({
              capture,
              name: s.materialClassName ?? 'Whole Sample',
            })),
          )}
        />
        <UnassignedCapturesLightbox captures={session.linkedCaptures} />
      </SamplingSuiteAnalysisContextProvider>
    );
  }

  if (sessionQuery.error) {
    throw sessionQuery.error;
  }

  return (
    <Center>
      <Stack align='center' justify='center'>
        <Loader variant='bars' size='xl' />
        <Text c='dimmed'>Loading VALI-Sample session...</Text>
      </Stack>
    </Center>
  );
}

function SessionSettingsCluster(props: { session: SamplingSuiteSessionDTO }) {
  const { session } = props;
  return (
    <Stack>
      <AutoAssignSwitch session={session} />
      <AutoProceedSwitch session={session} />
    </Stack>
  );
}

function AutoAssignSwitch(props: { session: SamplingSuiteSessionDTO }) {
  const { session } = props;

  const patchMutation = usePatchSamplingSuiteSession();

  return (
    <Switch
      checked={session.autoAssign}
      onChange={(e) => {
        if (!patchMutation.isIdle) return;
        patchMutation.mutate(
          {
            sessionId: session.id,
            patch: {
              autoAssign: e.currentTarget.checked,
            },
          },
          {
            onSettled() {
              patchMutation.reset();
            },
          },
        );
      }}
      label='Assign new captures to active row'
    />
  );
}

function AutoProceedSwitch(props: { session: SamplingSuiteSessionDTO }) {
  const { session } = props;

  const patchMutation = usePatchSamplingSuiteSession();

  return (
    <Switch
      label='Advance active material class after a capture is assigned'
      checked={session.autoProceed}
      onChange={(e) => {
        if (!patchMutation.isIdle) return;
        patchMutation.mutate(
          {
            sessionId: session.id,
            patch: {
              autoProceed: e.currentTarget.checked,
            },
          },
          {
            onSettled() {
              patchMutation.reset();
            },
          },
        );
      }}
    />
  );
}

function UnlinkedCaptureSection() {
  // TODO: Toggle to include/exclude captures from other suites?

  const body = <UnlinkedCaptures />;

  if (body) {
    return (
      <Paper className={sessionCss.stickyTool} p='sm' withBorder>
        <Stack align='center'>
          <Title order={3}>Unlinked</Title>
          {body}
        </Stack>
      </Paper>
    );
  }

  return null;
}

function InstanceActionCluster() {
  return (
    <Stack spacing='xs'>
      <Button
        color='indigo'
        variant='outline'
        leftIcon={<IconScale />}
        onClick={() => {
          // TODO: Implement scale calibration
        }}
        disabled
      >
        Calibrate Scale
      </Button>
      <Button
        color='indigo'
        variant='outline'
        leftIcon={<IconCamera />}
        onClick={() => {
          // TODO: Implement manual capture trigger
        }}
        disabled
      >
        Trigger Capture
      </Button>
    </Stack>
  );
}

function AssignedCaptureTable(props: { session: SamplingSuiteSessionDTO }) {
  const { session } = props;
  const patchMutation = usePatchSamplingSuiteSession();

  const stateSequence = session.materialClassStates.map(
    (s) => s.materialClassId,
  );
  const currIdx = stateSequence.indexOf(session.activeMaterialClassId);
  const nextIdx = Math.min(currIdx + 1, stateSequence.length - 1);
  const prevIdx = Math.max(currIdx - 1, 0);
  const nextMaterialClassId = stateSequence[nextIdx];
  const prevMaterialClassId = stateSequence[prevIdx];

  useHotkeys([
    [
      's',
      () => {
        if (!patchMutation.isIdle) return;
        const currentlyIsEmpty =
          session.materialClassStates.find(
            (s) => s.materialClassId === session.activeMaterialClassId,
          )?.isEmpty ?? false;

        return patchMutation.mutate(
          {
            sessionId: session.id,
            patch: {
              emptinessChanges: [
                {
                  isEmpty: !currentlyIsEmpty,
                  materialClassId: session.activeMaterialClassId,
                },
              ],
            },
          },
          {
            onSettled() {
              patchMutation.reset();
            },
          },
        );
      },
    ],
    [
      'ArrowUp',
      () => {
        if (!patchMutation.isIdle) return;
        if (prevIdx === currIdx) return;
        patchMutation.mutate(
          {
            sessionId: session.id,
            patch: {
              activeMaterialClassId: prevMaterialClassId,
            },
          },
          {
            onSettled() {
              patchMutation.reset();
            },
          },
        );
      },
    ],
    [
      'ArrowDown',
      () => {
        if (!patchMutation.isIdle) return;
        if (nextIdx === currIdx) return;
        patchMutation.mutate(
          {
            sessionId: session.id,
            patch: {
              activeMaterialClassId: nextMaterialClassId,
            },
          },
          {
            onSettled() {
              patchMutation.reset();
            },
          },
        );
      },
    ],
  ]);

  const activeMutation = usePatchSamplingSuiteSession();

  const emptyMutation = usePatchSamplingSuiteSession();

  const materialClassRows = session.materialClassStates.map((state) => (
    <MaterialClassRow
      key={state.materialClassId}
      materialClass={
        state.materialClassId && state.materialClassName
          ? { id: state.materialClassId, name: state.materialClassName }
          : null
      }
      captures={state.assignedCaptures}
      totalNetWeight={state.totalNetWeight}
      totalDriedNetWeight={state.totalDriedNetWeight}
      isEmpty={state.isEmpty}
      onEmptyChange={(isEmpty) => {
        if (!emptyMutation.isIdle) return;
        emptyMutation.mutate(
          {
            sessionId: session.id,
            patch: {
              emptinessChanges: [
                {
                  materialClassId: state.materialClassId,
                  isEmpty,
                },
              ],
            },
          },
          {
            onSettled() {
              emptyMutation.reset();
            },
          },
        );
      }}
      activate={() => {
        if (!activeMutation.isIdle) return;
        activeMutation.mutate(
          {
            sessionId: session.id,
            patch: {
              activeMaterialClassId: state.materialClassId,
            },
          },
          {
            onSettled() {
              activeMutation.reset();
            },
          },
        );
      }}
      active={session.activeMaterialClassId === state.materialClassId}
    />
  ));

  const closeMutation = useCloseSamplingSuiteSession();

  const closeButton = (
    <Button
      leftIcon={<IconPlayerStop />}
      variant='outline'
      color='gray'
      loading={closeMutation.isLoading}
      onClick={() => {
        closeMutation.mutate(
          { sessionId: session.id, close: { complete: false } },
          {
            onSuccess() {
              Router.replace('SampleDetail', {
                sampleId: session.sampleId,
              });
              showNotification({
                title: 'Session Closed',
                message: 'Your VALI-Sample session was closed successfully.',
                color: 'green',
                icon: <IconCheck />,
              });
            },
          },
        );
      }}
    >
      Finish Later
    </Button>
  );

  const complete = () =>
    closeMutation.mutate(
      {
        sessionId: session.id,
        close: {
          complete: true,
        },
      },
      {
        onSuccess() {
          Router.replace('SampleDetail', {
            sampleId: session.sampleId,
          });
          showNotification({
            title: 'Session Completed',
            message: 'Your VALI-Sample session was completed successfully.',
            color: 'green',
            icon: <IconCheck />,
          });
        },
      },
    );

  const bottomRow = closeMutation.isError ? (
    <MutationErrorAlert
      errorTitle={'Error closing session'}
      entityName={'session'}
      mutation={closeMutation}
      formVariant={'close'}
    />
  ) : (
    <BottomRow
      key='bottom'
      states={session.materialClassStates.map((s) => ({
        isEmpty: s.isEmpty,
        captures: s.assignedCaptures,
        materialClassId: s.materialClassId,
      }))}
      unassignedCaptures={session.linkedCaptures}
      actions={closeButton}
      complete={complete}
      completionMutation={closeMutation}
    />
  );

  const rows = [...materialClassRows, bottomRow];

  return <AssignedCapturesContainer>{rows}</AssignedCapturesContainer>;
}
