import { Checkbox, CheckboxProps, Input } from '@mantine/core';

export function CheckboxInput(props: CheckboxProps) {
  const {
    label,
    description,
    disabled,
    checked,
    onChange,
    error,
    ...checkBoxProps
  } = props;
  return (
    <Input.Wrapper label={label} description={description}>
      <Checkbox
        size='xl'
        mt={description !== undefined ? '.3rem' : undefined}
        disabled={disabled}
        checked={checked}
        onChange={onChange}
        error={error}
        {...checkBoxProps}
      />
    </Input.Wrapper>
  );
}
