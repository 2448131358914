import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useMaterialClassSets } from '../api/materialClassSet';
import { useCreateSample } from '../api/sample';
import { useCreateSamplingSuiteSampleAnalysis } from '../api/samplingSuiteSampleAnalysis';
import { AppPage } from '../App/AppPage';
import { useTenantContext } from '../App/TenantContext';
import { FormWrapper } from '../Form/FormWrapper';
import { useFormNavBlocker } from '../Form/useFormNavBlocker';
import {
  NotionalSampleLinkCreationDTO,
  NotionalSampleOriginCreationDTO,
  SampleCreationDTO,
} from '../rest-client';
import { Router } from '../router';
import {
  NotionalSampleFormFields,
  NotionalSampleFormProps,
  NotionalSampleFormValues,
  useNotionalSampleForm,
} from './NotionalSampleForm';

export function AddNotionalSamplePage(props: NotionalSampleFormProps) {
  const tenant = useTenantContext();
  const isRadius = tenant.tenantId === 1;

  const form = useFormNavBlocker(useNotionalSampleForm(props));
  // TODO: add createMutation
  const createMutation = useCreateSample();
  const sampleId = useMemo(() => uuidv4(), []);

  const materialClassSetsQuery = useMaterialClassSets();
  const [materialClassSetId, setMaterialClassSetId] = useState<string | null>(
    null,
  );
  if (
    materialClassSetsQuery.data &&
    materialClassSetsQuery.data.length > 0 &&
    materialClassSetId === null
  ) {
    setMaterialClassSetId(materialClassSetsQuery.data[0].id);
  }
  const addSamplingSuiteAnalysisMutation =
    useCreateSamplingSuiteSampleAnalysis();

  const formValuesToDto = ({
    timestamp,
    name,
    materialProcessed,
    commodityId,
    intermediateProduct,
    internalMaterialSourceId,
    mixedInternalMaterialSources,
    processId,
    outputPortId,
    recoveryStrategyId,
    recoveryStrategyPathId,
    internalMaterialSinkId,
  }: NotionalSampleFormValues) => {
    if (timestamp === null) {
      throw new Error('timestamp must be defined');
    }
    // determining NotionalSampleCreationDTO.MaterialProcessed
    let materialProcessedDto: NotionalSampleOriginCreationDTO;
    if (!materialProcessed) {
      // NotionalSampleCreationDTO.MaterialProcessed as NotProcessedWithMixedUpstreamSourceDTO
      if (mixedInternalMaterialSources) {
        if (commodityId === null) {
          throw new Error(
            'Commodity cannot be null if the material is unprocessed and the upstream source is unknown.',
          );
        }
        materialProcessedDto = {
          kind: 'MixedSourcesNotProcessed',
        };
      }
      // NotionalSampleCreationDTO.MaterialProcessed as NotProcessedWithSingleUpstreamSourceDTO
      else {
        if (internalMaterialSourceId === null) {
          throw new Error(
            'Upstream source cannot be null if the material is unprocessed and not mixed.',
          );
        }
        materialProcessedDto = {
          kind: 'SingleSourceNotProcessed',
          internalMaterialSourceId: internalMaterialSourceId,
        };
      }
    }
    // NotionalSampleCreationDTO.MaterialProcessed as MaterialProcessedDTO
    else {
      if (!intermediateProduct && commodityId === null) {
        throw new Error(
          'Commodity cannot be null if the material has been processed and the material is not an intermediate product.',
        );
      }
      materialProcessedDto = {
        kind: 'Processed',
        upstreamSource: null,
        producingProcess: null,
        employedRecoveryStrategy: null,
      };
      // determining NotionalSampleCreationDTO.MaterialProcessed.UpstreamSource
      if (mixedInternalMaterialSources) {
        materialProcessedDto.upstreamSource = {
          kind: 'MixedInternalMaterialSources',
        };
      } else {
        if (internalMaterialSourceId !== null) {
          materialProcessedDto.upstreamSource = {
            kind: 'SingleInternalMaterialSource',
            internalMaterialSourceId: internalMaterialSourceId,
          };
        }
      }
      // determining NotionalSampleCreationDTO.MaterialProcessed.ProducingProcess
      if (processId !== null) {
        if (outputPortId !== null) {
          materialProcessedDto.producingProcess = {
            kind: 'KnownOutputPort',
            processId: processId,
            outputPortId: outputPortId,
          };
        } else {
          materialProcessedDto.producingProcess = {
            kind: 'UnknownOutputPort',
            processId: processId,
          };
        }
      }
      // determining NotionalSampleCreationDTO.MaterialProcessed.EmployedRecoveryStrategy
      if (recoveryStrategyId !== null) {
        if (recoveryStrategyPathId !== null) {
          materialProcessedDto.employedRecoveryStrategy = {
            kind: 'KnownPath',
            recoveryStrategyId: recoveryStrategyId,
            recoveryStrategyPathId: recoveryStrategyPathId,
            // Dummy values to get code to compile
            order: 1,
            negative: true,
          };
        } else {
          materialProcessedDto.employedRecoveryStrategy = {
            kind: 'UnknownPath',
            recoveryStrategyId: recoveryStrategyId,
          };
        }
      }
    }

    const notionalSample: NotionalSampleLinkCreationDTO = {
      type: 'notional',
      manuallySpecifiedCommodityId: commodityId,
      isManuallySpecifiedIntermediate: false,
      sampleTakenTimestamp: timestamp.utc().toISOString(),
      name: name !== '' ? name : null,
      notionalSampleOrigin: materialProcessedDto,
      internalMaterialSinkId,
    };

    const sample: SampleCreationDTO = {
      id: sampleId,
      link: notionalSample,
    };
    return sample;
  };

  return (
    <AppPage title='Add Sample'>
      <AppPage.Section>
        <FormWrapper
          entityName='Sample'
          form={form}
          formVariant='create'
          mutation={createMutation}
          onSuccess={({ id }: SampleCreationDTO) => {
            if (isRadius && materialClassSetId) {
              addSamplingSuiteAnalysisMutation.mutate(
                {
                  id: uuidv4(),
                  sampleId,
                  materialClassSetId,
                },
                {
                  onError() {
                    showNotification({
                      title: 'Error Creating VALI-Sample Analysis',
                      message: 'An error occurred creating the analysis',
                      color: 'red',
                      icon: <IconX />,
                    });
                  },
                  onSuccess() {
                    showNotification({
                      title: 'VALI-Sample Analysis Created',
                      message: 'A new VALI-Sample analysis has been created',
                      color: 'green',
                      icon: <IconCheck />,
                    });
                  },
                  onSettled() {
                    addSamplingSuiteAnalysisMutation.reset();
                  },
                },
              );
            }
            Router.replace('SampleDetail', {
              sampleId: id,
            });
          }}
          formValuesToDto={formValuesToDto}
          onCancel={() => {
            Router.push('SampleList');
          }}
          formActionButtonsPosition={isRadius ? 'left' : 'right'}
        >
          <NotionalSampleFormFields form={form} />
        </FormWrapper>
      </AppPage.Section>
    </AppPage>
  );
}
