import { Button, ButtonProps, Group, Text, Title } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useDeleteRadius3dsSampleParameters } from '../../api/radius3dsSampleParameters';
import { LabeledValue } from '../../common';
import { DeleteEntityButton } from '../../Form/DeleteEntity';
import { EditIcon, ProcessParametersIcon } from '../../Icons';
import {
  Radius3DSSampleParametersDTO,
  SampleDTO,
  SampleId,
} from '../../rest-client';
import { WithUnit } from '../../util/WithUnit';
import {
  AddRadius3DSSampleParametersDrawerForm,
  EditRadius3DSSampleParametersDrawerForm,
} from './Radius3DSSampleParametersForm';

export function Radius3DSSampleParametersSection(props: { sample: SampleDTO }) {
  const { sample } = props;

  const radius3dsParams = sample.radius3DSSampleParameters;
  const deleteMutation = useDeleteRadius3dsSampleParameters();

  return (
    <>
      <Group>
        <Title order={4}>3DS Parameters</Title>

        {radius3dsParams !== null ? (
          <>
            <Edit3DSSampleParametersButton sample={sample} variant='outline' />
            <DeleteEntityButton
              entityName='3DS Parameters'
              deleteMutation={deleteMutation}
              mutationVariables={sample.id}
              variant='outline'
            />
          </>
        ) : (
          <AddRadius3DSSampleParametersButton sampleId={sample.id} />
        )}
      </Group>
      <Group>
        <Radius3DSParametersDetails radius3dsParams={radius3dsParams} />
      </Group>
    </>
  );
}

export function Radius3DSParametersDetails(props: {
  radius3dsParams: Radius3DSSampleParametersDTO | null;
}) {
  const { radius3dsParams } = props;
  return (
    <>
      <LabeledValue label='Relative Density'>
        {radius3dsParams !== null ? (
          radius3dsParams.relativeDensity
        ) : (
          <Text c='dimmed'>none</Text>
        )}
      </LabeledValue>
      <LabeledValue label='Throughput'>
        {radius3dsParams !== null ? (
          <WithUnit unitSuffix='tph'>
            {radius3dsParams.shortTonsPerHour}
          </WithUnit>
        ) : (
          <Text c='dimmed'>none</Text>
        )}
      </LabeledValue>
    </>
  );
}

function AddRadius3DSSampleParametersButton(
  props: Omit<ButtonProps, 'onClick'> & { sampleId: SampleId },
) {
  const {
    sampleId,
    children = 'Add 3DS Parameters',
    leftIcon = <ProcessParametersIcon />,
    ...buttonProps
  } = props;

  const [drawerOpened, { open: openDrawer, close: closeDrawer }] =
    useDisclosure(false);
  return (
    <>
      <Button
        onClick={openDrawer}
        leftIcon={leftIcon}
        {...buttonProps}
        size='sm'
      >
        {children}
      </Button>
      <AddRadius3DSSampleParametersDrawerForm
        opened={drawerOpened}
        onClose={closeDrawer}
        sampleId={sampleId}
      />
    </>
  );
}

function Edit3DSSampleParametersButton(
  props: Omit<ButtonProps, 'onClick'> & { sample: SampleDTO },
) {
  const {
    sample,
    children = 'Edit',
    leftIcon = <EditIcon />,
    ...buttonProps
  } = props;

  const [drawerOpened, { open: openDrawer, close: closeDrawer }] =
    useDisclosure(false);

  return (
    <>
      <Button onClick={openDrawer} leftIcon={leftIcon} {...buttonProps}>
        {children}
      </Button>
      {drawerOpened ? (
        <EditRadius3DSSampleParametersDrawerForm
          opened={drawerOpened}
          onClose={closeDrawer}
          sample={sample}
        />
      ) : null}
    </>
  );
}
