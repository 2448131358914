import { LineChart } from 'echarts/charts';
import {
  DatasetComponent,
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent,
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { useCallback } from 'react';
import { EChart } from '../../../echarts/BareEChart';
import { RedWaveMaterialClassMetadataDTO } from '../../../rest-client';
import cssClasses from './RedWaveMaterialClassTimeseriesChart.module.css';

echarts.use([
  LineChart,
  GridComponent,
  TitleComponent,
  LegendComponent,
  TooltipComponent,
  DatasetComponent,
]);

export function RedWaveMaterialClassParticlesPerMinuteTimeSeriesChart(props: {
  classMetadata: Record<string, RedWaveMaterialClassMetadataDTO>;
  timestamps: string[];
  classParticlesPerMinute: Record<string, number[]>;
}) {
  const { classMetadata, timestamps, classParticlesPerMinute } = props;

  const maxObjectsPerMinuteDigits = Math.max(
    ...Object.values(classParticlesPerMinute).flat(),
  ).toString().length;
  return (
    <EChart
      className={cssClasses.chart}
      option={{
        title: {
          text: 'Material Class Particle Throughput',
        },
        dataset: {
          source: {
            timestamps,
            ...classParticlesPerMinute,
          },
        },
        xAxis: {
          name: 'Time (hh:mm)',
          type: 'time',
          nameLocation: 'middle',
          nameTextStyle: {
            lineHeight: 40,
          },
        },
        yAxis: {
          name: 'Throughput (particles / min)',
          type: 'value',
          nameLocation: 'middle',
          nameTextStyle: {
            lineHeight: 40 + 12 * maxObjectsPerMinuteDigits,
          },
        },
        series: Object.entries(classMetadata).map(
          ([classNumber, { name }]) => ({
            type: 'line',
            encode: { x: 'timestamps', y: classNumber },
            name,
            showSymbol: false,
          }),
        ),
        legend: {
          top: 'top',
          right: 150,
        },
        tooltip: {
          trigger: 'axis',
          order: 'valueDesc',
        },
      }}
    />
  );
}

export function RedWaveMaterialClassAreaProportionTimeseriesChart(props: {
  classMetadata: Record<string, RedWaveMaterialClassMetadataDTO>;
  timestamps: string[];
  classAreaProportions: Record<string, number[]>;
}) {
  const { classMetadata, classAreaProportions, timestamps } = props;

  const axisLabelFormatter = useCallback(
    (value: number | null) => `${value === null ? 'unknown ' : value * 100}%`,
    [],
  );

  const tooltipLabelFormatter = useCallback(
    (value: number | null) =>
      `${value === null ? 'unknown' : (value * 100).toFixed(1)}%`,
    [],
  );

  return (
    <EChart
      className={cssClasses.chart}
      option={{
        title: {
          text: 'Material Class Areas',
        },
        dataset: {
          source: {
            timestamps,
            ...classAreaProportions,
          },
        },
        xAxis: {
          name: 'Time (hh:mm)',
          type: 'time',
          nameLocation: 'middle',
          nameTextStyle: {
            lineHeight: 40,
          },
        },
        yAxis: {
          name: 'Proportion',
          type: 'value',
          axisLabel: {
            formatter: axisLabelFormatter,
          },
          nameLocation: 'middle',
          nameTextStyle: {
            lineHeight: 100,
          },
        },
        series: Object.entries(classMetadata).map(
          ([classNumber, { name }]) => ({
            type: 'line',
            encode: { x: 'timestamps', y: classNumber },
            name,
            // step: 'end',
            // smooth: true,
            showSymbol: false,
          }),
        ),
        tooltip: {
          trigger: 'axis',
          valueFormatter: tooltipLabelFormatter,
          order: 'valueDesc',
        },
        legend: {
          top: 'top',
        },
      }}
    />
  );
}
