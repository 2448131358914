import {
  Alert,
  Badge,
  Button,
  Group,
  List,
  Loader,
  Stack,
  Switch,
  Table,
  Text,
  Title,
} from '@mantine/core';
import {
  IconExternalLink,
  IconFileDownload,
  IconTableExport,
} from '@tabler/icons-react';
import { useEffect, useState } from 'react';
import { match } from 'ts-pattern';
import { v4 as uuidv4 } from 'uuid';
import {
  useCreateInsightSampleExport,
  useExportPreview,
  useInsightSampleExport,
  useInsightSampleExports,
} from '../api/riverside';
import { AppPage } from '../App/AppPage';
import { useFacilityContext } from '../Facility/FacilityContext';
import { LinkButton, LinkText } from '../Link';
import {
  InsightSampleExportDetailDTO,
  InsightSampleExportResultDTO,
  InsightSampleExportSampleDTO,
} from '../rest-client';
import { Router } from '../router';
import Temporal from '../Temporal/temporal';

export function InsightExportButton() {
  return (
    <LinkButton
      to={Router.SampleInsightExportList()}
      color='blue'
      leftIcon={<IconTableExport />}
      variant='outline'
      ml='auto'
    >
      Export to Insight
    </LinkButton>
  );
}

export function SampleInsightExportPage() {
  return (
    <AppPage
      breadcrumbs={[{ routeName: Router.SampleList(), title: 'Samples' }]}
      title='Insight Exports'
    >
      <AppPage.Section>
        <ExportCreationSection />
      </AppPage.Section>
      <AppPage.Section>
        <Stack>
          <Title order={3}>Export History</Title>
          <ExportHistorySection />
        </Stack>
      </AppPage.Section>
    </AppPage>
  );
}

function ExportHistorySection() {
  const exportsQuery = useInsightSampleExports();

  const { timeZoneId: tz } = useFacilityContext();

  if (exportsQuery.data) {
    const exports = exportsQuery.data;

    if (exports.length === 0) {
      return (
        <Stack>
          <Alert variant='outline' color='gray' title='No export history'>
            No exports have been created yet. Historical exports will appear
            here after one has been created.
          </Alert>
        </Stack>
      );
    }

    return (
      <Stack>
        <Table>
          <thead>
            <tr>
              <th>Export</th>
              <th>Samples</th>
            </tr>
          </thead>
          <tbody>
            {exports.map((exp) => (
              <tr key={exp.id}>
                <td>
                  <LinkText
                    to={Router.SampleInsightExportDetail({ exportId: exp.id })}
                  >
                    {Temporal.Instant.from(exp.insertedAt)
                      .toZonedDateTimeISO(tz)
                      .toLocaleString()}
                  </LinkText>
                </td>
                <td>{exp.sampleCount}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Stack>
    );
  }

  if (exportsQuery.isLoadingError) {
    throw exportsQuery.error;
  }

  return <Loader />;
}

type SampleExportStatus =
  | 'exportable'
  | 'unanalyzed' // incomplete, or no analysis
  | 'no-commodity'
  | 'no-commodity-to-insight-sampling-type'
  | 'no-material-class-set-conversion';

function SampleExportStatusBadge(props: { status: SampleExportStatus }) {
  return match(props.status)
    .with('exportable', () => (
      <Badge color='green' variant='filled'>
        Exportable
      </Badge>
    ))
    .with('no-commodity', () => (
      <Badge color='orange' variant='outline'>
        No Commodity
      </Badge>
    ))
    .with('no-commodity-to-insight-sampling-type', () => (
      <Badge color='red'>No Commodity to Insight Sampling Type Mapping</Badge>
    ))
    .with('no-material-class-set-conversion', () => (
      <Badge color='orange'>No Material Class Set Conversion</Badge>
    ))
    .with('unanalyzed', () => (
      <Badge color='gray' variant='outline'>
        Unanalyzed
      </Badge>
    ))
    .exhaustive();
}

function getSampleStatusMap(exportResult: InsightSampleExportResultDTO) {
  const sampleStatuses = new Map<string, SampleExportStatus>();
  for (const sample of exportResult.allSamples) {
    sampleStatuses.set(sample.sampleId, 'exportable');
  }

  for (const sampleId of exportResult.unanalyzedSamples) {
    sampleStatuses.set(sampleId, 'unanalyzed');
  }

  for (const sampleId of exportResult.noCommoditySamples) {
    sampleStatuses.set(sampleId, 'no-commodity');
  }

  for (const sampleId of exportResult.noCommodityToInsightSamplingTypeSamples) {
    sampleStatuses.set(sampleId, 'no-commodity-to-insight-sampling-type');
  }

  for (const sampleId of exportResult.noConversionSamples) {
    sampleStatuses.set(sampleId, 'no-material-class-set-conversion');
  }
  return sampleStatuses;
}

function SampleExportTable(props: {
  sampleStatuses: Map<string, SampleExportStatus>;
  samples: InsightSampleExportSampleDTO[];
}) {
  const { sampleStatuses, samples } = props;

  const { timeZoneId: tz } = useFacilityContext();

  return (
    <Table w='fit-content' withBorder>
      <thead>
        <tr>
          <th style={{ textAlign: 'right' }}>Sample Id</th>
          <th>Sample Time</th>
          <th>Status</th>
          <th>Age</th>
        </tr>
      </thead>
      <tbody>
        {samples.map((sample) => {
          const sampleStatus = sampleStatuses.get(sample.sampleId);
          if (sampleStatus === undefined) return null;

          return (
            <tr key={sample.sampleId}>
              <td style={{ textAlign: 'right' }}>
                <LinkText
                  to={Router.SampleDetail({
                    sampleId: sample.sampleId,
                  })}
                  newTab
                >
                  {sample.sampleNumericId}{' '}
                  <IconExternalLink
                    size='1.1em'
                    style={{ marginBottom: '-.12em' }}
                  />
                </LinkText>
              </td>
              <td>
                {Temporal.Instant.from(sample.sampleTime)
                  .toZonedDateTimeISO(tz)
                  .toLocaleString()}{' '}
              </td>
              <td>
                <SampleExportStatusBadge status={sampleStatus} />
              </td>
              <td>
                {sample.isOutsideDeduplicationWindow ? (
                  <Badge color='orange' variant='filled'>
                    Old
                  </Badge>
                ) : (
                  <Badge>Okay</Badge>
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}

function ExportCreationSection() {
  const previewQuery = useExportPreview();
  const createMutation = useCreateInsightSampleExport();

  const [includeOld, setIncludeOld] = useState(false);

  const createExport = () => {
    createMutation.mutate(
      {
        id: uuidv4(),
        sampleIds: null,
        includeOld,
      },
      {
        onSuccess(_data, { id }) {
          Router.push('SampleInsightExportDetail', { exportId: id });
        },
      },
    );
  };

  if (previewQuery.data !== undefined) {
    const preview = previewQuery.data;
    const { exportResult } = preview;

    if (exportResult.allSamples.length === 0) {
      return (
        <Alert color='gray' title='All Samples Exported'>
          <Text>
            All samples have already been exported. You can download the CSV
            from historical exports below.
          </Text>
        </Alert>
      );
    }

    const sampleStatuses = getSampleStatusMap(exportResult);
    let exportableSamples = exportResult.allSamples.filter(
      (s) => sampleStatuses.get(s.sampleId) === 'exportable',
    );
    let unexportableSamples = exportResult.allSamples.filter(
      (s) => sampleStatuses.get(s.sampleId) !== 'exportable',
    );

    if (!includeOld) {
      exportableSamples = exportableSamples.filter(
        (s) => !s.isOutsideDeduplicationWindow,
      );
      unexportableSamples = unexportableSamples.filter(
        (s) => !s.isOutsideDeduplicationWindow,
      );
    }

    const anyOld = exportResult.allSamples.some(
      (s) => s.isOutsideDeduplicationWindow,
    );

    return (
      <Stack>
        {anyOld ? (
          <>
            <Switch
              color='orange'
              label='Include samples over 28 days old'
              checked={includeOld}
              onChange={(e) => setIncludeOld(e.currentTarget.checked)}
            />
            {includeOld ? (
              <Alert
                color='orange'
                title='Potential for Duplicates'
                variant='filled'
              >
                <Text>
                  Samples older than 30 days will not be checked for duplicates
                  by Insight!
                </Text>
                <Text>
                  Only continue with this export if you are certain that you
                  will not create any duplicate entries in Insight.
                </Text>
              </Alert>
            ) : null}
          </>
        ) : null}

        <Group align='flex-start'>
          <Stack>
            <Title order={4}>Exportable Samples</Title>

            {exportableSamples.length === 0 ? (
              <Alert color='yellow' title='No Exportable Samples'>
                There are no samples ready for export.
              </Alert>
            ) : (
              <SampleExportTable
                sampleStatuses={sampleStatuses}
                samples={exportableSamples}
              />
            )}

            <Button
              loading={createMutation.isLoading}
              size='lg'
              leftIcon={<IconTableExport />}
              onClick={() => createExport()}
              color={includeOld ? 'orange' : undefined}
              disabled={exportableSamples.length === 0}
            >
              Create Export
            </Button>
          </Stack>

          <Stack>
            <Title order={4}>Unexportable Samples</Title>
            {unexportableSamples.length === 0 ? (
              <Alert color='green'>No unexportable samples</Alert>
            ) : (
              <SampleExportTable
                sampleStatuses={sampleStatuses}
                samples={unexportableSamples}
              />
            )}
          </Stack>
        </Group>
      </Stack>
    );
  }

  if (previewQuery.isError) {
    throw previewQuery.error;
  }

  return <Loader />;
}

function humanFileSize(size: number) {
  const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
  return `${+(size / 1024 ** i).toFixed(2) * 1} ${['B', 'kB', 'MB', 'GB', 'TB'][i]}`;
}

export function SampleInsightExportDetailPage(props: { exportId: string }) {
  return (
    <AppPage
      breadcrumbs={[
        { routeName: Router.SampleList(), title: 'Samples' },
        {
          routeName: Router.SampleInsightExportList(),
          title: 'Insight Exports',
        },
      ]}
      title='Export'
    >
      <AppPage.Section>
        <SampleInsightExportDetail exportId={props.exportId} />
      </AppPage.Section>
    </AppPage>
  );
}

function SampleInsightExportDetail(props: { exportId: string }) {
  const {
    data: sampleExport,
    isError,
    error,
  } = useInsightSampleExport(props.exportId);

  if (sampleExport) {
    return <SampleInsightExportDetailContent export={sampleExport} />;
  }

  if (isError) {
    throw error;
  }

  return <Loader variant='bars' />;
}

function SampleInsightExportDetailContent(props: {
  export: InsightSampleExportDetailDTO;
}) {
  const {
    export: { exportResult },
  } = props;
  const [csvObjectUrl, setCsvObjectUrl] = useState<string | null>(null);
  const [blobSize, setBlobSize] = useState<number | null>(null);
  useEffect(() => {
    const csvBlob = new Blob([exportResult.exportCsv], { type: 'text/csv' });
    const objectUrl = window.URL.createObjectURL(csvBlob);
    setBlobSize(csvBlob.size);
    setCsvObjectUrl(objectUrl);
    return () => {
      window.URL.revokeObjectURL(objectUrl);
    };
  }, [exportResult.exportCsv]);

  const sampleStatuses = getSampleStatusMap(exportResult);

  // TODO: Warn the user if the exported samples will contain any that are out of range, or if any samples cannot be exported

  const anyUnexportable = sampleStatuses
    .values()
    .some((s) => s !== 'exportable');
  const anyOld = exportResult.allSamples.some(
    (s) => s.isOutsideDeduplicationWindow,
  );

  return (
    <Stack>
      {anyOld ? (
        <Alert color='orange' variant='filled' title='Potential for Duplicates'>
          <Text>
            This export includes samples older than 30 days. These samples will
            not be checked for duplicates by Insight, and may result in
            duplicates.
          </Text>

          <Text>
            Only upload this CSV to Insight if you are certain that duplicate
            samples will not be created.
          </Text>
        </Alert>
      ) : null}
      {anyUnexportable ? (
        <Alert
          title='Some Samples Cannot Be Exported'
          variant='filled'
          color='red'
        >
          <Text>
            This export includes some samples that cannot be exported. Any
            samples which cannot be exported will be omitted from the CSV.
          </Text>

          <Text>
            All samples must be exportable before the CSV can be downloaded.
          </Text>
        </Alert>
      ) : null}
      <Button
        size='lg'
        color={anyOld ? 'orange' : undefined}
        leftIcon={<IconFileDownload />}
        disabled={anyUnexportable}
        component='a'
        loading={!csvObjectUrl}
        href={csvObjectUrl ?? undefined}
        download={`valis-insight-sample-export-${props.export.id}.csv`}
        rightIcon={<>({humanFileSize(blobSize ?? 0)})</>}
      >
        Download Export CSV
      </Button>
      <Group align='flex-start'>
        <SampleExportTable
          sampleStatuses={sampleStatuses}
          samples={exportResult.allSamples}
        />
        <Stack>
          {exportResult.unanalyzedSamples.length > 0 ? (
            <Alert color='yellow' title='Unanalyzed Samples'>
              The export contains {exportResult.unanalyzedSamples.length}{' '}
              unanalyzed samples which are not included in the CSV. Once they
              are completed, they will be exportable.
            </Alert>
          ) : null}
          {exportResult.noCommoditySamples.length > 0 ? (
            <Alert title='Samples with No Commodity' color='orange'>
              The export period contains{' '}
              {exportResult.noCommoditySamples.length} samples that do not have
              an associated commodity. These samples will not be included in the
              CSV.
            </Alert>
          ) : null}
          {exportResult.noCommodityToInsightSamplingTypeSamples.length > 0 ? (
            <Alert
              title='Commodities with No Insight Sampling Type'
              color='orange'
            >
              <Text>
                {exportResult.noCommodityToInsightSamplingTypeSamples.length}{' '}
                samples are not included in the export because the following
                commodities are missing an Insight sampling type.
              </Text>

              <List>
                {exportResult.noInsightSamplingTypeCommodities.length === 0
                  ? 'none'
                  : exportResult.noInsightSamplingTypeCommodities.map((c) => (
                      <List.Item key={c.id}>{c.name}</List.Item>
                    ))}
              </List>
              <Text fw='bold'>
                Please contact support to update the integration.
              </Text>
            </Alert>
          ) : null}
          {exportResult.noConversionSamples.length > 0 ? (
            <Alert
              color='orange'
              title='Material Class Sets with No Conversion'
            >
              <Text>
                {exportResult.noConversionSamples.length} samples have been
                excluded from the export because their material class set does
                not have a conversion to the Insight material class set.
                Material class sets missing conversion:
              </Text>
              <List>
                {exportResult.noConversionMaterialClassSets.map((mcs) => (
                  <List.Item key={mcs.id}>
                    <LinkText
                      to={Router.MaterialClassSetDetail({
                        materialClassSetId: mcs.id,
                      })}
                    >
                      {mcs.name}
                    </LinkText>
                  </List.Item>
                ))}
              </List>
            </Alert>
          ) : null}
        </Stack>
      </Group>
    </Stack>
  );
}
