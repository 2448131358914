/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InsightSampleExportCreationDTO } from '../models/InsightSampleExportCreationDTO';
import type { InsightSampleExportDetailDTO } from '../models/InsightSampleExportDetailDTO';
import type { InsightSampleExportPreviewDTO } from '../models/InsightSampleExportPreviewDTO';
import type { InsightSampleExportSummaryDTO } from '../models/InsightSampleExportSummaryDTO';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class InsightExportService {
    /**
     * @returns InsightSampleExportPreviewDTO Success
     * @throws ApiError
     */
    public static getExportPreview(): CancelablePromise<InsightSampleExportPreviewDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/riverside/insight/sample-exports/preview',
        });
    }
    /**
     * @returns InsightSampleExportSummaryDTO Success
     * @throws ApiError
     */
    public static getSampleExports(): CancelablePromise<Array<InsightSampleExportSummaryDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/riverside/insight/sample-exports',
        });
    }
    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static createSampleExport(
        requestBody?: InsightSampleExportCreationDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/riverside/insight/sample-exports',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param exportId
     * @returns InsightSampleExportDetailDTO Success
     * @throws ApiError
     */
    public static getSampleExport(
        exportId: string,
    ): CancelablePromise<InsightSampleExportDetailDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/riverside/insight/sample-exports/{exportId}',
            path: {
                'exportId': exportId,
            },
        });
    }
}
