import { Alert, Divider, Flex, Stack, Switch } from '@mantine/core';
import { useState } from 'react';
import {
  RedWaveMaterialClassMetadataDTO,
  RedWaveMaterialClassNumber,
  RedWaveStream,
} from '../rest-client';
import { Composition, Mixture } from '../util/mixture';
import cssClasses from './RedWaveCompositionChartControls.module.css';
import { RedWaveMaterialClassMultiselect } from './RedWaveMaterialClassMultiselect';

export type RedWaveClassMetadata = Record<
  RedWaveMaterialClassNumber,
  RedWaveMaterialClassMetadataDTO
>;

export interface RedWaveChartControlState {
  backgroundClassIncluded: boolean;
  selectedClasses: Set<number>;
  renormalize: boolean;
}

export interface RedWaveCompositionChartControlsProps {
  classMetadata: RedWaveClassMetadata;

  stream?: RedWaveStream;

  value: RedWaveChartControlState;
  onChange: (newState: RedWaveChartControlState) => void;

  minSelected?: number | null;
  maxSelected?: number | null;
}

export function RedWaveCompositionChartControls(
  props: RedWaveCompositionChartControlsProps,
) {
  const {
    classMetadata,
    stream = RedWaveStream.INPUT,
    value,
    onChange,
    minSelected = 1,
    maxSelected,
  } = props;

  const backgroundClassInStream =
    stream === RedWaveStream.INPUT ||
    classMetadata[0].ejected === (stream === RedWaveStream.EJECT);

  const classesInStream = Object.entries(classMetadata).filter(
    ([, m]) =>
      stream === RedWaveStream.INPUT ||
      m.ejected === (stream === RedWaveStream.EJECT),
  );

  if (classesInStream.length === 0) {
    return <Alert color='yellow'>No material classes in this stream.</Alert>;
  }

  const singleClass =
    classesInStream.length === 1 ? classesInStream[0] : undefined;

  if (singleClass) {
    return (
      <RedWaveMaterialClassMultiselect
        classMetadata={classMetadata}
        stream={stream}
        showBackground
        value={new Set([Number(singleClass[0])])}
        onChange={() => {
          throw new Error('unexpected class selection change from single ');
        }}
        minSelected={1}
        maxSelected={1}
      />
    );
  }

  return (
    <Flex
      wrap='nowrap'
      gap='lg'
      className={cssClasses.compositionChartControls}
    >
      <Stack className={cssClasses.backgroundClassControl}>
        {backgroundClassInStream ? (
          <Switch
            label='Include Background Class'
            styles={{ label: { whiteSpace: 'nowrap' } }}
            description='Show background class data which may contain noise'
            checked={value.backgroundClassIncluded}
            disabled={
              // Don't allow disabling the background class if doing so would put us under the min selection limit
              value.selectedClasses.size <=
                (minSelected ?? Number.NEGATIVE_INFINITY) &&
              value.selectedClasses.has(0)
            }
            onChange={(e) => {
              const checked = e.currentTarget.checked;

              const newSelectedClasses = new Set(value.selectedClasses);

              if (checked) {
                if (
                  newSelectedClasses.size <
                  (maxSelected ?? Number.POSITIVE_INFINITY)
                ) {
                  newSelectedClasses.add(0);
                }
              } else {
                newSelectedClasses.delete(0);
              }

              return onChange({
                ...value,
                selectedClasses: new Set(newSelectedClasses),
                backgroundClassIncluded: e.currentTarget.checked,
              });
            }}
          />
        ) : undefined}
        <Switch
          label='Ignore Unselected Classes'
          styles={{ label: { whiteSpace: 'nowrap' } }}
          description='Renormalize the selected classes to 100%'
          checked={value.renormalize}
          onChange={(e) =>
            onChange({ ...value, renormalize: e.currentTarget.checked })
          }
        />
      </Stack>
      <Divider orientation='vertical' size='sm' />
      <RedWaveMaterialClassMultiselect
        classMetadata={classMetadata}
        stream={stream}
        showBackground={
          value.backgroundClassIncluded || !backgroundClassInStream
        }
        value={value.selectedClasses}
        onChange={(selectedClasses) => onChange({ ...value, selectedClasses })}
        minSelected={minSelected}
        maxSelected={maxSelected}
      />
    </Flex>
  );
}

const UNSELECTED_CLASS_NAME = 'Unselected';

export function useRedWaveChartControls(
  classMetadata: RedWaveClassMetadata,
  initialControlState: RedWaveChartControlState,
) {
  const [controlState, setControlState] =
    useState<RedWaveChartControlState>(initialControlState);

  // We use 'Unselected' for grouping unselected classes. To be extra safe we make sure this isn't also a class name in the system
  const conflictingClass = Object.values(classMetadata).find(
    (m) => m.name === UNSELECTED_CLASS_NAME,
  );
  if (conflictingClass !== undefined) {
    throw new Error(
      `RedWave class assumed to not have name of ${UNSELECTED_CLASS_NAME}}, but class ${conflictingClass.id} does`,
    );
  }

  const { backgroundClassIncluded, selectedClasses, renormalize } =
    controlState;

  if (!backgroundClassIncluded && selectedClasses.has(0)) {
    throw new Error(
      'background class selected but also not allowed to be included',
    );
  }

  // Map the class numbers to their names from the metadata and group into unselected if not renormalizing
  const prepareMixture = (classMixture: Mixture<number>) => {
    const m = backgroundClassIncluded
      ? classMixture
      : classMixture.submixture((c) => c !== 0);
    const selectedMixture = m.submixture((c) => selectedClasses.has(c));
    const unselectedMixture = m.submixture((c) => !selectedClasses.has(c));
    const unselectedTotal = unselectedMixture.total();

    const namedEntries: [string, number][] = [
      ...selectedMixture.mapKeys(
        (classNumber) => classMetadata[classNumber].name,
      ),
    ];

    if (!renormalize && classMixture.size > 1) {
      namedEntries.push([UNSELECTED_CLASS_NAME, unselectedTotal]);
    }
    return new Mixture(namedEntries);
  };

  const chartCounts = (classCounts: Mixture<number>) =>
    prepareMixture(classCounts);

  const chartComposition = (
    areaMixture: Mixture<number>,
  ): Composition<string> => prepareMixture(areaMixture).composition();

  return {
    chartComposition,
    chartCounts,
    controlState,
    setControlState,
  };
}
