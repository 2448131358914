import { Flex } from '@mantine/core';
import { useCallback } from 'react';
import { EChart } from '../../echarts/BareEChart';
import { useCategoricalColors } from '../../lib/colors';
import { formatPercentage } from '../../util/percentages';
import { useRecoveryStrategySimulationCtx } from '../RecoveryStrategySimulationContext';

export function SimulationInputComposition() {
  const { simulation, feedTotal } = useRecoveryStrategySimulationCtx();

  const colors = useCategoricalColors();

  const valueFormatter = useCallback(
    (val: number | null) => (val === null ? '?' : val.toFixed(2)),
    [],
  );

  if (simulation.rootMaterialNode === null) {
    return null;
  }

  const inputComposition =
    simulation.inputMaterialClassSetComposition.materialClassesProportions;
  const selectedCompositionTotal = Object.entries(inputComposition)
    .map((materialClassComp) => materialClassComp[1])
    .reduce((acc, value) => acc + value);

  return (
    <Flex>
      <EChart
        h={500}
        w='100%'
        option={{
          grid: { containLabel: true, top: 0, bottom: 0 },
          tooltip: {
            valueFormatter: feedTotal ? valueFormatter : formatPercentage,
          },
          yAxis: {
            type: 'category',
            data: simulation.materialClassSet.materialClasses
              .map((mc) => mc.name)
              .reverse(),
          },
          xAxis: {
            type: 'value',
            axisLabel: {
              formatter: feedTotal ? undefined : formatPercentage,
            },
          },
          series: [
            {
              type: 'bar',
              data: simulation.materialClassSet.materialClasses
                .map((mc, i) => ({
                  value: feedTotal
                    ? feedTotal * inputComposition[mc.id]
                    : inputComposition[mc.id] / selectedCompositionTotal,
                  itemStyle: {
                    color: colors[i],
                  },
                }))
                .reverse(),
            },
          ],
        }}
      />
    </Flex>
  );
}
