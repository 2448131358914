import {
  Alert,
  Box,
  BoxProps,
  Button,
  Stack,
  useMantineColorScheme,
} from '@mantine/core';
import { IconAlertCircle } from '@tabler/icons-react';
import type { SetOptionOpts } from 'echarts/core';
import * as echarts from 'echarts/core';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { ErrorBoundary, FallbackProps } from '../ErrorBoundary';

export interface EChartsInitOpts {
  locale?: string;
  renderer?: 'canvas' | 'svg';
  // TODO(2306): Missing some stuff like ssr, width, height, useDirtyRect, etc
}

export interface DataZoomEvent {
  type: 'datazoom';
  start: number;
  end: number;
}

export interface BareEChartProps {
  initOptions?: EChartsInitOpts;
  theme?: string;

  onDataZoom?: (e: DataZoomEvent) => void;
}

export const BareEChart = forwardRef<
  echarts.ECharts | undefined,
  BareEChartProps
>(function BareEChart(props, ref) {
  const { theme, initOptions } = props;

  const echartsInstanceRef = useRef<echarts.ECharts>();
  const resizeObserverRef = useRef<ResizeObserver>();

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const onContainerRefChange = useCallback(
    (containerDiv: HTMLDivElement | null) => {
      if (containerDiv === null) {
        if (
          echartsInstanceRef.current !== undefined &&
          !echartsInstanceRef.current.isDisposed()
        ) {
          echartsInstanceRef.current.dispose();
        }
        echartsInstanceRef.current = undefined;

        resizeObserverRef.current?.disconnect();
        resizeObserverRef.current = undefined;
      } else {
        if (echartsInstanceRef.current !== undefined) {
          if (!echartsInstanceRef.current.isDisposed()) {
            echartsInstanceRef.current.dispose();
            echartsInstanceRef.current = undefined;
          }
        }

        echartsInstanceRef.current = echarts.init(
          containerDiv,
          theme,
          initOptions,
        );

        // Set up a resize observer
        resizeObserverRef.current = new ResizeObserver(() => {
          echartsInstanceRef.current?.resize();
        });

        resizeObserverRef.current.observe(containerDiv);
      }
    },
    [theme, initOptions, echartsInstanceRef, resizeObserverRef],
  );

  useImperativeHandle(ref, () => echartsInstanceRef.current);

  return (
    <div
      key={theme ?? 'default'}
      style={{ width: '100%', height: '100%' }}
      ref={onContainerRefChange}
    />
  );
});

function EChartFallback({ resetError }: FallbackProps) {
  return (
    <Alert color='red' title='Error' icon={<IconAlertCircle size={16} />}>
      <Stack>
        Something went wrong displaying this chart.
        <Button onClick={resetError}>Try Again</Button>
      </Stack>
    </Alert>
  );
}

export type EChartProps<TOption extends echarts.EChartsCoreOption> = Omit<
  BareEChartProps,
  'theme'
> & {
  option: TOption;
  setOptionOpts?: SetOptionOpts;
} & Omit<BoxProps, 'children' | 'component'>;
export const EChart = function EChart<
  TOption extends echarts.EChartsCoreOption,
>(props: EChartProps<TOption>) {
  const { option, initOptions, setOptionOpts, onDataZoom, ...boxProps } = props;
  const echartsInstanceRef = useRef<echarts.ECharts>(null);
  const { colorScheme } = useMantineColorScheme();
  const theme = colorScheme === 'dark' ? 'dark-bold' : 'light-bold';

  // TODO(2306): Document that this requires memoization of callbacks
  useDeepCompareEffect(() => {
    echartsInstanceRef.current?.setOption(option, setOptionOpts);
  }, [option, setOptionOpts, echartsInstanceRef.current]);

  // TODO(2306): Factor this into its own hook before adding other events
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (!echartsInstanceRef.current) return;
    if (!onDataZoom) return;

    const instance = echartsInstanceRef.current;

    const datazoomCallback = (e: unknown) => onDataZoom(e as DataZoomEvent);

    instance.on('datazoom', datazoomCallback);
    return () => {
      instance.off('datazoom', datazoomCallback);
    };
  }, [onDataZoom, echartsInstanceRef]);

  return (
    <Box component='div' {...boxProps}>
      <ErrorBoundary fallback={EChartFallback}>
        <BareEChart
          ref={echartsInstanceRef}
          initOptions={initOptions}
          theme={theme}
        />
      </ErrorBoundary>
    </Box>
  );
};
