import { createGroup, createRouter } from '@swan-io/chicane';

const Routes = {
  Home: '/?:initialGridState' as const, // initialGridState is Base64URL encoded ag-grid state
  ...createGroup('FacilityInsights', '/facility-insights', {
    Area: '/*',
    Dashboard: '/?:dateRange&:startDate&:endDate',
    MetricDetail: '/metric/:metricName?:dateRange&:startDate&:endDate',
    MetricFacetDetail:
      '/metric/:metricName/:facetName?:dateRange&:startDate&:endDate',
  }),
  ...createGroup('Process', '/processes', {
    Area: '/*',
    List: '/',
    Detail: '/:processId',
    ConfigDetail: '/:processId/:processConfigId',
    CreateProcessConfig: '/:processId/process-configs/create',
    EditProcessConfig: '/:processId/:processConfigId/edit',
  }),
  ...createGroup('ProcessRun', '/process-runs', {
    Area: '/*',
    Detail: '/:processRunId?:tab',
    Complete: '/:processRunId/complete',
    Add: '/create?:processId&:start&:end&:sourceRepositoryId',
    Edit: '/:processRunId/edit',
    ...createGroup('StreamTransfer', '/:processRunId/stream-transfers', {
      Add: '/:outputPortId/create',
      Edit: '/:streamTransferId/edit',
    }),
  }),
  ...createGroup('FeedFlowGroup', '/feed-flow-groups', {
    Area: '/*',
    Detail: '/:feedFlowGroupId?:tab',
  }),
  ...createGroup('SortSystem', '/sort-systems', {
    Area: '/*',
    List: '/',
    Detail: '/:sortSystemId',
    ...createGroup('RedWave', '/redwave', {
      SortProgramDetail:
        '/:sortSystemId/sort-programs/:sortProgramNumber?:timestamp',
    }),
  }),
  ...createGroup('RecoveryGoal', '/recovery-goals', {
    Area: '/*',
    List: '/',
    Detail: '/:recoveryGoalId',
    Create: '/create',
    Edit: '/:recoveryGoalId/edit',
  }),
  ...createGroup('RecoveryStrategy', '/recovery-strategies', {
    Area: '/*',
    List: '/',
    Detail: '/:recoveryStrategyId',
  }),
  ...createGroup(
    'RecoveryStrategySimulation',
    '/recovery-strategy-simulations',
    {
      Area: '/*',
      List: '/',
      Detail: '/:simulationId',
    },
  ),
  ...createGroup('Ledger', '/ledger', {
    History: '/?:date&:range&:entryId',
  }),
  ...createGroup('Production', '/production', {
    Area: '/*',
    History: '/history?:processId&:after&:before',
    Daily: '/daily?:date&:processId&:productionIntervalId',
    IntervalStatus: '/intervals/:productionIntervalId',
  }),
  ...createGroup('Container', '/containers', {
    Area: '/*',
    List: '/',
    Detail: '/:containerId?:timestamp',
    DetailProcessRunAdd: '/:containerId/add-process-run',
    ...createGroup('Transfer', '/transfers', {
      Edit: '/:transferId/edit',
    }),
  }),
  ...createGroup('TruckLoad', '/truck-loads', {
    Area: '/*',
    List: '/',
    Detail: '/:truckLoadId',
    Add: '/add',
    ...createGroup('Transfer', '/transfers', {
      Add: '/add',
    }),
  }),
  ...createGroup('InternalSource', '/internal-sources', {
    Area: '/*',
    List: '/',
    Detail: '/:internalMaterialSourceId',
    Create: '/create',
    Edit: '/:internalMaterialSourceId/edit',
    ...createGroup('Materials', '/:internalMaterialSourceId/materials', {
      MaterialDetail: '/:internallySourcedMaterialId',
    }),
  }),
  ...createGroup('InternalSink', '/material-export-destinations', {
    Area: '/*',
    List: '/',
    Detail: '/:internalMaterialSinkId',
    Add: '/add',
    Edit: '/:internalMaterialSinkId/edit',
    TransferEdit: '/exports/:transferId/edit',
    TransferDetail: '/exports/:sinkTransferId',
  }),
  ...createGroup('Vendor', '/vendors', {
    Area: '/*',
    List: '/',
    Detail: '/:vendorId',
    Create: '/create',
    Edit: '/:vendorId/edit',
    ...createGroup('PurchasedMaterial', '/:vendorId/purchased-materials', {
      Detail: '/:purchasedMaterialId',
      Create: '/create',
    }),
  }),
  ...createGroup('Customer', '/customers', {
    Area: '/*',
    List: '/',
    Detail: '/:customerId',
    ...createGroup('SoldMaterials', '/:customerId/sold-materials', {
      Create: '/create',
      Detail: '/:soldMaterialId',
    }),
  }),
  ...createGroup('Commodity', '/commodities', {
    Area: '/*',
    List: '/',
    Detail: '/:commodityId',
  }),
  ...createGroup('MaterialClass', '/material-classes', {
    Area: '/*',
    List: '/',
    Detail: '/:materialClassId',
    Create: '/create',
    Edit: '/:materialClassId/edit',
  }),
  ...createGroup('MaterialClassSet', '/material-class-sets', {
    Area: '/*',
    Detail: '/:materialClassSetId',
    Create: '/create',
  }),
  ...createGroup(
    'MaterialClassSetConversion',
    '/material-class-set-conversions',
    {
      Detail: '/:sourceMaterialClassSetId/:targetMaterialClassSetId',
    },
  ),
  ...createGroup('Sample', '/samples', {
    Area: '/*',
    List: '/',
    Detail: '/:sampleId',
    CreateContainer: '/create-container?:containerId&:timestamp',
    CreateNotional:
      '/create?:timestamp&:commodityId&:internalMaterialSourceId&:processId&:recoveryStrategyId&:internalMaterialSinkId',
    ...createGroup('InsightExport', '/insight-exports', {
      List: '/',
      Detail: '/:exportId',
    }),
  }),
  ...createGroup('SamplingSession', '/sampling-sessions', {
    Area: '/*',
    Session: '/:sessionId',
  }),
  ...createGroup('Schupan', '/schupan', {
    Area: '/*',
    ...createGroup('Rimas', '/rimas', {
      Area: '/*',
      Report: '/report',
      Snapshots: '/snapshots',
    }),
    DailyProduction: '/daily-production-reports',
  }),
};
export const Router = createRouter(Routes);
