import { BarChart } from 'echarts/charts';
import { GridComponent } from 'echarts/components';
import * as echarts from 'echarts/core';
import { useCallback } from 'react';
import cssClasses from './CompositionChart.module.css';
import { EChart } from './echarts/BareEChart';
import { Composition } from './util/mixture';

echarts.use([BarChart, GridComponent]);

export function CompositionChart(props: {
  composition: Composition<string>;
  rotateXAxisLabels?: boolean;
  title?: string;
  xAxisText?: string;
  yAxisText?: string;
}) {
  const {
    composition,
    title,
    rotateXAxisLabels = false,
    xAxisText,
    yAxisText,
  } = props;

  const keySet = [...composition.keys()];

  const percentageFormatter = useCallback(
    (val: number | null) =>
      `${val === null ? 'unknown ' : (val * 100).toFixed(1)}%`,
    [],
  );

  const percentageValueFormatter = useCallback(
    (p: { value: number }) => `${(p.value * 100).toFixed(1)}%`,
    [],
  );

  return (
    <EChart
      className={cssClasses.compositionChart}
      option={{
        title: {
          text: title,
        },
        grid: {
          bottom: 80,
        },
        xAxis: {
          name: xAxisText,
          type: 'category',
          data: keySet,
          nameLocation: 'middle',
          nameTextStyle: {
            lineHeight: 40,
          },
          axisLabel: {
            interval: rotateXAxisLabels && 0,
            rotate: rotateXAxisLabels && 45,
          },
        },
        yAxis: {
          name: yAxisText,
          type: 'value',
          axisLabel: {
            formatter: percentageFormatter,
          },
          nameLocation: 'middle',
          nameTextStyle: {
            lineHeight: 100,
          },
        },
        series: [
          {
            type: 'bar',
            colorBy: 'data',
            data: keySet.map((k) => composition.get(k)),
            label: {
              show: true,
              position: 'top',
              formatter: percentageValueFormatter,
            },
          },
        ],
      }}
    />
  );
}
