import { createStyles, Flex, Group, Paper, Stack, Text } from '@mantine/core';
import { Handle, NodeProps, Position } from 'reactflow';
import { match, P } from 'ts-pattern';
import { CommodityName } from '../Commodity/CommodityName';
import { RecoveryGoalName } from '../RecoveryGoal/RecoveryGoalName';
import {
  BasicMaterialNodeDTO,
  BasicRecoveryGoalNodeDTO,
  CommoditySpreadMaterialNodeDTO,
  CommoditySpreadRecoveryGoalNodeDTO,
  DefaultCommodityAssignmentMaterialNodeDTO,
  DefaultCommodityAssignmentRecoveryGoalNodeDTO,
} from '../rest-client';
import { BasicMaterialNodeBody } from './BasicRecoveryTreeNodes';
import {
  CommoditySpreadMaterialNodeBody,
  CommoditySpreadRecoveryNodeBody,
} from './CommoditySpreadRecoveryTreeNodes';
import { DefaultCommodityAssignmentMaterialNodeBody } from './DefaultCommodityAssignmentRecoveryTreeNodes';
import {
  MaterialNodeData,
  RecoveryNodeData,
  RecoveryTreeNodeVariant,
} from './RecoveryTreeGraph';

interface NodeStyleParams {
  selected: boolean;
  zIndex: number;
}
const useNodeStyles = createStyles<
  'leafOrRootMaterial' | 'intermediateMaterial' | 'recovery',
  NodeStyleParams
>((theme, _params) => {
  const { zIndex, selected } = _params;
  const isDark = theme.colorScheme === 'dark';

  const selectedBorderColor = theme.colors.teal[isDark ? 5 : 6];

  const nodeStyles = {
    zIndex,
    border: selected ? `3px solid ${selectedBorderColor}` : undefined,
  };

  const leafOrRootStyles = {
    background: isDark
      ? theme.colors.teal[selected ? 8 : 6]
      : theme.colors.teal[selected ? 1 : 0],
    ...nodeStyles,
  };
  const intermediateMaterialStyles = {
    background: isDark
      ? theme.colors.orange[selected ? 8 : 6]
      : theme.colors.orange[selected ? 1 : 0],
    ...nodeStyles,
  };
  const recoveryStyles = {
    background: isDark
      ? theme.colors.gray[selected ? 8 : 6]
      : theme.colors.gray[selected ? 1 : 0],
    ...nodeStyles,
  };

  return {
    leafOrRootMaterial: {
      ...leafOrRootStyles,
    },
    intermediateMaterial: {
      ...intermediateMaterialStyles,
    },
    recovery: {
      ...recoveryStyles,
    },
  };
});

export type RecoveryTreeNodeDTO =
  | ({
      type: 'recovery';
    } & RecoveryGoalNodeDTO)
  | ({
      type: 'material';
    } & MaterialNodeDTO);

export type RecoveryGoalNodeDTO =
  | BasicRecoveryGoalNodeDTO
  | CommoditySpreadRecoveryGoalNodeDTO
  | DefaultCommodityAssignmentRecoveryGoalNodeDTO;

export type RecoveryGoalNodeProps<
  TRecoveryGoalNodeDTO extends RecoveryGoalNodeDTO,
> = {
  recoveryGoalNodeDto: TRecoveryGoalNodeDTO;
  dtoToBodyComponent: (
    recoveryGoalNodeDto: TRecoveryGoalNodeDTO,
  ) => JSX.Element;
  dtoToLabelComponent: (
    recoveryGoalNodeDto: TRecoveryGoalNodeDTO,
  ) => JSX.Element;
  nodeProps: NodeProps<RecoveryNodeData>;
};
export function RecoveryNode<TRecoveryGoalNodeDTO extends RecoveryGoalNodeDTO>(
  props: RecoveryGoalNodeProps<TRecoveryGoalNodeDTO>,
) {
  const {
    recoveryGoalNodeDto,
    dtoToBodyComponent,
    dtoToLabelComponent,
    nodeProps,
  } = props;
  const { classes } = useNodeStyles({
    zIndex: nodeProps.zIndex,
    selected: nodeProps.selected,
  });

  return (
    <Paper className={classes.recovery} p='sm' shadow='md'>
      {recoveryGoalNodeDto.index !== 0 && (
        <Handle
          id={Math.floor((recoveryGoalNodeDto.index - 1) / 2).toString()}
          type='target'
          position={Position.Top}
        />
      )}
      <Stack justify={'center'} align='center' w='100%'>
        {dtoToLabelComponent(recoveryGoalNodeDto)}
        <Flex align='start' gap='xs'>
          {dtoToBodyComponent(recoveryGoalNodeDto)}
        </Flex>
      </Stack>
      <>
        <Handle
          id={(2 * recoveryGoalNodeDto.index + 1).toString()}
          type='source'
          position={Position.Bottom}
        />
        <Handle
          id={(2 * recoveryGoalNodeDto.index + 2).toString()}
          type='source'
          position={Position.Bottom}
        />
      </>
    </Paper>
  );
}

export type MaterialNodeDTO =
  | BasicMaterialNodeDTO
  | CommoditySpreadMaterialNodeDTO
  | DefaultCommodityAssignmentMaterialNodeDTO;

type MaterialNodeProps<TMaterialNodeDTO extends MaterialNodeDTO> = {
  materialNodeDto: TMaterialNodeDTO;
  dtoToLabelComponent: (materialNodeDto: TMaterialNodeDTO) => JSX.Element;
  dtoToBodyComponent: (materialNodeDto: TMaterialNodeDTO) => JSX.Element;
  nodeProps: NodeProps<MaterialNodeData>;
};

export function MaterialNode<TMaterialNodeDTO extends MaterialNodeDTO>(
  props: MaterialNodeProps<TMaterialNodeDTO>,
) {
  const {
    materialNodeDto,
    dtoToLabelComponent,
    dtoToBodyComponent,
    nodeProps,
  } = props;
  const { classes } = useNodeStyles({
    zIndex: nodeProps.zIndex,
    selected: nodeProps.selected,
  });

  return (
    <Paper
      className={
        materialNodeDto.consumingRecoveryGoalNode === null ||
        materialNodeDto.index === 0
          ? classes.leafOrRootMaterial
          : classes.intermediateMaterial
      }
      p='sm'
      shadow='md'
    >
      {materialNodeDto.index !== 0 && (
        <Handle
          id={Math.floor((materialNodeDto.index - 1) / 2).toString()}
          type='target'
          position={Position.Top}
        />
      )}
      <Stack justify={'center'} align='center' w='100%'>
        {dtoToLabelComponent(materialNodeDto)}
        <Group>{dtoToBodyComponent(materialNodeDto)}</Group>
      </Stack>
      {materialNodeDto.consumingRecoveryGoalNode !== null && (
        <Handle
          id={(2 * materialNodeDto.index + 1).toString()}
          type='source'
          position={Position.Bottom}
        />
      )}
    </Paper>
  );
}

export function RecoveryNodeLabel(recoveryGoalNodeDto: RecoveryGoalNodeDTO) {
  return (
    <RecoveryGoalName
      recoveryGoal={recoveryGoalNodeDto.recoveryGoal}
      noLink
      withIcon
    />
  );
}

export function CommodityMaterialNodeLabel(
  materialNodeDto:
    | CommoditySpreadMaterialNodeDTO
    | DefaultCommodityAssignmentMaterialNodeDTO,
) {
  return (
    <Text>
      {match(materialNodeDto)
        .with({ commodity: P.nonNullable }, (nodeWithKnownCommodity) => (
          <CommodityName
            commodity={nodeWithKnownCommodity.commodity}
            noLink
            withIcon
          />
        ))
        .with(
          {
            commodity: null,
            consumingRecoveryGoalNode: null,
          },
          () => <Text>Output Product</Text>,
        )
        .with(
          {
            commodity: null,
            consumingRecoveryGoalNode: P.nonNullable,
            index: 0,
          },
          () => <Text>Feedstock</Text>,
        )
        .with(
          {
            commodity: null,
            consumingRecoveryGoalNode: P.nonNullable,
            index: P.number.positive(),
          },
          () => <Text c='dimmed'>Intermediate Product</Text>,
        )
        .otherwise(() => (
          <Text c='dimmed'>Unknown</Text>
        ))}
    </Text>
  );
}

export function RecoveryTreeDiagramMaterialNode(
  props: NodeProps<MaterialNodeData>,
) {
  return match(props.data.variant)
    .with(RecoveryTreeNodeVariant.commoditySpread, () => (
      <MaterialNode
        materialNodeDto={
          props.data.materialNode as CommoditySpreadMaterialNodeDTO
        }
        dtoToLabelComponent={CommodityMaterialNodeLabel}
        dtoToBodyComponent={CommoditySpreadMaterialNodeBody}
        nodeProps={props}
      />
    ))
    .with(RecoveryTreeNodeVariant.basic, () => (
      <MaterialNode
        materialNodeDto={props.data.materialNode as BasicMaterialNodeDTO}
        dtoToLabelComponent={() => <></>}
        dtoToBodyComponent={BasicMaterialNodeBody}
        nodeProps={props}
      />
    ))
    .with(RecoveryTreeNodeVariant.defaultCommodityAssignment, () => (
      <MaterialNode
        materialNodeDto={
          props.data.materialNode as DefaultCommodityAssignmentMaterialNodeDTO
        }
        dtoToLabelComponent={CommodityMaterialNodeLabel}
        dtoToBodyComponent={DefaultCommodityAssignmentMaterialNodeBody}
        nodeProps={props}
      />
    ))
    .exhaustive();
}

export function RecoveryTreeDiagramRecoveryNode(
  props: NodeProps<RecoveryNodeData>,
) {
  return match(props.data.variant)
    .with(RecoveryTreeNodeVariant.commoditySpread, () => (
      <RecoveryNode
        recoveryGoalNodeDto={
          props.data.recoveryGoalNode as CommoditySpreadRecoveryGoalNodeDTO
        }
        dtoToBodyComponent={CommoditySpreadRecoveryNodeBody}
        dtoToLabelComponent={RecoveryNodeLabel}
        nodeProps={props}
      />
    ))
    .with(RecoveryTreeNodeVariant.basic, () => (
      <RecoveryNode
        recoveryGoalNodeDto={
          props.data.recoveryGoalNode as BasicRecoveryGoalNodeDTO
        }
        dtoToLabelComponent={() => <></>}
        dtoToBodyComponent={RecoveryNodeLabel}
        nodeProps={props}
      />
    ))
    .with(RecoveryTreeNodeVariant.defaultCommodityAssignment, () => (
      <RecoveryNode
        recoveryGoalNodeDto={
          props.data
            .recoveryGoalNode as DefaultCommodityAssignmentRecoveryGoalNodeDTO
        }
        dtoToLabelComponent={() => <></>}
        dtoToBodyComponent={RecoveryNodeLabel}
        nodeProps={props}
      />
    ))
    .exhaustive();
}
