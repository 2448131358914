import { Grid, Loader, useMantineTheme } from '@mantine/core';
import dayjs from 'dayjs';
import { BarChart, GaugeChart, HeatmapChart, LineChart } from 'echarts/charts';
import {
  DatasetComponent,
  GridComponent,
  TitleComponent,
  TooltipComponent,
  VisualMapComponent,
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { useCallback } from 'react';
import Temporal from '../../../Temporal/temporal.ts';
import {
  useRedWaveSystemMetrics,
  useRedWaveTelemetry,
} from '../../../api/redWave';
import { EChart } from '../../../echarts/BareEChart';
import { ProcessDTO, RedWaveSortSystemDTO } from '../../../rest-client';
import cssClasses from './RedWaveSystemMetrics.module.css';

echarts.use([
  LineChart,
  BarChart,
  HeatmapChart,
  GridComponent,
  TooltipComponent,
  TitleComponent,
  VisualMapComponent,
  DatasetComponent,
  GaugeChart,
]);

export function RedWaveSystemMetrics(props: {
  system: RedWaveSortSystemDTO;
  process: ProcessDTO;
  startTime: Temporal.Instant;
  endTime?: Temporal.Instant;
}) {
  const { system, startTime, endTime } = props;

  const start = dayjs.utc(startTime.epochMilliseconds);
  const end = endTime ? dayjs.utc(endTime.epochMilliseconds) : undefined;
  const telemetryQuery = useRedWaveTelemetry(system.id, start, end);
  const systemMetricsQuery = useRedWaveSystemMetrics(
    system.id,
    start,
    end ?? null,
  );

  if (telemetryQuery.isLoadingError) {
    throw telemetryQuery.error;
  }

  if (systemMetricsQuery.isLoadingError) {
    throw systemMetricsQuery.error;
  }

  if (telemetryQuery.isLoading) {
    return <Loader />;
  }

  if (systemMetricsQuery.isLoading) {
    return <Loader />;
  }

  const telemetry = telemetryQuery.data;
  const metrics = systemMetricsQuery.data;

  const materialClassObjectCountPerMinuteArrays = Object.values(
    telemetry.differentialTelemetry.materialClassObjectsPerMinute,
  );
  const objectsPerMinute = materialClassObjectCountPerMinuteArrays[0].map(
    (_, i) =>
      materialClassObjectCountPerMinuteArrays.reduce(
        (total, clsCounts) => total + clsCounts[i],
        0,
      ),
  );

  // TODO (2309): Valve pressures
  return (
    <Grid gutter={'xs'}>
      <Grid.Col span={12}>
        <RedWaveValveActivityHeatmapChart
          timestamps={telemetry.differentialTelemetry.timestamps}
          valveActivity={telemetry.differentialTelemetry.valveActivity}
          cumulativeValveActivity={telemetry.cumulativeTelemetry.valveActivity}
        />
      </Grid.Col>

      <Grid.Col span={8}>
        <RedWaveObjectRateChart
          timestamps={telemetry.differentialTelemetry.timestamps}
          objectsPerMinute={objectsPerMinute}
        />
      </Grid.Col>

      <Grid.Col span={4} />
      {metrics.airPressuresBars.at(-1) && (
        <>
          <Grid.Col span={8}>
            <RedWaveValvePressureTimeSeriesChart
              timestamps={metrics.timestamps}
              airPressuresBars={metrics.airPressuresBars}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <RedWaveValvePressureGaugeChart
              pressureBars={metrics.airPressuresBars.at(-1) ?? 0}
            />
          </Grid.Col>{' '}
        </>
      )}
    </Grid>
  );
}

export function RedWaveObjectRateChart(props: {
  timestamps: string[];
  objectsPerMinute: number[];
}) {
  const { timestamps, objectsPerMinute } = props;

  const maxObjectsPerMinuteDigits = Math.max(...objectsPerMinute).toString()
    .length;
  return (
    <EChart
      className={cssClasses.fixedHeightChart}
      option={{
        title: {
          text: 'Particle Throughput',
        },
        dataset: {
          source: {
            timestamps,
            objectCounts: objectsPerMinute,
          },
        },
        xAxis: {
          name: 'Time (hh:mm)',
          type: 'time',
          nameLocation: 'middle',
          nameTextStyle: {
            lineHeight: 40,
          },
        },
        yAxis: {
          name: 'Throughput (particles / min)',
          type: 'value',
          nameLocation: 'middle',
          nameTextStyle: {
            lineHeight: 40 + 12 * maxObjectsPerMinuteDigits,
          },
        },
        series: [
          {
            type: 'line',
            encode: { x: 'timestamps', y: 'objectCounts' },
            name: 'Particles per minute',
            showSymbol: false,
          },
        ],
        tooltip: {
          trigger: 'axis',
        },
        grid: {
          right: 0,
        },
      }}
    />
  );
}

export function RedWaveValveActivityHeatmapChart(props: {
  timestamps: string[];
  valveActivity: number[][];
  cumulativeValveActivity: number[];
}) {
  const { timestamps, valveActivity, cumulativeValveActivity } = props;

  // valve activity is an array where each item is the values for a single valve region
  // we need to transform into tuples [x, y, activity]
  let maxActivity = 0;
  const data = valveActivity.flatMap((activityArray, valveRegionIdx) =>
    activityArray.map((activity, i) => {
      maxActivity = Math.max(maxActivity, activity);
      return [timestamps[i], valveRegionIdx, activity];
    }),
  );

  const maxTotalActivity = Math.max(...cumulativeValveActivity);

  return (
    <EChart
      className={cssClasses.fixedHeightChart}
      option={{
        title: {
          text: 'Valve Activity',
        },
        xAxis: [
          {
            type: 'category',
            data: timestamps,
            show: false,
            gridIndex: 0,
          },
          {
            type: 'value',
            gridIndex: 1,
          },
        ],
        yAxis: [
          {
            type: 'category',
            data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
            gridIndex: 0,
          },
          {
            type: 'category',
            data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
            gridIndex: 1,
          },
        ],
        visualMap: [
          {
            type: 'continuous',
            min: 0,
            max: maxActivity,
            calculable: false,
            show: false,
            seriesIndex: 0,
          },
          {
            type: 'continuous',
            min: 0,
            max: maxTotalActivity,
            calculable: false,
            show: false,
            seriesIndex: 1,
            dimension: 0,
          },
        ],
        series: [
          {
            type: 'heatmap',
            data,
            xAxisIndex: 0,
            yAxisIndex: 0,
          },
          {
            type: 'bar',
            data: cumulativeValveActivity,
            barCategoryGap: 0,
            xAxisIndex: 1,
            yAxisIndex: 1,
          },
        ],
        tooltip: {},

        grid: [
          { width: 'auto', left: 30, right: '33%' },
          { width: 'auto', left: '70%', right: 20 },
        ],
      }}
    />
  );
}

export function useAirPressureLimits() {
  const { colors } = useMantineTheme();
  return new Map([
    [4.5, colors.red[7]],
    [6, colors.yellow[6]],
    [100, colors.green[6]],
  ]);
}

export function RedWaveValvePressureGaugeChart(props: {
  pressureBars: number;
}) {
  const airPressureLimits = useAirPressureLimits();
  const { pressureBars } = props;

  const maxPressureBars = 10;

  return (
    <EChart
      className={cssClasses.pressureGaugeChart}
      option={{
        series: [
          {
            type: 'gauge',
            radius: '90%',
            min: 0,
            max: maxPressureBars,
            axisLine: {
              lineStyle: {
                color: Array.from(airPressureLimits.entries()).map(
                  ([limit, color]) => [limit / maxPressureBars, color],
                ),
              },
            },
            pointer: {
              itemStyle: {
                color: 'inherit',
              },
            },

            detail: {
              formatter: '{value} bar',
              color: 'inherit',
            },
            data: [{ value: pressureBars.toFixed(2), name: 'Latest Pressure' }],
          },
        ],
      }}
    />
  );
}

export function RedWaveValvePressureTimeSeriesChart(props: {
  airPressuresBars: number[];
  timestamps: string[];
}) {
  const { airPressuresBars, timestamps } = props;
  const airPressureLimits = useAirPressureLimits();

  const valueFormatter = useCallback((value: number) => `${value} bar`, []);

  return (
    <EChart
      className={cssClasses.pressureGaugeTimeSeriesChart}
      option={{
        title: {
          text: 'Air Pressure',
        },
        dataset: {
          source: {
            timestamps,
            airPressuresBars,
          },
        },
        xAxis: {
          type: 'time',
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: valueFormatter,
          },
        },
        visualMap: [
          {
            type: 'piecewise',
            show: false,
            dimension: 1,
            pieces: Array.from(airPressureLimits.entries()).map(
              ([limit, color]) => ({ max: limit, color }),
            ),
          },
        ],
        series: [
          {
            type: 'line',
            encode: { x: 'timestamps', y: 'airPressuresBars' },
            name: 'Air Pressure',
            showSymbol: false,
            smooth: true,
            areaStyle: {
              opacity: 0.1,
            },
          },
        ],
        tooltip: {
          trigger: 'axis',
          valueFormatter: valueFormatter,
        },
        grid: {
          right: 0,
        },
      }}
    />
  );
}
