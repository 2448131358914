import {
  ActionIcon,
  Alert,
  Button,
  Checkbox,
  Container,
  Flex,
  Group,
  Input,
  List,
  Modal,
  NumberInput,
  SegmentedControl,
  Select,
  Skeleton,
  Stack,
  Table,
  Tabs,
  Text,
} from '@mantine/core';
import { DatePickerInput, DatesRangeValue } from '@mantine/dates';
import { useDisclosure } from '@mantine/hooks';
import { IconRefresh } from '@tabler/icons-react';
import { useCallback, useState } from 'react';
import { match, P } from 'ts-pattern';
import { useCommodities } from '../../api/commodity';
import {
  useInternalMaterialSourceComposition,
  useInternalMaterialSources,
} from '../../api/internalMaterialSource';
import { usePatchRecoveryStrategySimulation } from '../../api/recoveryStrategySimulation';
import { CommodityName } from '../../Commodity/CommodityName';
import { LabeledValue } from '../../common';
import { EditIcon, InternalMaterialSourceIcon } from '../../Icons';
import { InternalMaterialSourceName } from '../../InternalMaterialSource/InternalMaterialSourceName';
import { RecoveryGoalPathName } from '../../RecoveryGoal/RecoveryGoalPathName';
import { InternallySourcedMaterialName } from '../../Repository/RepositoryName';
import {
  FeedstockParametersPatchDTO,
  InternallySourcedMaterialCompositionDTO,
  InternalMaterialSourceBulkCompositionAnalysisResultDTO,
  InternalMaterialSourceDTO,
  InternalMaterialSourceId,
  MaterialClassSetDTO,
  RecoveryPathPriceAssignmentItemDTO,
  RecoveryStrategySimulationId,
  RecoveryStrategySimulationPatchDTO,
  WeightUnit,
} from '../../rest-client';
import { getWeightFromNetWeight } from '../../util/weightFromNetWeight';
import { weightUnitShorthand } from '../../util/weightUnitShorthand';
import {
  InputCompositionInternalMaterialSourceVariant,
  InputCompositionSource,
  RecoveryStrategySimulationCompositionCtxProvider,
  useRecoveryStrategySimulationCompositionCtx,
} from '../RecoveryStrategySimulationCompositionContext';
import { useRecoveryStrategySimulationCtx } from '../RecoveryStrategySimulationContext';
import { SimulationInputComposition } from './SimulationFeedstockComposition';
import classes from './SimulationParameters.module.css';
import { SimulationPerformanceParametersInput } from './SimulationPerformanceParametersInput';

export function SimulationParameterTabs() {
  const [activeTab, setActiveTab] = useState<string | null>('feedstock');

  return (
    <Tabs value={activeTab} onTabChange={setActiveTab} variant='outline'>
      <Tabs.List>
        <Tabs.Tab value='feedstock'>Feedstock</Tabs.Tab>
        <Tabs.Tab value='performance'>Recovery Performance</Tabs.Tab>
        <Tabs.Tab value='pricing'>Commodity Prices</Tabs.Tab>
      </Tabs.List>
      <Tabs.Panel value='feedstock'>
        <SimulationFeedstockTab />
      </Tabs.Panel>
      <Tabs.Panel value='performance'>
        <Container mt='lg'>
          <SimulationPerformanceParametersInput />
        </Container>
      </Tabs.Panel>
      <Tabs.Panel value='pricing'>
        <RecoveryPathAssignmentsInputTable />
      </Tabs.Panel>
    </Tabs>
  );
}

function RecoveryPathAssignmentsInputTable() {
  const { simulation } = useRecoveryStrategySimulationCtx();

  if (simulation.recoveryPathPriceAssignments === null) {
    return (
      <Alert color='orange' title='Invalid Recovery Strategy'>
        You cannot manually spcify prices or load recent commodity spot prices
        for the materials in this simulation because the recovery strategy is
        considered invalid. Please update the recovery strategy.
      </Alert>
    );
  }

  const { feedstock, intermediates, outputs } =
    simulation.recoveryPathPriceAssignments;

  return (
    <Table>
      <thead>
        <tr>
          <th>Product Type</th>
          <th>Commodity</th>
          <th>Recovery Path</th>
          <th>Price</th>
        </tr>
      </thead>
      <tbody>
        <RecoveryPathAssignmentInputTableRow
          recoveryPathAssignment={feedstock}
          productType='Feedstock'
          simulationId={simulation.id}
        />
        {intermediates.map((intermediate, idx) => (
          <RecoveryPathAssignmentInputTableRow
            key={idx}
            recoveryPathAssignment={intermediate}
            productType='Intermediate'
            simulationId={simulation.id}
          />
        ))}
        {outputs.map((intermediate, idx) => (
          <RecoveryPathAssignmentInputTableRow
            key={idx}
            recoveryPathAssignment={intermediate}
            productType='Output'
            simulationId={simulation.id}
          />
        ))}
      </tbody>
    </Table>
  );
}

function RecoveryPathAssignmentInputTableRow(props: {
  recoveryPathAssignment: RecoveryPathPriceAssignmentItemDTO;
  productType: 'Feedstock' | 'Intermediate' | 'Output';
  simulationId: RecoveryStrategySimulationId;
}) {
  const { recoveryPathAssignment, productType, simulationId } = props;
  const { steps, commodity, pricePerWeightUnit, weightUnit } =
    recoveryPathAssignment;

  const [priceValue, setPriceValue] = useState<number | null>(
    pricePerWeightUnit,
  );
  const patchMutation = usePatchRecoveryStrategySimulation();
  const mutate = useCallback(() => {
    if (priceValue === null) return;
    return patchMutation.mutate({
      simulationId: simulationId,
      patch:
        productType === 'Feedstock'
          ? {
              feedstockParameters: { feedstockMassUsdPerUnit: priceValue },
            }
          : {
              simulationRecoveryPathPriceAssignments: [
                {
                  negativePath: recoveryPathAssignment.steps.map(
                    (step) => step.negative,
                  ),
                  price: priceValue,
                  weightUnit: weightUnit,
                },
              ],
            },
    });
  }, [
    priceValue,
    patchMutation,
    simulationId,
    productType,
    recoveryPathAssignment.steps,
    weightUnit,
  ]);

  return (
    <tr>
      <td>{productType}</td>
      <td>
        {commodity !== null ? (
          <CommodityName commodity={commodity} />
        ) : (
          <Text c='dimmed'>Unknown</Text>
        )}
      </td>
      <td>
        <RecoveryGoalPathName steps={steps} />
      </td>

      <td>
        <NumberInput
          value={priceValue ?? ''}
          onChange={(v) => setPriceValue(v === '' ? null : v)}
          onBlur={() => mutate()}
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              mutate();
            }
          }}
          precision={2}
          w='5rem'
          rightSectionWidth='4ch'
          rightSection={
            <Text c='dimmed'>{`$/${weightUnitShorthand(weightUnit)}`}</Text>
          }
        />
      </td>
    </tr>
  );
}

function SimulationFeedstockTab() {
  const { simulation, feedTotal, setFeedTotal } =
    useRecoveryStrategySimulationCtx();

  const patchMutation = usePatchRecoveryStrategySimulation();

  const mutateFeedstockMass = useCallback(() => {
    const feedstockParametersPatch: FeedstockParametersPatchDTO = {
      feedstockMass: feedTotal ?? undefined,
      feedstockMassUnit: WeightUnit.POUND,
      feedstockMassUsdPerUnit: undefined,
    };
    const simulationPatch: RecoveryStrategySimulationPatchDTO = {
      feedstockParameters: feedstockParametersPatch,
    };
    const patchMutationArgs = {
      simulationId: simulation.id,
      patch: simulationPatch,
    };
    patchMutation.mutate(patchMutationArgs, {
      onSuccess() {},
    });
    if (patchMutation.isError) {
      return (
        <Button
          color='red'
          onClick={() => {
            patchMutation.reset();
          }}
        >
          <IconRefresh /> Mutation Failed - Reset
        </Button>
      );
    }
  }, [feedTotal, patchMutation, simulation.id]);

  return (
    <Stack mt='lg'>
      <Flex align='flex-start' justify='flex-start' gap='md'>
        <SimulationFeedstockCommodityEditor />
        <LabeledValue label='Total Feedstock Mass'>
          <NumberInput
            value={feedTotal ?? ''}
            onChange={(v) => {
              setFeedTotal(v === '' ? null : v);
            }}
            onBlur={() => mutateFeedstockMass()}
            onKeyDown={(e) => {
              if (e.code === 'Enter') {
                mutateFeedstockMass();
              }
            }}
          />
        </LabeledValue>
        <SimulationFeedstockSourceTypeSegmentedControl />
        {match(simulation)
          .with(
            { compositionSourceInternalMaterialSource: P.nonNullable },
            (withInternalMaterialSource) => (
              <LabeledValue label='Upstream Material Source'>
                <InternalMaterialSourceName
                  internalMaterialSource={
                    withInternalMaterialSource
                      .compositionSourceInternalMaterialSource
                      .internalMaterialSource
                  }
                />
              </LabeledValue>
            ),
          )
          .with(
            { compositionSourceInternallySourcedMaterial: P.nonNullable },
            (withInternallySourcedMaterial) => (
              <LabeledValue label='Upstream Sourced Material'>
                <InternallySourcedMaterialName
                  internallySourcedMaterial={
                    withInternallySourcedMaterial.compositionSourceInternallySourcedMaterial
                  }
                />
              </LabeledValue>
            ),
          )
          .otherwise(() => undefined)}
        <PopulateSimulationInputCompositionFromInternalMaterialSourceButton />
      </Flex>
      <SimulationInputComposition />
    </Stack>
  );
}

function SimulationFeedstockSourceTypeSegmentedControl() {
  const { simulation } = useRecoveryStrategySimulationCtx();

  type InputCompositionSourceType =
    | 'internalMaterialSource'
    | 'internallySourcedMaterial'
    | 'materialClassSetComposition';
  const inputCompositionSourceType: InputCompositionSourceType = match(
    simulation,
  )
    .with(
      { compositionSourceInternalMaterialSource: P.nonNullable },
      () => 'internalMaterialSource' as const,
    )
    .with(
      { compositionSourceInternallySourcedMaterial: P.nonNullable },
      () => 'internallySourcedMaterial' as const,
    )
    .otherwise(() => 'materialClassSetComposition');
  interface InputCompositionSourceData {
    label: string;
    value: InputCompositionSourceType;
    disabled: boolean;
  }
  const inputCompositionSourceTypeData: InputCompositionSourceData[] = [
    {
      label: 'Upstream Source',
      value: 'internalMaterialSource',
      disabled: inputCompositionSourceType !== 'internalMaterialSource',
    },
    {
      label: 'Upstream Sourced Material',
      value: 'internallySourcedMaterial',
      disabled: inputCompositionSourceType !== 'internallySourcedMaterial',
    },
    {
      label: 'Custom Composition',
      value: 'materialClassSetComposition',
      disabled: inputCompositionSourceType !== 'materialClassSetComposition',
    },
  ];
  return (
    <LabeledValue label='Feedstock Composition Source Type'>
      <SegmentedControl
        value={inputCompositionSourceType}
        data={inputCompositionSourceTypeData}
      />
    </LabeledValue>
  );
}

export function SimulationFeedstockCommodityEditor() {
  const { simulation } = useRecoveryStrategySimulationCtx();
  const [opened, { open, close }] = useDisclosure(false);
  return (
    <LabeledValue label='Input Commodity'>
      <Modal
        opened={opened}
        onClose={close}
        title='Select Feedstock Material Commodity Type'
        centered
        size={'xl'}
      >
        <Stack>
          <Text>
            Select a commodity to use for the process simulation feedstock type.
            This commodity will be used in conjunction with the recovery
            strategy and default commodity path assignments to construct the
            recovery tree.
          </Text>
          <CommoditySelector onSelect={close} />
        </Stack>
      </Modal>

      <Group spacing='xs'>
        {simulation.rootMaterialNode?.commodity ? (
          <CommodityName commodity={simulation.rootMaterialNode.commodity} />
        ) : (
          <Text color='red'>Not Specified</Text>
        )}
        <ActionIcon onClick={open} variant='subtle'>
          <EditIcon />
        </ActionIcon>
      </Group>
    </LabeledValue>
  );
}

function CommoditySelector(props: { onSelect?: () => void }) {
  const { onSelect } = props;
  const { simulation } = useRecoveryStrategySimulationCtx();
  const [selectedCommodityId, setSelectedCommodityId] = useState<
    string | undefined
  >(undefined);
  const commoditiesQuery = useCommodities();
  const patchRecoverySimulationMutation = usePatchRecoveryStrategySimulation();

  const mutateRecoverySimulationCompositionSource = () => {
    if (selectedCommodityId === undefined) {
      throw new Error('no selected commodity');
    }
    const patchMutationArgs = {
      simulationId: simulation.id,
      patch: {
        inputCommodityId: selectedCommodityId,
      },
    };
    patchRecoverySimulationMutation.mutate(patchMutationArgs, {
      onSuccess() {
        onSelect?.();
      },
    });
  };

  if (patchRecoverySimulationMutation.isError) {
    return (
      <Button
        color='red'
        onClick={() => {
          patchRecoverySimulationMutation.reset();
        }}
      >
        <IconRefresh /> Mutation Failed - Reset
      </Button>
    );
  }

  if (commoditiesQuery.data) {
    return (
      <Stack>
        <Table highlightOnHover={true} p='lg'>
          <thead>
            <tr>
              <th>Commodity Name</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            {commoditiesQuery.data.map((c) => {
              return (
                <tr
                  className={`${classes.pointer} ${selectedCommodityId === c.id ? classes.selected : ''}`}
                  key={c.id}
                  onClick={() => setSelectedCommodityId(c.id)}
                >
                  <td>{c.name}</td>
                  <td>{c.description ?? <Text c='dimmed'>none</Text>}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <Button
          w='10rem'
          onClick={() => {
            mutateRecoverySimulationCompositionSource();
          }}
          disabled={!selectedCommodityId}
          loading={patchRecoverySimulationMutation.isLoading}
        >
          Apply
        </Button>
      </Stack>
    );
  }

  if (commoditiesQuery.isLoading) {
    return <Skeleton h='10rem' />;
  }

  if (commoditiesQuery.isLoadingError) {
    return (
      <Flex align={'center'} justify={'center'} w='100%' h='100%'>
        <Alert title='Uh oh...' color='red' miw={'50%'}>
          Failed to load available commodities.
        </Alert>
      </Flex>
    );
  }
}

export function PopulateSimulationInputCompositionFromInternalMaterialSourceButton() {
  const [opened, { open, close }] = useDisclosure(false);
  return (
    <>
      <Modal
        opened={opened}
        onClose={close}
        title='Populate Feedstock Composition from Upstream Material'
        centered
        size='100%'
      >
        <Stack>
          <Text>
            Select an upstream material source to populate the simulation
            feedstock material composition. You can also optionally select a
            date range for the material that was derived from that upstream
            material source. The selected upsteram material source must have at
            least one source material derived from it to be used to populate the
            feedstock composition. Once an upstream material source is selected,
            you can select...
            <List>
              <List.Item>
                a material derived from that source in the specified date range
              </List.Item>
              <List.Item>
                the unweighted average composition of all the materials derived
                from that source in the date range
              </List.Item>
              <List.Item>
                the weighted average composition of all the material derived
                from that source in the date range, weighting each sourced
                material by its weight
              </List.Item>
            </List>
            Then, specify whether you want the selected upstream material source
            commodity to use for the simulation feedstock commodity type.
          </Text>
          <PopulateFromInternalMaterialSourceWidget
            onClose={close}
            onOpen={open}
          />
        </Stack>
      </Modal>
      <LabeledValue label='Autofill Feedstock Composition'>
        <Button
          leftIcon={<InternalMaterialSourceIcon />}
          onClick={open}
          w='fit-content'
        >
          Select Upstream Source Composition
        </Button>
      </LabeledValue>
    </>
  );
}

interface ModalProps {
  onOpen?: () => void;
  onClose?: () => void;
}

function PopulateFromInternalMaterialSourceWidget(props: ModalProps) {
  const [selectedImsId, setSelectedImsId] =
    useState<InternalMaterialSourceId | null>(null);
  const [interval, setInterval] = useState<
    [string | undefined, string | undefined]
  >([undefined, undefined]);
  const [associateCommodity, setAssociateCommodity] = useState<boolean>(true);

  const imsQuery = useInternalMaterialSources();
  const imsCompositionQuery = useInternalMaterialSourceComposition({
    internalMaterialSourceId: selectedImsId,
    intervalStart: interval[0],
    intervalEnd: interval[1],
  });

  if (imsQuery.data) {
    return (
      <Stack>
        <Group position='apart'>
          <Group w='70%'>
            <Select
              size='md'
              miw='49%'
              required
              label='Select upstream material source'
              value={selectedImsId ?? ''}
              onChange={(ims: string) => setSelectedImsId(ims)}
              data={
                imsQuery.data?.map((f) => ({
                  value: f.id,
                  label: f.name,
                })) ?? []
              }
              disabled={imsQuery.isLoading}
              dropdownPosition={'bottom'}
            />
            <DatePickerInput
              miw='49%'
              size='md'
              label='Select date range for sourced materials'
              type='range'
              onChange={(val: DatesRangeValue) => {
                setInterval([val[0]?.toISOString(), val[1]?.toISOString()]);
              }}
              clearable
            />
          </Group>
          <Flex gap='md' maw='28%'>
            <Input.Wrapper
              label='Populate Feedstock Commodity Type'
              description='Check this box if you want this selection to upstream the simulation feedstock commodity type.'
            >
              <Checkbox
                checked={associateCommodity}
                onChange={(e) => setAssociateCommodity(e.target.checked)}
                size='xl'
                mt='.3rem'
              />
            </Input.Wrapper>
          </Flex>
        </Group>
        <Skeleton
          visible={imsCompositionQuery.isLoading && selectedImsId !== null}
          mih='20rem'
          mt='lg'
        >
          {imsCompositionQuery.data && (
            <CompositionSelectorTable
              {...props}
              imsId={selectedImsId}
              imsCommodityId={
                imsQuery.data.find(
                  (s: InternalMaterialSourceDTO) => s.id === selectedImsId,
                )?.commodityId ?? undefined
              }
              interval={interval}
              analysis={imsCompositionQuery.data}
              associateCommodity={associateCommodity}
            />
          )}
        </Skeleton>
      </Stack>
    );
  }

  if (imsQuery.isLoading) {
    return <Skeleton mih='30rem' w='100%' />;
  }

  if (imsQuery.error || imsQuery.isLoadingError) {
    return (
      <Stack h='30rem'>
        <Flex align={'center'} justify={'center'} w='100%' h='100%'>
          <Alert title='Uh oh...' color='red' miw={'50%'}>
            Failed to load Internal Material Sources. Check your network
            connectivity.
          </Alert>
        </Flex>
      </Stack>
    );
  }

  if (imsCompositionQuery.isLoadingError || imsCompositionQuery.isError) {
    return (
      <Stack h='30rem'>
        <Flex align={'center'} justify={'center'} w='100%' h='100%'>
          <Alert title='Uh oh...' color='red' miw={'50%'}>
            Failed to load composition for internal material sources. Check your
            network connectivity.
          </Alert>
        </Flex>
      </Stack>
    );
  }
}

function CompositionSelectorTableStatusText(props: {
  isEmpty: boolean;
  hasMaterialSet: boolean;
}) {
  const { isEmpty, hasMaterialSet } = props;

  return (
    <>
      {isEmpty && (
        <Alert color='orange' title='No Sourced Materials'>
          There are no sourced materials in the selected date range for this
          upsteram material source. Please select an upstream material source
          with at least one sourced material in the optionally specified date
          range.
        </Alert>
      )}
      {!isEmpty && !hasMaterialSet && (
        <Alert
          color='orange'
          title='No Sourced Materials with Simulation Material Class Set'
        >
          There are no sourced materials in the selected date range for this
          upsteram material source which have compositions matching the
          simulation material class set. Please select an upsteram material
          source with at least one sourced material sampled using the simulation
          material class set.
        </Alert>
      )}
    </>
  );
}

interface InternallySourcedCompositionSelectorTableProps {
  internallySourcedMaterialCompositions: InternallySourcedMaterialCompositionDTO[];
  materialClassSet: MaterialClassSetDTO;
}

function InternallySourcedCompositionSelectorTable(
  props: InternallySourcedCompositionSelectorTableProps,
) {
  const { materialClassSet, internallySourcedMaterialCompositions } = props;

  const {
    setSelectedMaterialClassSetComposition,
    selectedInputCompositionSource: selectedSourceComposition,
    setSelectedInputCompositionSource,
  } = useRecoveryStrategySimulationCompositionCtx();

  return (
    <Table w='100%' highlightOnHover={true}>
      <thead>
        <tr>
          <th style={{ textAlign: 'start', width: '10rem' }}>
            Upstream Sourced Material Name
          </th>
          {materialClassSet.materialClasses.map((materialClass) => {
            return <th key={materialClass.id}>{materialClass.name}</th>;
          })}
        </tr>
      </thead>
      <tbody>
        {internallySourcedMaterialCompositions.map(
          (composition: InternallySourcedMaterialCompositionDTO) => {
            const compPercentages =
              composition.materialClassSetCompositions[materialClassSet.id];
            const compValues = Object.fromEntries(
              Object.entries(compPercentages).map(([key, percentage]) => {
                return [
                  key,
                  percentage *
                    (composition.netWeight === null
                      ? 100
                      : getWeightFromNetWeight(composition.netWeight)),
                ];
              }),
            );
            return (
              <tr
                key={composition.internallySourcedMaterial.id}
                className={`${classes.pointer} ${match(
                  selectedSourceComposition,
                )
                  .with(
                    {
                      internallySourcedMaterialId:
                        composition.internallySourcedMaterial.id,
                    },
                    () => classes.selected,
                  )
                  .otherwise(() => '')}`}
                onClick={() => {
                  setSelectedMaterialClassSetComposition(compValues);
                  setSelectedInputCompositionSource({
                    internallySourcedMaterialId:
                      composition.internallySourcedMaterial.id,
                  });
                }}
              >
                <td style={{ maxWidth: '10%' }}>
                  {composition.internallySourcedMaterial.name}
                </td>
                {materialClassSet.materialClasses.map((materialClass) => {
                  return (
                    <td key={materialClass.id}>
                      {Number(compPercentages[materialClass.id]).toFixed(2)}
                    </td>
                  );
                })}
              </tr>
            );
          },
        )}
      </tbody>
    </Table>
  );
}

interface AggregateCompositionSourceSelectorTableProps {
  weightedCompositions: Record<string, Record<string, number>>;
  unweightedCompositions: Record<string, Record<string, number>>;
  materialClassSet: MaterialClassSetDTO;
}

function AggregateCompositionSourceSelectorTable(
  props: AggregateCompositionSourceSelectorTableProps,
) {
  const { weightedCompositions, unweightedCompositions, materialClassSet } =
    props;
  const {
    selectedInputCompositionSource: selectedSourceComposition,
    setSelectedInputCompositionSource,
    setSelectedMaterialClassSetComposition,
  } = useRecoveryStrategySimulationCompositionCtx();

  const hasWeightedComp = materialClassSet.id in weightedCompositions;
  const hasUnweightedComp = materialClassSet.id in unweightedCompositions;

  return (
    <Table w='100%' highlightOnHover={true}>
      <thead>
        <tr>
          <th style={{ textAlign: 'start', width: '10rem' }}>
            Aggregation Type
          </th>
          {materialClassSet.materialClasses.map((materialClass) => {
            return <th key={materialClass.id}>{materialClass.name}</th>;
          })}
        </tr>
      </thead>
      <tbody>
        {hasUnweightedComp && (
          <tr
            className={`${classes.pointer} ${match(selectedSourceComposition)
              .with(
                InputCompositionInternalMaterialSourceVariant.unweighted,
                () => classes.selected,
              )
              .otherwise(() => '')}`}
            onClick={() => {
              const percentages = unweightedCompositions[materialClassSet.id];
              const compVals = Object.fromEntries(
                Object.entries(percentages).map(([key, value]) => [
                  key,
                  value * 100,
                ]),
              );
              setSelectedMaterialClassSetComposition(compVals);
              setSelectedInputCompositionSource(
                InputCompositionInternalMaterialSourceVariant.unweighted,
              );
            }}
          >
            <td>Unweighted Composition</td>
            {materialClassSet.materialClasses.map((materialClass) => {
              const compVals = unweightedCompositions[materialClassSet.id];
              return (
                <td key={materialClass.id}>
                  {Number(compVals[materialClass.id]).toFixed(2)}
                </td>
              );
            })}
          </tr>
        )}
        {hasWeightedComp && (
          <tr
            className={`${classes.pointer} ${match(selectedSourceComposition)
              .with(
                InputCompositionInternalMaterialSourceVariant.weighted,
                () => classes.selected,
              )
              .otherwise(() => '')}`}
            onClick={() => {
              const percentages = weightedCompositions[materialClassSet.id];
              const compVals = Object.fromEntries(
                Object.entries(percentages).map(([key, value]) => [
                  key,
                  value * 100,
                ]),
              );
              setSelectedMaterialClassSetComposition(compVals);
              setSelectedInputCompositionSource(
                InputCompositionInternalMaterialSourceVariant.weighted,
              );
            }}
          >
            <td>Weighted Composition</td>
            {materialClassSet.materialClasses.map((materialClass) => {
              const compVals = weightedCompositions[materialClassSet.id];
              return (
                <td key={materialClass.id}>
                  {Number(compVals[materialClass.id]).toFixed(2)}
                </td>
              );
            })}
          </tr>
        )}
      </tbody>
    </Table>
  );
}

interface CompositionSourceAnalysisProps {
  imsId: string | null;
  imsCommodityId: string | undefined;
  interval: [string | undefined, string | undefined];
  analysis: InternalMaterialSourceBulkCompositionAnalysisResultDTO;
  associateCommodity: boolean;
}

function CompositionSelectorTable(
  props: ModalProps & CompositionSourceAnalysisProps,
) {
  const {
    onClose,
    imsId,
    imsCommodityId,
    interval,
    analysis,
    associateCommodity,
  } = props;
  const { simulation } = useRecoveryStrategySimulationCtx();

  const emptyInputComposition: Record<string, number> = Object.fromEntries(
    simulation.materialClassSet.materialClasses.map((mc) => [mc.id, 0]),
  );
  const [
    selectedMaterialClassSetComposition,
    setSelectedMaterialClassSetComposition,
  ] = useState<Record<string, number>>(
    simulation.rootMaterialNode?.materialClassComposition ??
      emptyInputComposition,
  );
  const [selectedInputCompositionSource, setSelectedInputCompositionSource] =
    useState<InputCompositionSource>({
      materialClassSetCompositionId:
        simulation.compositionSourceMaterialClassSetCompositionId,
    });

  const patchRecoverySimulationMutation = usePatchRecoveryStrategySimulation();
  const mutateRecoverySimulationCompositionSource = useCallback(() => {
    const patchMutationArgs = match(selectedInputCompositionSource)
      .with(null, { materialClassSetCompositionId: P.string }, () => {
        return null;
      })
      .with(InputCompositionInternalMaterialSourceVariant.weighted, () => {
        return {
          simulationId: simulation.id,
          patch: {
            aggregateCompositionSource: {
              internalMaterialSourceId: imsId ?? '',
              intervalStart: interval[0] ?? null,
              intervalEnd: interval[1] ?? null,
              isWeighted: true,
            },
            materialClassSetComposition: {
              materialClassesProportions: selectedMaterialClassSetComposition,
            },
            ...(associateCommodity && { inputCommodityId: imsCommodityId }),
          },
        };
      })
      .with(InputCompositionInternalMaterialSourceVariant.unweighted, () => {
        return {
          simulationId: simulation.id,
          patch: {
            aggregateCompositionSource: {
              internalMaterialSourceId: imsId ?? '',
              intervalStart: interval[0] ?? null,
              intervalEnd: interval[1] ?? null,
              isWeighted: false,
            },
            materialClassSetComposition: {
              materialClassesProportions: selectedMaterialClassSetComposition,
            },
            ...(associateCommodity && { inputCommodityId: imsCommodityId }),
          },
        };
      })
      .with(
        { internallySourcedMaterialId: P.string },
        ({ internallySourcedMaterialId }) => {
          return {
            simulationId: simulation.id,
            patch: {
              internallySourcedMaterialCompositionSource: {
                internallySourcedMaterialId: internallySourcedMaterialId,
              },
              materialClassSetComposition: {
                materialClassesProportions: selectedMaterialClassSetComposition,
              },
              ...(associateCommodity && { inputCommodityId: imsCommodityId }),
            },
          };
        },
      )
      .exhaustive();
    if (patchMutationArgs !== null)
      patchRecoverySimulationMutation.mutate(patchMutationArgs);
  }, [
    selectedInputCompositionSource,
    patchRecoverySimulationMutation,
    simulation.id,
    imsId,
    imsCommodityId,
    associateCommodity,
    interval,
    selectedMaterialClassSetComposition,
  ]);

  if (analysis === null) return <></>;

  const isEmpty = analysis.internallySourcedMaterialCompositions.length === 0;
  const hasMaterialSet =
    simulation.materialClassSet.id in analysis.materialClassSets;

  const disableApplyButton =
    patchRecoverySimulationMutation.isLoading ||
    match(selectedInputCompositionSource)
      .with(
        {
          materialClassSetCompositionId:
            simulation.compositionSourceMaterialClassSetCompositionId,
        },
        () => true,
      )
      .otherwise(() => false);
  return (
    <Stack justify={'end'} h='100%'>
      <RecoveryStrategySimulationCompositionCtxProvider
        selectedMaterialClassSetComposition={
          selectedMaterialClassSetComposition
        }
        setSelectedMaterialClassSetComposition={
          setSelectedMaterialClassSetComposition
        }
        selectedInputCompositionSource={selectedInputCompositionSource}
        setSelectedInputCompositionSource={setSelectedInputCompositionSource}
      >
        <CompositionSelectorTableStatusText
          isEmpty={isEmpty}
          hasMaterialSet={hasMaterialSet}
        />
        {hasMaterialSet && (
          <InternallySourcedCompositionSelectorTable
            internallySourcedMaterialCompositions={
              analysis.internallySourcedMaterialCompositions
            }
            materialClassSet={simulation.materialClassSet}
          />
        )}
        {hasMaterialSet && (
          <AggregateCompositionSourceSelectorTable
            weightedCompositions={analysis.weightedCompositions}
            unweightedCompositions={analysis.unweightedCompositions}
            materialClassSet={simulation.materialClassSet}
          />
        )}
        <Flex align={'center'} justify={'end'} w={'100%'}>
          {patchRecoverySimulationMutation.isError ? (
            <Button
              miw='100%'
              color='red'
              onClick={() => {
                patchRecoverySimulationMutation.reset();
                setSelectedMaterialClassSetComposition(
                  simulation.rootMaterialNode?.materialClassComposition ??
                    emptyInputComposition,
                );
                setSelectedInputCompositionSource(
                  selectedInputCompositionSource,
                );
              }}
            >
              <IconRefresh />
              <Text>An Error Occurred - Click Here to Reset</Text>
            </Button>
          ) : (
            !isEmpty && (
              <Flex justify={'between'} gap={'lg'} align={'center'}>
                <Button
                  onClick={() => {
                    mutateRecoverySimulationCompositionSource();
                    onClose?.();
                  }}
                  disabled={disableApplyButton}
                  onKeyDown={(e) => {
                    if (e.code === 'Enter') {
                      mutateRecoverySimulationCompositionSource();
                      onClose?.();
                    }
                  }}
                >
                  Apply
                </Button>
              </Flex>
            )
          )}
        </Flex>
      </RecoveryStrategySimulationCompositionCtxProvider>
    </Stack>
  );
}
