import { ActionIcon, Loader, Table, useMantineTheme } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { DeleteIcon, EditIcon } from '../../Icons.tsx';
import { TableEmptyBasicContent } from '../../Table/TableEmptyBasicContent.tsx';
import {
  useCommoditySpotPrices,
  useDeleteCommoditySpotPrice,
} from '../../api/commoditySpotPrice.ts';
import { CommodityId, CommoditySpotPriceDTO } from '../../rest-client/index.ts';
import { Price } from '../../util/Money.tsx';
import { EditCommoditySpotPriceDrawerForm } from './EditCommoditySpotPriceDrawerForm.tsx';

export function CommoditySpotPricesTable(props: { commodityId: CommodityId }) {
  const { commodityId }: { commodityId: CommodityId } = props;

  const theme = useMantineTheme();
  const {
    data: commoditySpotPrices,
    isLoading,
    isLoadingError,
    error,
  } = useCommoditySpotPrices(commodityId);

  if (isLoadingError) {
    throw error;
  }

  const isEmpty = commoditySpotPrices?.length === 0;

  return (
    <Table striped highlightOnHover>
      {(isLoading || isEmpty) && (
        <caption
          style={{
            captionSide: 'bottom',
            textAlign: 'center',
            padding: theme.spacing.md,
          }}
        >
          {isLoading && <Loader />}
          {!isLoading && isEmpty && (
            <TableEmptyBasicContent>
              No commodity spot prices.
            </TableEmptyBasicContent>
          )}
        </caption>
      )}
      <thead>
        <tr>
          <th>Date</th>
          <th style={{ textAlign: 'right' }}>Price</th>
          <th>Edit</th>
          <th>Delete</th>
        </tr>
      </thead>
      <tbody>
        {commoditySpotPrices?.map((commoditySpotPrice) => (
          <CommoditySpotPriceRow
            key={commoditySpotPrice.id}
            commoditySpotPrice={commoditySpotPrice}
            commodityId={commodityId}
          />
        ))}
      </tbody>
    </Table>
  );
}

type CommoditySpotPriceRowProps = {
  commodityId: CommodityId;
  commoditySpotPrice: CommoditySpotPriceDTO;
};

function CommoditySpotPriceRow(props: CommoditySpotPriceRowProps) {
  const { commodityId, commoditySpotPrice } = props;

  const [editDrawerOpened, { open: openEditDrawer, close: closeEditDrawer }] =
    useDisclosure(false);

  const deleteMutation = useDeleteCommoditySpotPrice(commodityId);

  return (
    <tr>
      <td>{dayjs(commoditySpotPrice.pricedAt).format('L')}</td>
      <td style={{ textAlign: 'right' }}>
        <Price
          usdPerWeightUnit={commoditySpotPrice.usdPerUnitOfWeight}
          weightUnit={commoditySpotPrice.weightUnit}
        />
      </td>
      <td>
        <ActionIcon
          gradient={{ from: 'teal', to: 'teal', deg: 90 }}
          variant='gradient'
          size='xl'
          onClick={openEditDrawer}
        >
          <EditIcon />
        </ActionIcon>
        <EditCommoditySpotPriceDrawerForm
          opened={editDrawerOpened}
          onClose={closeEditDrawer}
          commodityId={commodityId}
          commoditySpotPrice={commoditySpotPrice}
        />
      </td>
      <td>
        <ActionIcon
          gradient={{ from: 'red', to: 'red', deg: 90 }}
          variant='gradient'
          size='xl'
          onClick={() => {
            deleteMutation.mutate(commoditySpotPrice.id, {
              onError() {
                showNotification({
                  title: 'Error Deleting Price',
                  message: 'An error occured deleting the price.',
                  color: 'red',
                  icon: <IconX />,
                });
                deleteMutation.reset();
              },
              onSuccess() {
                showNotification({
                  title: 'Price Deleted',
                  message: 'Price was successfully deleted.',
                  color: 'green',
                  icon: <IconCheck />,
                });
              },
            });
          }}
        >
          <DeleteIcon />
        </ActionIcon>
      </td>
    </tr>
  );
}
