import { Center, Loader, useMantineTheme } from '@mantine/core';
import dayjs, { Dayjs } from 'dayjs';
import { useFacilityContext } from '../Facility/FacilityContext';
import {
  useDetailedProcess,
  useProcessSystemConfigChanges,
  useProcessSystemObjectQuantities,
  useSystemOperationalStateHistories,
} from '../api/process';
import {
  DetailedProcessDTO,
  ProductionIntervalTemporalStatusDTO,
  SystemConfigChangesDTO,
  SystemObjectQuantitiesDTO,
  SystemOperationalState,
  SystemOperationalStateHistoryDTO,
} from '../rest-client';

import { CustomChart } from 'echarts/charts';
import {
  DataZoomComponent,
  GridComponent,
  MarkAreaComponent,
  MarkLineComponent,
  TitleComponent,
  TooltipComponent,
  VisualMapComponent,
  VisualMapPiecewiseComponent,
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { useCallback } from 'react';
import { match } from 'ts-pattern';
import { DataZoomEvent, EChart } from '../echarts/BareEChart';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DataZoomComponent,
  CustomChart,
  MarkLineComponent,
  VisualMapComponent,
  VisualMapPiecewiseComponent,
  MarkAreaComponent,
]);

export interface ProvisionalProcessRunInterval {
  start: Dayjs;
  end: Dayjs;
}

export interface ProvisionalProcessStopInterval {
  start: Dayjs;
  end: Dayjs;
  unplanned: boolean | undefined;
}

function ProductionIntervalTimelineEChart(props: {
  status: ProductionIntervalTemporalStatusDTO;
  process: DetailedProcessDTO;
  systemOperationalStateHistories: Record<
    string,
    SystemOperationalStateHistoryDTO
  >;
  systemObjectQuantities: SystemObjectQuantitiesDTO;
  systemConfigChanges: SystemConfigChangesDTO;
  onSelectedRangeChange?: (range: [Dayjs, Dayjs]) => void;
}) {
  const {
    status,
    process,
    systemOperationalStateHistories,
    systemObjectQuantities,
    systemConfigChanges,
    onSelectedRangeChange,
  } = props;

  const theme = useMantineTheme();

  const { timeZoneId: tz } = useFacilityContext();

  const onDataZoom = useCallback(
    (dze: DataZoomEvent) => {
      const start = dayjs.utc(status.productionInterval.startTime);
      const end = dayjs.utc(status.productionInterval.endTime);
      const durationMs = end.diff(start);

      const startOffset = durationMs * dze.start;
      const endOffset = durationMs * dze.end;

      onSelectedRangeChange?.([
        start.add(startOffset, 'milliseconds').tz(tz),
        start.add(endOffset, 'milliseconds').tz(tz),
      ]);
    },
    [
      onSelectedRangeChange,
      tz,
      status.productionInterval.startTime,
      status.productionInterval.endTime,
    ],
  );

  const xAxisLabelFormatter = useCallback(
    (v: number) => dayjs.unix(v).tz(tz).format('LT'),
    [tz],
  );

  const markLineLabelFormatter = useCallback(
    (v: { data: { value: number; label: string } }) =>
      dayjs.unix(v.data.value).tz(tz).format('LT'),
    [tz],
  );

  const markLineEmphLabelFormatter = useCallback(
    (v: { data: { label: string } }) => v.data.label,
    [],
  );

  const operationalStateItemStyle = (
    operationalState: SystemOperationalState,
  ) =>
    match(operationalState)
      .with(SystemOperationalState.UNREACHABLE, () => ({
        color: 'gray',
        opacity: 0.2,
      }))
      .with(SystemOperationalState.OFFLINE, () => ({
        color: 'gray',
        opacity: 0.3,
      }))
      .with(SystemOperationalState.SETTLING, () => ({
        color: 'black',
        opacity: 0.45,
      }))
      .with(SystemOperationalState.STANDBY, () => ({
        color: theme.colors.gray[5],
        opacity: 0.25,
      }))
      .with(SystemOperationalState.ENGAGING, () => ({
        color: theme.colors.blue[9],
        opacity: 0.55,
      }))
      .with(SystemOperationalState.ENGAGED, () => ({
        color: theme.colors.green[9],
        opacity: 0.25,
      }))
      .with(SystemOperationalState.DISENGAGING, () => ({
        color: theme.colors.blue[9],
        opacity: 0.35,
      }))
      .with(SystemOperationalState.FAULTED, () => ({
        color: theme.colors.red[7],
        opacity: 0.45,
      }))
      .exhaustive();

  const prodIntervalStartUnix = dayjs
    .utc(status.productionInterval.startTime)
    .unix();
  const prodIntervalEndUnix = dayjs
    .utc(status.productionInterval.endTime)
    .unix();

  const systemMaxObjects = Object.fromEntries(
    Object.entries(systemObjectQuantities.systemObjectQuantities).map(
      ([systemId, qs]) => [systemId, qs.reduce((a, b) => Math.max(a, b), 0)],
    ),
  );

  const effectiveSystemOperationalState = useCallback(
    (systemId: string, time: Dayjs): SystemOperationalState => {
      const history = systemOperationalStateHistories[systemId];
      if (
        history.stateChanges.length === 0 ||
        time.isBefore(dayjs.utc(history.stateChanges[0].instant))
      ) {
        return history.initialState;
      }

      for (const c of history.stateChanges.slice().reverse()) {
        if (dayjs.utc(c.instant).isSameOrBefore(time)) {
          return c.state;
        }
      }

      throw new Error('no effective state found');
    },
    [systemOperationalStateHistories],
  );

  const effectiveSystemConfig = useCallback(
    (systemId: string, time: Dayjs) => {
      const configChanges = systemConfigChanges.systemConfigChanges[systemId];
      if (
        configChanges.length === 0 ||
        time.isBefore(dayjs.utc(configChanges[0].instant))
      ) {
        return systemConfigChanges.systemInitiallyActiveConfigs[systemId]
          .systemConfiguration;
      }

      // iterate backwards and the the first config change before the given time
      for (const c of configChanges.slice().reverse()) {
        if (dayjs.utc(c.instant).isSameOrBefore(time)) {
          return c.systemConfiguration;
        }
      }

      throw new Error('no config found for time');
    },
    [systemConfigChanges],
  );

  const tooltipFormatter = useCallback(
    (params: [{ axisValue: number }]) => {
      const tooltipTime = dayjs.unix(params[0].axisValue).tz(tz);
      const systemStates = process.sortSystems.map((system) => ({
        system,
        config: effectiveSystemConfig(system.id, tooltipTime),
        operationalState: effectiveSystemOperationalState(
          system.id,
          tooltipTime,
        ),
      }));

      return `${tooltipTime.format('LT')}<br/>${systemStates
        .map(
          ({ system, config, operationalState }) =>
            `${system.name} | ${operationalState} | ${config.name}`,
        )
        .join('')}`;
    },
    [effectiveSystemOperationalState, effectiveSystemConfig, process, tz],
  );

  const option = {
    grid: [
      { bottom: '30%', left: '7%', right: '7%', top: '5%' },
      { top: '80%', left: '7%', right: '7%' },
    ],
    xAxis: [
      {
        gridIndex: 0,
        type: 'value',
        min: prodIntervalStartUnix,
        max: prodIntervalEndUnix,
        scale: true,
        axisLabel: {
          show: true,
          formatter: xAxisLabelFormatter,
        },
      },
      {
        type: 'value',
        min: prodIntervalStartUnix,
        max: prodIntervalEndUnix,
        scale: true,
        axisLabel: {
          show: false,
        },
        gridIndex: 1,
      },
    ],
    yAxis: [
      {
        gridIndex: 0,
        axisLabel: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLine: {
          show: false,
        },
      },
      {
        gridIndex: 1,
        data: [...Array(status.maxDepth + 1).keys()],
        axisLabel: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLine: {
          show: false,
        },
      },
    ],
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        axis: 'x',
        snap: false,
      },
      formatter: tooltipFormatter,
    },
    visualMap: {
      type: 'piecewise',
      show: false,
      dimension: 0,
      outOfRange: {
        color: 'red',
      },
      pieces: [
        ...status.stopStatuses.flatMap((d, i) => {
          if (d.inverted) return [];
          if (d.stop.startBoundResult.status !== 'success') return [];
          if (d.stop.endBoundResult.status !== 'success') return [];
          if (d.stopOverlapErrors.length > 0) return [];

          return {
            label: `D${i + 1}`,
            gt: dayjs.utc(d.stop.startBoundResult.instant).unix(),
            lt: dayjs.utc(d.stop.endBoundResult.instant).unix(),
            color: d.stop.reason.unplanned ? 'orange' : 'yellow',
          };
        }),
        // TODO(2331): handle provisional stops
        // ...provisionalProcessStopIntervals.map((d) => ({
        //   gt: d.start.unix(),
        //   lt: d.end.unix(),
        //   color: 'rgba(227, 100, 45, 0.6)',
        // })),
        ...status.feedFlowGroups.flatMap((ffg, i) => {
          if (ffg.lastFlowIntervalEnd === null) return []; // Don't render empty flow groups
          return {
            label: `R${i + 1}`,
            gt: dayjs.utc(ffg.effectiveTimestamp).unix(),
            lt: dayjs.utc(ffg.lastFlowIntervalEnd).unix(),
            color: 'rgba(0,180,0,0.8)',
          };
        }),
        // TODO(2331): Handle provisional runs
        // ...provisionalProcessRunIntervals.map((p) => ({
        //   gt: p.start.unix(),
        //   lt: p.end.unix(),
        //   color: 'rgba(0,180,0,0.8)',
        // })),
      ],
    },
    dataZoom: {
      xAxisIndex: [0, 1],
      type: 'slider',
      labelFormatter: xAxisLabelFormatter,
    },
    series: [
      {
        xAxisIndex: 1,
        yAxisIndex: 1,
        type: 'custom',
        encode: {
          x: [1, 2],
          y: 0,
        },
        renderItem: renderRect,
        data: [
          ...status.feedFlowGroups.flatMap((ffg, i) =>
            ffg.lastFlowIntervalEnd === null
              ? []
              : [
                  {
                    itemStyle: {
                      color: theme.colors.blue[8],
                    },
                    name: `Process Run ${i + 1}`,
                    value: [
                      0,
                      dayjs.utc(ffg.effectiveTimestamp).unix(),
                      dayjs.utc(ffg.lastFlowIntervalEnd).unix(),
                    ],
                  },
                ],
          ),
          ...status.stopStatuses.flatMap((d, i) => {
            if (d.inverted) return [];
            if (d.stop.startBoundResult.status !== 'success') return [];
            if (d.stop.endBoundResult.status !== 'success') return [];
            return [
              {
                name: `Downtime ${i + 1}`,

                itemStyle: {
                  color: d.stop.reason.unplanned
                    ? theme.colors.orange[6]
                    : theme.colors.yellow[5],
                },
                value: [
                  d.depth,
                  dayjs.utc(d.stop.startBoundResult.instant).unix(),
                  dayjs.utc(d.stop.endBoundResult.instant).unix(),
                ],
              },
            ];
          }),
        ],
        // ...(provisionalProcessStopIntervals.length > 0 ||
        // provisionalProcessRunIntervals.length > 0
        //   ? []
        //   : status.uncoveredIntervals.map((u) => [
        //       {
        //         xAxis: dayjs.utc(u.start).unix(),
        //         yAxis: 0.5,
        //         itemStyle: {
        //           color: 'red',
        //           opacity: 0.15,
        //         },
        //       },
        //       { xAxis: dayjs.utc(u.end).unix() },
        //     ])),
        // ...status.processRuns.flatMap((p, i) => {
        //   if (p.processRun.endTime === null) return [];
        //   return [
        //     [
        //       {
        //         name: `PR${i + 1}`,
        //         xAxis: dayjs.utc(p.processRun.startTime).unix(),
        //         yAxis: 0.5,
        //         itemStyle: {
        //           color: theme.colors.blue[9],
        //           opacity: 0.25,
        //         },
        //       },
        //       {
        //         xAxis: dayjs.utc(p.processRun.endTime).unix(),
        //         yAxis: 0,
        //       },
        //     ],
        //   ];
        // }),
        // ...provisionalProcessRunIntervals.map((p) => [
        //   {
        //     xAxis: p.start.unix(),
        //     yAxis: 0.75,
        //     itemStyle: {
        //       color: theme.colors.blue[9],
        //       opacity: 0.25,
        //     },
        //   },
        //   {
        //     xAxis: p.end.unix(),
        //     yAxis: 0,
        //   },
        // ]),
        // ...provisionalProcessStopIntervals.map((d) => [
        //   {
        //     xAxis: d.start.unix(),
        //     yAxis: 1,
        //     itemStyle: {
        //       color:
        //         d.unplanned ?? false
        //           ? theme.colors.orange[8]
        //           : theme.colors.yellow[2],
        //       opacity: 0.15,
        //     },
        //   },
        //   {
        //     xAxis: d.end.unix(),
        //     yAxis: 0,
        //   },
        // ]),
      },
      ...process.sortSystems.map((system) => {
        const stateHistory = systemOperationalStateHistories[system.id];

        return {
          showSymbol: false,
          type: 'line',
          step: 'end',
          lineStyle: {
            width: 0,
          },
          data: systemObjectQuantities.systemObjectQuantities[system.id].map(
            (q, i) => [
              dayjs.utc(systemObjectQuantities.timestamps[i]).unix(),
              q / systemMaxObjects[system.id],
            ],
          ),
          areaStyle: {},
          symbol: 'none',
          markArea: {
            data: [
              [
                {
                  xAxis: prodIntervalStartUnix,
                  yAxis: 1,
                  itemStyle: operationalStateItemStyle(
                    stateHistory.initialState,
                  ),
                },
                {
                  xAxis:
                    stateHistory.stateChanges.length > 0
                      ? dayjs.utc(stateHistory.stateChanges[0].instant).unix()
                      : prodIntervalEndUnix,
                  yAxis: 0,
                },
              ],
              ...stateHistory.stateChanges.map(({ instant, state }, i) => [
                {
                  xAxis: dayjs.utc(instant).unix(),
                  yAxis: 1,
                  itemStyle: operationalStateItemStyle(state),
                },
                {
                  xAxis:
                    i + 1 < stateHistory.stateChanges.length
                      ? dayjs
                          .utc(stateHistory.stateChanges[i + 1].instant)
                          .unix()
                      : prodIntervalEndUnix,
                  yAxis: 0,
                },
              ]),
            ],
          },
          markLine: {
            symbol: ['none', 'round'],
            lineStyle: {
              color: theme.colors.grape[6],
            },
            label: {
              position: 'start',
              formatter: markLineLabelFormatter,
              distance: 20,
            },
            emphasis: {
              label: {
                position: 'insideEndTop',
                formatter: markLineEmphLabelFormatter,
              },
            },
            data: [
              {
                label:
                  systemConfigChanges.systemInitiallyActiveConfigs[system.id]
                    .systemConfiguration.name,
                xAxis: prodIntervalStartUnix,
              },
              ...systemConfigChanges.systemConfigChanges[system.id].map(
                (chg) => ({
                  label: chg.systemConfiguration.name,
                  xAxis: dayjs.utc(chg.instant).unix(),
                }),
              ),
            ],
          },
        };
      }),
    ],
  };

  return (
    <EChart
      h={320 + 100 * process.sortSystems.length}
      onDataZoom={onDataZoom}
      option={option}
    />
  );
}

export function ProductionIntervalTimelineChart(props: {
  status: ProductionIntervalTemporalStatusDTO;
  provisionalProcessRunIntervals?: ProvisionalProcessRunInterval[];
  provisionalProcessStopIntervals?: ProvisionalProcessStopInterval[];
  onSelectedRangeChange?: (range: [Dayjs, Dayjs]) => void;
}) {
  const { status, onSelectedRangeChange } = props;
  const { processId } = status.productionInterval;
  const processQuery = useDetailedProcess(processId);

  const productionIntervalStart = dayjs.utc(
    status.productionInterval.startTime,
  );
  const productionIntervalEnd = dayjs.utc(status.productionInterval.endTime);

  const systemConfigChangesQuery = useProcessSystemConfigChanges({
    processId,
    after: productionIntervalStart,
    before: productionIntervalEnd,
  });

  const systemObjectQuantitiesQuery = useProcessSystemObjectQuantities({
    processId,
    after: productionIntervalStart,
    before: productionIntervalEnd,
  });

  const systemOperationalStateHistoriesQuery =
    useSystemOperationalStateHistories({
      processId,
      after: productionIntervalStart,
      before: productionIntervalEnd,
    });

  if (
    processQuery.data &&
    systemConfigChangesQuery.data &&
    systemObjectQuantitiesQuery.data &&
    systemOperationalStateHistoriesQuery.data
  ) {
    const process = processQuery.data;
    const systemConfigChanges = systemConfigChangesQuery.data;

    const systemObjectQuantities = systemObjectQuantitiesQuery.data;
    const systemOperationalStateHistories =
      systemOperationalStateHistoriesQuery.data;

    return (
      <ProductionIntervalTimelineEChart
        status={status}
        process={process}
        systemOperationalStateHistories={systemOperationalStateHistories}
        systemConfigChanges={systemConfigChanges}
        systemObjectQuantities={systemObjectQuantities}
        onSelectedRangeChange={onSelectedRangeChange}
      />
    );
  }

  if (processQuery.isError) {
    throw processQuery.error;
  }

  if (systemConfigChangesQuery.isError) {
    throw systemConfigChangesQuery.error;
  }

  if (systemObjectQuantitiesQuery.isError) {
    throw systemObjectQuantitiesQuery.error;
  }

  return (
    <Center>
      <Loader variant='bars' />
    </Center>
  );
}

function renderRect(
  params: { coordSys: { x: number; y: number; width: number; height: number } },
  api: {
    value: (dimension: number) => number;
    coord: (data: number[]) => number[];
    size: (range: number[], location?: number[]) => number[];
    style: (extra?: unknown, idx?: number) => unknown;
  },
) {
  const categoryIndex = api.value(0);
  const start = api.coord([api.value(1), categoryIndex]);
  const end = api.coord([api.value(2), categoryIndex]);
  const height = api.size([0, 1])[1] * 0.6;

  const rectShape = echarts.graphic.clipRectByRect(
    {
      x: start[0],
      y: start[1] - height / 2,
      width: end[0] - start[0],
      height,
    },
    {
      x: params.coordSys.x,
      y: params.coordSys.y,
      width: params.coordSys.width,
      height: params.coordSys.height,
    },
  );

  // the types for this function are wrong in the upstream library
  if (rectShape === undefined) {
    throw new Error();
  }
  return {
    type: 'rect',
    transition: ['shape'],
    shape: rectShape,
    style: api.style(),
  };
}
