import { Card, Group, Progress, Stack, Text } from '@mantine/core';
import { ReactNode } from 'react';
import { BinaryConfusionMatrixDTO } from '../rest-client';
import { PCTFORMAT } from '../util/percentages';
import cssClasses from './BinaryConfusionMatrixStats.module.css';

export function BinaryConfusionMatrixStats(props: {
  matrix: BinaryConfusionMatrixDTO;
  withCardWrapper: boolean;
  prevalenceLabel?: string;
  positivePredictiveValueLabel?: string;
  falseDiscoveryRateLabel?: string;
  truePositiveRateLabel?: string;
  falseNegativeRateLabel?: string;
  trueNegativeRateLabel?: string;
  trueNegativeRateInverseLabel?: string;
}) {
  const {
    matrix,
    withCardWrapper,
    prevalenceLabel = 'Prevalence',
    positivePredictiveValueLabel = 'Purity',
    falseDiscoveryRateLabel = '(Impurity)',
    truePositiveRateLabel = 'Hit Rate',
    falseNegativeRateLabel = '(Miss Rate)',
    trueNegativeRateLabel = 'Selectivity',
    trueNegativeRateInverseLabel,
  } = props;

  const progressProps = {
    radius: 'xs',
    size: 'xl',
  };

  const stackProps = {
    spacing: 0,
  };

  function StatWrapper(props: { children: ReactNode }) {
    const { children } = props;
    return withCardWrapper ? (
      <Card shadow='xs' className={cssClasses.statsCard}>
        {children}
      </Card>
    ) : (
      <>{children}</>
    );
  }

  return (
    <>
      <StatWrapper>
        <Stack {...stackProps}>
          <Text>
            <Text span color='blue'>
              {prevalenceLabel}
            </Text>
          </Text>
          <Progress
            {...progressProps}
            sections={[
              {
                value: matrix.prevalence * 100,
                color: 'blue',
              },
            ]}
          />
          <Text color='blue' weight={600}>
            {PCTFORMAT.format(matrix.prevalence)}
          </Text>
        </Stack>
      </StatWrapper>
      <StatWrapper>
        <Stack {...stackProps}>
          <Group position='apart'>
            <Text color='teal'>{positivePredictiveValueLabel}</Text>
            <Text color='red'>{falseDiscoveryRateLabel}</Text>
          </Group>
          <Progress
            {...progressProps}
            sections={[
              {
                value: matrix.positivePredictiveValue * 100,
                color: 'teal',
              },
              {
                value: matrix.falseDiscoveryRate * 100,
                color: 'red',
              },
            ]}
          />
          <Group position='apart' fw={600}>
            <Text color='teal'>
              {PCTFORMAT.format(matrix.positivePredictiveValue)}
            </Text>
            <Text color='red'>
              {PCTFORMAT.format(matrix.falseDiscoveryRate)}
            </Text>
          </Group>
        </Stack>
      </StatWrapper>

      <StatWrapper>
        <Stack {...stackProps}>
          <Group position='apart'>
            <Text color='teal'>{truePositiveRateLabel}</Text>
            <Text color='red'>{falseNegativeRateLabel}</Text>
          </Group>
          <Progress
            {...progressProps}
            sections={[
              {
                value: matrix.truePositiveRate * 100,
                color: 'teal',
              },
              {
                value: matrix.falseNegativeRate * 100,
                color: 'red',
              },
            ]}
          />
          <Group position='apart' fw={600}>
            <Text color='teal'>
              {PCTFORMAT.format(matrix.truePositiveRate)}
            </Text>
            <Text color='red'>
              {PCTFORMAT.format(matrix.falseNegativeRate)}
            </Text>
          </Group>
        </Stack>
      </StatWrapper>

      <StatWrapper>
        <Stack {...stackProps}>
          <Text>
            <Group position='apart'>
              <Text color='teal'>{trueNegativeRateLabel}</Text>
              <Text color='red'>{trueNegativeRateInverseLabel}</Text>
            </Group>
          </Text>
          <Progress
            {...progressProps}
            sections={[
              {
                value: matrix.trueNegativeRate * 100,
                color: 'teal',
              },
              {
                value: 100 - matrix.trueNegativeRate * 100,
                color: 'red',
              },
            ]}
          />
          <Group position='apart' fw={600}>
            <Text color='teal'>
              {PCTFORMAT.format(matrix.trueNegativeRate)}
            </Text>
            <Text color='red'>
              {PCTFORMAT.format(1 - matrix.trueNegativeRate)}
            </Text>
          </Group>
        </Stack>
      </StatWrapper>
    </>
  );
}
