import { Alert, Badge, Group, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { match } from 'ts-pattern';
import { useDeleteEntityModal } from '../../Form/DeleteEntity.tsx';
import { ProcessBufferRestorationIcon } from '../../Icons';
import { ProcessIdName } from '../../Process/ProcessName.tsx';
import { EditProcessBufferRestorationDrawerForm } from '../../ProcessBufferRestoration/EditProcessBufferRestorationDrawerForm.tsx';
import { useDeleteProcessBufferRestoration } from '../../api/processBufferRestoration.ts';
import { CalendarDateTime } from '../../common';
import {
  BufferRestorationErrorDTO,
  ProcessBufferRestorationDTO,
} from '../../rest-client';
import { DeleteMenuItem } from '../DeleteMenuItem.tsx';
import { EditMenuItem } from '../EditMenuItem.tsx';
import { InventoryLedgerRowTemplate } from '../InventoryLedgerRowTemplate';
import { useInventoryLedgerStatusContext } from '../LedgerStatusContext';
import { RowActionsMenu } from '../RowActionsMenu.tsx';

export function ProcessBufferRestorationRow(props: {
  processBufferRestoration: ProcessBufferRestorationDTO;
}) {
  const { processBufferRestoration } = props;

  const ledgerStatus = useInventoryLedgerStatusContext();
  const status = ledgerStatus.bufferRestorationStatus(processBufferRestoration);

  const deleteMutation = useDeleteProcessBufferRestoration();
  const openDeleteModal = useDeleteEntityModal({
    mutationVariables: processBufferRestoration.id,
    deleteMutation,
    entityName: 'Feedstock Restoration',
  });

  const [drawerOpened, { open: openDrawer, close: closeDrawer }] =
    useDisclosure(false);

  let errorExplanationContent = null;
  if (status.status === 'conflict') {
    errorExplanationContent = status.errors.map((error, i) => (
      <ProcessBufferRestorationErrorExplaination
        bufferRestorationError={error}
        key={i}
      />
    ));
  }

  return (
    <>
      <InventoryLedgerRowTemplate
        eventType={
          <Group spacing='xs'>
            <ProcessBufferRestorationIcon />
            <Badge color='yellow'>Feedstock Restoration</Badge>
          </Group>
        }
        date={
          <CalendarDateTime
            iso8601={processBufferRestoration.effectiveTimestamp}
          />
        }
        entryStatus={status}
        source={
          <ProcessIdName
            processId={processBufferRestoration.processId}
            withIcon
          />
        }
        destination={
          <Group spacing='xs'>
            <ProcessBufferRestorationIcon />
            <Text c='dimmed'>Feedstock Restored</Text>
          </Group>
        }
        actions={
          <RowActionsMenu>
            <EditMenuItem onClick={openDrawer} />
            <DeleteMenuItem onClick={openDeleteModal} />
          </RowActionsMenu>
        }
      />
      {errorExplanationContent}
      <EditProcessBufferRestorationDrawerForm
        processBufferRestorationId={processBufferRestoration.id}
        opened={drawerOpened}
        onClose={closeDrawer}
      />
    </>
  );
}

function ProcessBufferRestorationErrorExplaination(props: {
  bufferRestorationError: BufferRestorationErrorDTO;
}) {
  const { bufferRestorationError } = props;
  return match(bufferRestorationError)
    .with({ kind: 'DuplicateBufferRestorationError' }, (error) => (
      <Alert
        color='red'
        title='Process Feedstock Restoration Concurrency Conflict'
      >
        This feedstock restoration event fonclits with another feedstock
        restoration event for the same process:{' '}
        <ProcessIdName processId={error.firstBufferRestoration.processId} />.
      </Alert>
    ))
    .with({ kind: 'NotImplicitlyDepletedBufferRestorationError' }, () => (
      <Alert
        color='red'
        title='Process Feedstock Restoration Depletion-Restoration Conflcit'
      >
        This feedstock restoration event ocurred on a feedstock buffer that was
        not implicitly depleted at time of restoration.
      </Alert>
    ))
    .exhaustive();
}
