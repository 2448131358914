import { Loader, Table, Text, useMantineTheme } from '@mantine/core';
import { DeleteEntityActionIcon } from '../../Form/DeleteEntity';
import { RecoveryGoalPathName } from '../../RecoveryGoal/RecoveryGoalPathName';
import { TableEmptyBasicContent } from '../../Table/TableEmptyBasicContent';
import { useCommodities } from '../../api/commodity';
import {
  useDefaultCommodityAssignments,
  useDeleteDefaultCommodityAssignment,
} from '../../api/defaultCommodityAssignment';
import { DefaultCommodityAssignmentDTO } from '../../rest-client';

export function DefaultCommodityAssignmentTable() {
  const theme = useMantineTheme();
  const {
    data: defaultAssignments,
    isLoading,
    isLoadingError,
    error,
  } = useDefaultCommodityAssignments();

  if (isLoadingError) {
    throw error;
  }

  const isEmpty = defaultAssignments && defaultAssignments.length === 0;

  return (
    <Table>
      {(isLoading || isEmpty) && (
        <caption
          style={{
            captionSide: 'bottom',
            textAlign: 'center',
            padding: theme.spacing.md,
          }}
        >
          {isLoading && <Loader />}
          {!isLoading && isEmpty && (
            <TableEmptyBasicContent>
              No Default Commodity Assignments
            </TableEmptyBasicContent>
          )}
        </caption>
      )}
      <thead>
        <tr>
          <th>Input Commodity</th>
          <th>Recovery Path</th>
          <th>Output Commodity</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {defaultAssignments?.map((dca) => (
          <DefaultCommodityAssignmentRow dca={dca} key={dca.id} />
        )) ?? (
          <tr>
            <td colSpan={4}>
              <Loader />
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
}

function DefaultCommodityAssignmentRow(props: {
  dca: DefaultCommodityAssignmentDTO;
}) {
  const { dca } = props;
  const deleteMutation = useDeleteDefaultCommodityAssignment();
  const commoditiesQuery = useCommodities();

  const commodityNameMap =
    commoditiesQuery.data &&
    new Map(commoditiesQuery.data.map((c) => [c.id, c.name]));
  return (
    <tr>
      <td>{commodityNameMap?.get(dca.inputCommodityId)}</td>
      <td>
        <RecoveryGoalPathName steps={dca.path.steps} />
      </td>
      <td>
        {dca.outputCommodityId === null ? (
          <Text c='dimmed'>Intermediate</Text>
        ) : (
          commodityNameMap?.get(dca.outputCommodityId)
        )}
      </td>
      <td>
        <DeleteEntityActionIcon
          mutationVariables={dca.id}
          deleteMutation={deleteMutation}
          entityName='Default Commodity Assignment'
        />
      </td>
    </tr>
  );
}
