import { match } from 'ts-pattern';
import { create } from 'zustand';
import {
  BalerControlAnalysisDTO,
  RimasAccount,
  RimasControlNumber,
  RimasCustomerOrder,
} from '../rest-client';

export type Status = 'complete' | 'incomplete' | 'unprocessed';
export type RowData = {
  controlNumber: RimasControlNumber;
  account: RimasAccount;
  customerOrder: RimasCustomerOrder;
  startDate: string;
  status: Status;
  receivedBales?: number;
  receivedPounds: number;
  laborHours?: number;
  outputs?: RowOutputData;
};

export type RowOutputData = {
  ubcBales: number;
} & RowOutputPoundsData;

export type RowOutputPoundsData = {
  ubcPounds: number;
  totalByproductPounds: number;
  alFinesPounds: number;
  ferrousPounds: number;
  mixedClipPounds: number;
  heaviesPounds: number;
  trashPounds: number;
  reconciledPounds: number;
};

export const dtoToRow = (dto: BalerControlAnalysisDTO) => {
  const rowData: RowData = {
    controlNumber: dto.controlNumber,
    account: dto.receiveStats.supplierInfo.account,
    customerOrder: dto.receiveStats.supplierInfo.customerOrder,
    startDate: dto.earliestToTagProcDate ?? dto.startDate,
    status: dto.status,
    receivedBales: dto.receiveStats.receivedBales ?? undefined,
    receivedPounds: dto.receiveStats.receivedNetPounds,
    laborHours: dto.laborHours ?? undefined,
  };

  match(dto)
    .with(
      { status: 'incomplete' },
      { status: 'complete' },
      ({ outputStats }) => {
        rowData.outputs = {
          ubcBales: outputStats.cleanUbcBales,
          ubcPounds: outputStats.cleanUbcNetPounds,
          totalByproductPounds:
            outputStats.alFinesNetPounds +
            outputStats.ferrousNetPounds +
            outputStats.mixedClipOldSheetNetPounds +
            outputStats.heaviesNetPounds +
            outputStats.trashNetPounds,
          alFinesPounds: outputStats.alFinesNetPounds,
          ferrousPounds: outputStats.ferrousNetPounds,
          mixedClipPounds: outputStats.mixedClipOldSheetNetPounds,
          heaviesPounds: outputStats.heaviesNetPounds,
          trashPounds: outputStats.trashNetPounds,
          reconciledPounds: outputStats.totalReconciledNetPounds ?? 0, // TODO: check if this is intended
        };
      },
    )
    .with({ status: 'unprocessed' }, () => null)
    .exhaustive();

  return rowData;
};

//#region mass fraction
const massFractionFormatter = new Intl.NumberFormat('en-US', {
  style: 'percent',
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
});

export type MassFractionValueObject = {
  numerator: number | null;
  denominator: number | null;
  toString: () => string;
  toPercent: () => number;
};

export const massFractionValueCalculator = (
  prop: keyof RowOutputPoundsData,
  outputs: RowOutputData,
  includeReconciled: boolean,
) => {
  const propPounds = outputs[prop];
  let processedPounds = outputs?.ubcPounds + outputs?.totalByproductPounds;
  if (includeReconciled) {
    processedPounds += outputs.reconciledPounds;
  }
  return createMassFractionValueObject(propPounds, processedPounds);
};

export const createMassFractionValueObject = (
  numerator: number | undefined,
  denominator: number | undefined,
) => {
  return {
    numerator: numerator ?? null,
    denominator: denominator ?? null,
    toString: () =>
      `${numerator !== undefined && denominator ? massFractionFormatter.format(numerator / denominator) : ''}`,
    toPercent: () =>
      numerator !== undefined && denominator
        ? (100 * numerator) / denominator
        : 0,
  } as MassFractionValueObject;
};

const poundsFormatter = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  maximumFractionDigits: 0,
});
//#endregion

//#region pounds per hour
export type PoundsPerHourValueObject = {
  pounds: number | null;
  hours: number | null;
  toString: () => string;
  toNumber: () => number;
  missingBlankValues: boolean;
};

export const createPoundsPerHourValueObject = (
  pounds: number | undefined,
  hours: number | undefined,
  missingBlankValues: boolean,
) => {
  return {
    pounds: pounds ?? null,
    hours: hours ?? null,
    toString: () =>
      `${pounds !== undefined && hours ? poundsFormatter.format(pounds / hours) : ''}`,
    missingBlankValues: missingBlankValues,
    toNumber: () => (pounds !== undefined && hours ? pounds / hours : 0),
  } as PoundsPerHourValueObject;
};

export const poundsPerLaborHourCalculator = (
  prop: keyof RowOutputPoundsData | 'totalProcessedPounds',
  outputs: RowOutputData | undefined,
  laborHours: number | undefined,
  includeReconciled?: boolean,
) => {
  if (outputs === undefined || laborHours === undefined) {
    if (prop === 'totalProcessedPounds') {
      return createPoundsPerHourValueObject(0, laborHours, true);
    }
    return createPoundsPerHourValueObject(outputs?.[prop], undefined, true);
  }
  return match(prop)
    .with('totalProcessedPounds', () =>
      createPoundsPerHourValueObject(
        outputs.ubcPounds +
          outputs.totalByproductPounds +
          (includeReconciled ? outputs.reconciledPounds : 0),
        laborHours,
        false,
      ),
    )
    .otherwise((prop) =>
      createPoundsPerHourValueObject(outputs[prop], laborHours, false),
    );
};
//#endregion

interface RimasTableStore {
  filteredRows: RowData[] | undefined;
  setFilteredRows: (rows: RowData[]) => void;
  /**
   * includeReconciled: controls if the reconciled weight in included in the mass fraction denominator
   */
  includeReconciled: boolean;
  setIncludeReconciled: (value: boolean) => void;
}

export const useRimasTableStore = create<RimasTableStore>()((set) => ({
  filteredRows: undefined,
  setFilteredRows: (rows) => {
    set(() => ({ filteredRows: rows }));
  },
  includeReconciled: true,
  setIncludeReconciled: (value) => {
    set(() => ({ includeReconciled: value }));
  },
}));
