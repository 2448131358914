import { UseQueryResult, useQueries } from '@tanstack/react-query';
import { MaterialSetResultDTO, MaterialStateService } from '../rest-client';
import { QueryFunctionContexts } from './queryKeyTypeUtils';
import { queryKeys } from './queryKeys';

async function fetchMaterialSetByHashes(
  ctx: QueryFunctionContexts['materialSet']['byHashes'],
) {
  const [{ stateHash, materialSetHash }] = ctx.queryKey;
  return await MaterialStateService.getMaterialSet(stateHash, materialSetHash);
}

export function useMaterialSets(args: {
  stateHash: string;
  materialSetHashes: string[];
}): UseQueryResult<MaterialSetResultDTO>[] {
  return useQueries({
    queries: args.materialSetHashes.map((materialSethash) => ({
      queryKey: queryKeys.materialSet.byHashes(args.stateHash, materialSethash),
      queryFn: fetchMaterialSetByHashes,
      staleTime: Number.POSITIVE_INFINITY, // immutable
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    })),
  });
}
