import { Paper, Stack, Text } from '@mantine/core';
import { useInferredProperties } from '../api/inference';
import { LabeledValue } from '../common';
import { MaterialSetDTO } from '../rest-client';
import { RecoveryGoalPathName } from './RecoveryGoalPathName';

export default function MaterialSetGlobalRecoveryGoalPaths(props: {
  materialSet: MaterialSetDTO;
}) {
  const { materialSet } = props;

  const inferredPropertiesQuery = useInferredProperties(materialSet);

  const paths = materialSet.recoveryGoalPaths;

  return (
    <div>
      <LabeledValue
        label={`Recovery Goal Sequence${paths.length > 1 ? 's' : ''}`}
      >
        {paths.length > 0 ? (
          <Stack spacing={2} align='flex-start'>
            {paths.map((path, i) => (
              <Paper key={i} withBorder p={2}>
                <RecoveryGoalPathName steps={path.steps} />
              </Paper>
            ))}
          </Stack>
        ) : (
          <Text color='dimmed'>unknown</Text>
        )}
      </LabeledValue>

      {inferredPropertiesQuery.data &&
        Object.entries(
          inferredPropertiesQuery.data
            .materialClassSetGlobalRecoveryPerformances,
        ).map(([materialClassSetId, perf]) =>
          perf.purity ? (
            <LabeledValue label='Purity' key={materialClassSetId}>
              <Text fw={500}>{(perf.purity * 100.0).toFixed(1)}%</Text>
            </LabeledValue>
          ) : null,
        )}
      {/* TODO(2337): Recovery performance */}
    </div>
  );
}
