import { Group, Skeleton, Stack, Text } from '@mantine/core';
import dayjs from 'dayjs';
import { match, P } from 'ts-pattern';
import { useInternalMaterialSource } from '../api/internalMaterialSource';
import { useDetailedProcess } from '../api/process';
import { useTenantContext } from '../App/TenantContext';
import { CommodityIdName, CommodityName } from '../Commodity/CommodityName';
import { LabeledValue } from '../common';
import { useFacilityContext } from '../Facility/FacilityContext';
import { InternalMaterialSinkIdName } from '../InternalMaterialSink/InternalMaterialSinkIdName';
import { InternalMaterialSourceIdName } from '../InternalMaterialSource/InternalMaterialSourceName';
import { ProcessIdName } from '../Process/ProcessName';
import { RecoveryStrategyIdName } from '../RecoveryStrategy/RecoveryStategyName';
import {
  InternalMaterialSinkId,
  InternalMaterialSourceId,
  NotionalSampleLinkDTO,
  NotionalSampleOriginDTO,
  OutputPortId,
  ProcessId,
  SampleNumericId,
} from '../rest-client';

export type NotionalSampleDetailsSectionProps = {
  notionalSampleLink: NotionalSampleLinkDTO;
  numericSampleId: SampleNumericId;
};
export function NotionalSampleDetails(
  props: NotionalSampleDetailsSectionProps,
) {
  const { notionalSampleLink, numericSampleId } = props;
  const {
    effectiveTimestamp,
    internalMaterialSinkId,
    name,
    notionalSampleOrigin,
  } = notionalSampleLink;
  const { timeZoneId } = useFacilityContext();

  const tenant = useTenantContext();
  const isRadius = tenant.tenantId === 1;

  if (isRadius) {
    return (
      <Stack>
        <Group spacing='xl'>
          <Commodity notionalSampleLink={notionalSampleLink} />
          <LabeledValue label='Timestamp'>
            {dayjs.utc(effectiveTimestamp).tz(timeZoneId).format('LLLL z')}
          </LabeledValue>

          <UpstreamSource notionalSampleOrigin={notionalSampleOrigin} />
          <UpstreamSourceCommodity notionalSampleLink={notionalSampleLink} />
          <LabeledValue label='Name'>
            {name === null || name === '' ? <Text c='dimmed'>none</Text> : name}
          </LabeledValue>
        </Group>
      </Stack>
    );
  }

  return (
    <Stack>
      <Group>
        <Commodity notionalSampleLink={notionalSampleLink} />
        <LabeledValue label='Timestamp'>
          {dayjs.utc(effectiveTimestamp).tz(timeZoneId).format('LLLL z')}
        </LabeledValue>
        <LabeledValue label='Sample ID'>{numericSampleId}</LabeledValue>
        <LabeledValue label='Name'>
          {name === null || name === '' ? <Text c='dimmed'>none</Text> : name}
        </LabeledValue>
      </Group>
      <Group>
        <UpstreamSource notionalSampleOrigin={notionalSampleOrigin} />
        <UpstreamSourceCommodity notionalSampleLink={notionalSampleLink} />
        <MaterialExportDestination
          internalMaterialSinkId={internalMaterialSinkId}
        />
      </Group>
      <Group>
        <MaterialProcessed notionalSampleLink={notionalSampleLink} />
        <EmployedRecoveryStrategy notionalSampleOrigin={notionalSampleOrigin} />
        <ProducingProcess notionalSampleOrigin={notionalSampleOrigin} />
        <ProducingProcessOutput notionalSampleOrigin={notionalSampleOrigin} />
      </Group>
    </Stack>
  );
}

function Commodity(props: { notionalSampleLink: NotionalSampleLinkDTO }) {
  const { notionalSampleLink } = props;

  return (
    <LabeledValue label='Commodity'>
      {match([
        notionalSampleLink.notionalSampleOrigin,
        notionalSampleLink.manuallySpecifiedCommodity,
      ])
        .with(
          [{ kind: 'SingleSourceNotProcessed' }, P._],
          [{ kind: 'MixedSourcesNotProcessed' }, P._],
          () => (
            <UpstreamSourceCommodity notionalSampleLink={notionalSampleLink} />
          ),
        )
        .with([{ kind: 'Processed' }, null], () => <Text>No Commodity</Text>)
        .with([{ kind: 'Processed' }, P.not(null)], ([, commodity]) => (
          <CommodityName commodity={commodity} />
        ))

        // TODO: implement recovery goal path deduced commodity
        .exhaustive()}
    </LabeledValue>
  );
}

// #region Upstream Components
function UpstreamSource(props: {
  notionalSampleOrigin: NotionalSampleOriginDTO;
}) {
  const { notionalSampleOrigin } = props;

  const infoContent = match(notionalSampleOrigin)
    .with(
      { kind: 'SingleSourceNotProcessed' },
      ({ internalMaterialSourceId }) => (
        <Text>
          The sampled material was directly sampled from the{' '}
          <InternalMaterialSourceIdName
            internalMaterialSourceId={internalMaterialSourceId}
            noLink
          />{' '}
          upstream source and was not processed. This sample will be directly
          associated with{' '}
          <InternalMaterialSourceIdName
            internalMaterialSourceId={internalMaterialSourceId}
            noLink
          />
          .
        </Text>
      ),
    )
    .with({ kind: 'MixedSourcesNotProcessed' }, () => (
      <Text>
        The sampled material was taken from a mixture of multiple unprocessed
        upstream material sources. Therefore, this sample cannot be directly
        associated to any one upstream material source.
      </Text>
    ))
    .with(
      {
        kind: 'Processed',
        upstreamSource: { kind: 'SingleInternalMaterialSource' },
      },
      ({ upstreamSource }) => (
        <Text>
          The sampled material was sampled from a result of processing{' '}
          <InternalMaterialSourceIdName
            internalMaterialSourceId={upstreamSource.internalMaterialSourceId}
            noLink
          />{' '}
          material. This sample, in conjunction with other samples of material
          derived from this source, will be related to infer upstream material
          composition.
        </Text>
      ),
    )
    .with(
      {
        kind: 'Processed',
        upstreamSource: { kind: 'MixedInternalMaterialSources' },
      },
      () => (
        <Text>
          The sampled material was sampled from a result of processing a mixture
          of upstream material sources. Therefore, this sample cannot be related
          to any one upstream material source.
        </Text>
      ),
    )
    .with({ kind: 'Processed', upstreamSource: null }, () => (
      <Text>
        This material was processed, but the upstream source material which was
        processed was never specified. Therefore, this sample cannot be related
        to any upstream material source
      </Text>
    ))
    .exhaustive();

  return (
    <LabeledValue
      label='Upstream Material Source'
      infoIconContent={infoContent}
    >
      {match(notionalSampleOrigin)
        .with(
          { kind: 'SingleSourceNotProcessed' },
          ({ internalMaterialSourceId }) => (
            <InternalMaterialSourceIdName
              internalMaterialSourceId={internalMaterialSourceId}
            />
          ),
        )
        .with({ kind: 'MixedSourcesNotProcessed' }, () => (
          <Text c='dimmed'>Mixed Upstream Sources</Text>
        ))
        .with(
          {
            kind: 'Processed',
            upstreamSource: { kind: 'MixedInternalMaterialSources' },
          },
          () => <Text c='dimmed'>Mixed Upstream Sources</Text>,
        )
        .with(
          {
            kind: 'Processed',
            upstreamSource: { kind: 'SingleInternalMaterialSource' },
          },
          ({ upstreamSource }) => (
            <InternalMaterialSourceIdName
              internalMaterialSourceId={upstreamSource.internalMaterialSourceId}
            />
          ),
        )
        .with({ kind: 'Processed', upstreamSource: null }, () => (
          <Text c='dimmed'>Unknown</Text>
        ))
        .exhaustive()}
    </LabeledValue>
  );
}

function UpstreamSourceCommodity(props: {
  notionalSampleLink: NotionalSampleLinkDTO;
}) {
  const { notionalSampleLink } = props;
  return (
    <LabeledValue label='Upstream Source Commodity'>
      {match(notionalSampleLink.notionalSampleOrigin)
        .with({ kind: 'MixedSourcesNotProcessed' }, () =>
          // PR reviewer please consider this coalescing:
          notionalSampleLink.manuallySpecifiedCommodity ? (
            <CommodityName
              commodity={notionalSampleLink.manuallySpecifiedCommodity}
            />
          ) : (
            <Text>No Commodity</Text>
          ),
        )
        .with(
          { kind: 'SingleSourceNotProcessed' },
          ({ internalMaterialSourceId }) => (
            <InternalMaterialSourceCommodity
              internalMaterialSourceId={internalMaterialSourceId}
            />
          ),
        )
        .with(
          { kind: 'Processed', upstreamSource: null },
          {
            kind: 'Processed',
            upstreamSource: { kind: 'MixedInternalMaterialSources' },
          },
          () => <Text c='dimmed'>Unknown</Text>,
        )
        .with(
          {
            kind: 'Processed',
            upstreamSource: { kind: 'SingleInternalMaterialSource' },
          },
          ({ upstreamSource }) => (
            <InternalMaterialSourceCommodity
              internalMaterialSourceId={upstreamSource.internalMaterialSourceId}
            />
          ),
        )
        .exhaustive()}
    </LabeledValue>
  );
}

function InternalMaterialSourceCommodity(props: {
  internalMaterialSourceId: InternalMaterialSourceId;
}) {
  const { internalMaterialSourceId } = props;
  const query = useInternalMaterialSource(internalMaterialSourceId);
  return match(query)
    .with({ isLoading: true }, () => <Skeleton>Loading...</Skeleton>)
    .with({ isLoadingError: true }, () => <Text c='red'>Error</Text>)
    .with(
      { data: { commodityId: P.nonNullable } },
      ({ data: internalMaterialSource }) => (
        <CommodityIdName commodityId={internalMaterialSource.commodityId} />
      ),
    )
    .with({ data: P.nonNullable }, () => <Text c='dimmed'>Unknown</Text>)
    .exhaustive();
}
// #endregion

// #region Processing Components
function EmployedRecoveryStrategy(props: {
  notionalSampleOrigin: NotionalSampleOriginDTO;
}) {
  const { notionalSampleOrigin } = props;

  const iconContent = match(notionalSampleOrigin)
    .with(
      { kind: 'MixedSourcesNotProcessed' },
      { kind: 'SingleSourceNotProcessed' },
      () => (
        <Text>
          The sampled material was never processed. Therefore, there is no
          employed recovery strategy.
        </Text>
      ),
    )
    .with({ kind: 'Processed', employedRecoveryStrategy: null }, () => (
      <Text>
        The sampled material was processed, but there was no recovery strategy
        specified. Therefore, the sample material will not be related to other
        sampled materials from employed recovery strategies.
      </Text>
    ))
    .with(
      { kind: 'Processed', employedRecoveryStrategy: { kind: 'UnknownPath' } },
      ({ employedRecoveryStrategy }) => (
        <Text>
          The sampled material was processed through the{' '}
          <RecoveryStrategyIdName
            recoveryStrategyId={employedRecoveryStrategy.recoveryStrategyId}
            noLink
          />{' '}
          recovery strategy, but the recovery output from that recovery strategy
          is unknown. Therefore, the sampled material cannot be appropriately
          related to the other sampled materials from that employed recovery
          strategy.
        </Text>
      ),
    )
    .with(
      { kind: 'Processed', employedRecoveryStrategy: { kind: 'KnownPath' } },
      ({ employedRecoveryStrategy }) => (
        <Text>
          The sampled material was processed through the{' '}
          <RecoveryStrategyIdName
            recoveryStrategyId={employedRecoveryStrategy.recoveryStrategyId}
            noLink
          />{' '}
          recovery strategy and is related to the other output products from
          that employed recovery strategy.
        </Text>
      ),
    )
    .exhaustive();

  return (
    <LabeledValue
      label='Employed Recovery Strategy'
      infoIconContent={iconContent}
    >
      {match(notionalSampleOrigin)
        .with(
          { kind: 'MixedSourcesNotProcessed' },
          { kind: 'SingleSourceNotProcessed' },
          () => <Text c='dimmed'>none</Text>,
        )
        .with({ kind: 'Processed', employedRecoveryStrategy: null }, () => (
          <Text c='dimmed'>Unknown</Text>
        ))
        .with(
          { kind: 'Processed', employedRecoveryStrategy: P.nonNullable },
          ({ employedRecoveryStrategy }) => (
            <RecoveryStrategyIdName
              recoveryStrategyId={employedRecoveryStrategy.recoveryStrategyId}
            />
          ),
        )
        .exhaustive()}
    </LabeledValue>
  );
}

function MaterialProcessed(props: {
  notionalSampleLink: NotionalSampleLinkDTO;
}) {
  const { notionalSampleLink } = props;

  const iconContent = match([
    notionalSampleLink.notionalSampleOrigin,
    notionalSampleLink.internalMaterialSinkId,
    notionalSampleLink.manuallySpecifiedCommodity,
  ])
    .with(
      [{ kind: 'MixedSourcesNotProcessed' }, P._, P._],
      [{ kind: 'SingleSourceNotProcessed' }, P._, P._],
      () => <Text>The sampled material was unprocessed.</Text>,
    )
    .with([{ kind: 'Processed' }, P._, null], () => (
      <Text>
        The sampled material was partially processed and is an intermediate
        product.
      </Text>
    ))
    .with([{ kind: 'Processed' }, null, P._], () => (
      <Text>
        The sampled material was fully processed and is an output product.
        However, there is no specified material export destination to be
        directly related to.
      </Text>
    ))
    .with(
      [{ kind: 'Processed' }, P.string, P._],
      ([, internalMaterialSinkId]) => (
        <Text>
          The sampled material was fully processed and is an output product.
          This sample will be directly associated to the{' '}
          <InternalMaterialSinkIdName
            internalMaterialSinkId={internalMaterialSinkId}
            variant='name-only'
          />{' '}
          material export destination.
        </Text>
      ),
    )
    .exhaustive();
  return (
    <LabeledValue label='Material Processed' infoIconContent={iconContent}>
      {match([
        notionalSampleLink.notionalSampleOrigin,
        notionalSampleLink.manuallySpecifiedCommodity,
      ])
        .with(
          [{ kind: 'MixedSourcesNotProcessed' }, P._],
          [{ kind: 'SingleSourceNotProcessed' }, P._],
          () => <Text c='dimmed'>Unprocessed</Text>,
        )
        .with([{ kind: 'Processed' }, null], () => (
          <Text>Partially Processed</Text>
        ))
        .with([{ kind: 'Processed' }, P.nonNullable], () => (
          <Text>Fully Processed</Text>
        ))
        .exhaustive()}
    </LabeledValue>
  );
}

function ProducingProcess(props: {
  notionalSampleOrigin: NotionalSampleOriginDTO;
}) {
  const { notionalSampleOrigin } = props;

  return (
    <LabeledValue label='Producing Process'>
      {match(notionalSampleOrigin)
        .with(
          { kind: 'MixedSourcesNotProcessed' },
          { kind: 'SingleSourceNotProcessed' },
          () => <Text c='dimmed'>none</Text>,
        )
        .with(
          { kind: 'Processed', producingProcess: { kind: 'KnownOutputPort' } },
          {
            kind: 'Processed',
            producingProcess: { kind: 'UnknownOutputPort' },
          },
          ({ producingProcess }) => (
            <ProcessIdName processId={producingProcess.processId} />
          ),
        )
        .with({ kind: 'Processed', producingProcess: null }, () => (
          <Text c='dimmed'>Unknown</Text>
        ))
        .exhaustive()}
    </LabeledValue>
  );
}

function ProducingProcessOutput(props: {
  notionalSampleOrigin: NotionalSampleOriginDTO;
}) {
  const { notionalSampleOrigin } = props;

  return (
    <LabeledValue label='Process Output'>
      {match(notionalSampleOrigin)
        .with(
          { kind: 'MixedSourcesNotProcessed' },
          { kind: 'SingleSourceNotProcessed' },
          () => <Text c='dimmed'>none</Text>,
        )
        .with(
          { kind: 'Processed', producingProcess: { kind: 'KnownOutputPort' } },
          ({ producingProcess }) => (
            <ProcessOutputPort
              processId={producingProcess.processId}
              outputPortId={producingProcess.outputPortId}
            />
          ),
        )
        .with(
          {
            kind: 'Processed',
            producingProcess: { kind: 'UnknownOutputPort' },
          },
          { kind: 'Processed', producingProcess: null },
          () => <Text c='dimmed'>Unknown</Text>,
        )
        .exhaustive()}
    </LabeledValue>
  );
}

function ProcessOutputPort(props: {
  processId: ProcessId;
  outputPortId: OutputPortId;
}) {
  const { processId, outputPortId } = props;

  const query = useDetailedProcess(processId);
  return match(query)
    .with({ isLoading: true }, () => <Skeleton>Loading...</Skeleton>)
    .with({ isLoadingError: true }, () => <Text c='red'>Error</Text>)
    .with({ data: P.nonNullable }, ({ data: process }) => (
      <Text>
        {process.outputs.find((output) => output.outputPortId === outputPortId)
          ?.name ?? 'Unknown'}
      </Text>
    ))
    .exhaustive();
}

// #endregion

function MaterialExportDestination(props: {
  internalMaterialSinkId: InternalMaterialSinkId | null;
}) {
  const { internalMaterialSinkId } = props;

  const iconContent = match(internalMaterialSinkId)
    .with(P.string, (id) => (
      <Text>
        The sampled material has been directly associated to the{' '}
        <InternalMaterialSinkIdName internalMaterialSinkId={id} /> material
        export destination. This sample, along with other samples directly
        associatied to the export destination, are used to infer the composition
        of that export destination.
      </Text>
    ))
    .with(null, () => undefined)
    .exhaustive();

  return (
    <LabeledValue
      label='Material Export Destination'
      infoIconContent={iconContent}
    >
      {match(internalMaterialSinkId)
        .with(P.string, (id) => (
          <InternalMaterialSinkIdName internalMaterialSinkId={id} />
        ))
        .with(null, () => <Text c='dimmed'>Unknown</Text>)
        .exhaustive()}
    </LabeledValue>
  );
}
