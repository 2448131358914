import { Group, Text } from '@mantine/core';
import { IconCirclePlus, IconSquareRoundedMinus } from '@tabler/icons-react';
import { ReactNode } from 'react';
import { LinkText } from '../Link';
import { useRecoveryGoal } from '../api/recoveryGoal';
import { Router } from '../router';
import cssClasses from './RecoveryGoalPathName.module.css';

interface RecovoeryGoalStep {
  recoveryGoalId: string;
  negative: boolean;
}

export function RecoveryGoalPathName(props: {
  steps: RecovoeryGoalStep[];
  noLink?: boolean;
}) {
  const { steps, noLink = false } = props;

  if (steps.length === 0) {
    return <Text c='dimmed'>unprocessed</Text>;
  }

  return (
    <Group className={cssClasses.wrapper}>
      {steps
        .map((step, i) => (
          <RecoveryGoalStepName step={step} key={i} noLink={noLink} />
        ))
        .reduce<ReactNode>(
          (acc, stepName) =>
            acc === null ? (
              stepName
            ) : (
              // biome-ignore lint/correctness/useJsxKeyInIterable: <explanation>
              <>
                {acc}
                {stepName}
              </>
            ),
          null,
        )}
    </Group>
  );
}

export function RecoveryGoalStepName(props: {
  step: RecovoeryGoalStep;
  noLink?: boolean;
}) {
  const { step, noLink = false } = props;
  const recoveryGoalQuery = useRecoveryGoal(step.recoveryGoalId);

  const textContent = (
    <>
      {step.negative ? (
        <IconSquareRoundedMinus
          size='1em'
          style={{ marginBottom: '-0.12em' }}
        />
      ) : (
        <IconCirclePlus size='1em' style={{ marginBottom: '-0.12em' }} />
      )}
      <Text span>{recoveryGoalQuery.data?.name ?? 'Loading...'}</Text>
    </>
  );

  return noLink ? (
    <Text>{textContent}</Text>
  ) : (
    <LinkText
      to={Router.RecoveryGoalDetail({ recoveryGoalId: step.recoveryGoalId })}
    >
      {textContent}
    </LinkText>
  );
}
